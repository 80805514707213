import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { categories } from '../../../../../pages/home/index/categories';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'buy1way-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  current = '/';
  categories = categories.slice(0, 7);
  private subscr: Subscription;
  locale: string = 'fr';

  constructor(private router: Router, private storage: LocalStorageService) {
    this.subscr = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.current = event.url;
      } else if (event instanceof NavigationEnd) {
        this.current = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.locale = this.storage.retrieve('lang');
    this.storage.observe('lang').subscribe({
      next: (lang) => (this.locale = lang),
    });
  }

  ngOnDestroy(): void {
    this.subscr.unsubscribe();
  }

  viewAllDemos(event: any) {
    event.preventDefault();
    var list = document.querySelectorAll('.demo-list .hidden');
    for (let i = 0; i < list.length; i++) {
      list[i].classList.add('show');
    }

    event.target.parentElement.classList.add('d-none');
  }

  translateCategory(id: string, locale: string) {
    const category = this.categories.find((category) => category.id === id);
    return locale === 'fr' ? category.nameFr : category.nameEn;
  }
  translateSubCategory(id: string, locale: string) {
    let subcategory;
    for (const category of categories) {
      subcategory = category.subcategory.find((sub) => sub.id === id);
      if (subcategory) {
        return locale === 'fr' ? subcategory.nameFr : subcategory.nameEn;
      }
    }
    return undefined;
  }
}
