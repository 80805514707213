import { Injectable } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LoginModalComponent } from '../components/modals/login-modal/login-modal.component';
import { RegisterModalComponent } from '../components/modals/register-modal/register-modal.component';

import { MessageModalComponent } from '../components/modals/message-modal/message-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalOption2: NgbModalOptions = {
    size: 'lg',
    windowClass: 'login-modal',
    beforeDismiss: async () => {
      document.querySelector('body')?.classList.remove('modal-open');

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve('success');
        }, 300);
      });

      return true;
    },
  };

  constructor(private modalService: NgbModal) {}

  // Show login modal
  showLoginModal() {
    this.modalService.open(LoginModalComponent, this.modalOption2);
  }
  // Show message modal
  showMesageModal() {
    this.modalService.open(MessageModalComponent, this.modalOption2);
  }

  // Show register modal
  showRegisterModal() {
    this.modalService.open(RegisterModalComponent, this.modalOption2);
  }
}
