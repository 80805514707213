import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'buy1way-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isDesktop: boolean;
  ngOnInit(): void {
    if (typeof navigator !== 'undefined') {
      this.isDesktop = window.innerWidth >= 992;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isDesktop = window.innerWidth >= 992;
  }
}
