<main class="main home-page skeleton-body">
  <div class="mb-lg-2"></div>
  <div class="container-fluid">
    <div class="row">
      <div class="col"></div>
      <div class="col-12 col-sm-8 col-lg-9">
        <div
          *ngIf="isMobile"
          class="d-flex flex-column justify-content-center container-add-ads p-5"
        >
          <button
            routerLink="/new"
            type="button"
            class="btn btn-primary btn-rounded btn-shadow"
            style="border-radius: 10px"
          >
            <i class="bi bi-plus-circle" style="margin-right: 5px"></i>
            {{ "header.ad_button" | translate }}
          </button>
          <h4 class="text-center mt-2">
            {{ "index.caroussel_item1_text" | translate }}
          </h4>
        </div>
        <div
          *ngIf="!isMobile"
          class="intro-slider-container slider-container-ratio mb-2"
        >
          <div class="intro-slider owl-simple owl-nav-inside">
            <div
              class="intro-slide"
              style="object-fit: cover; border-radius: 12px"
            >
              <figure class="slide-image mb-0">
                <img
                  style="object-fit: cover; border-radius: 12px"
                  src="assets/images/home/sliders/slide-1.jpg"
                  alt="Image Desc"
                />
              </figure>

              <div class="intro-content">
                <h1 class="intro-title" style="max-width: 40%">
                  {{ "index.caroussel_item1_title" | translate }}
                </h1>

                <div
                  class="intro-text"
                  contentAnim
                  aniName="blurIn"
                  style="max-width: 70%"
                >
                  {{ "index.caroussel_item1_text" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>

  <div
    class="container py-5 my-lg-4"
    *ngIf="recentItems.length > 0"
    style="padding-left: 2rem; padding-right: 2rem"
  >
    <div class="heading heading-center" contentAnim aniName="blurIn">
      <h1 class="title font-weight-bolder">
        {{ "index.recent_ads" | translate }}
      </h1>
    </div>
    <owl-carousel-o
      #owlElement
      carouselClasses="owl-theme"
      [options]="categoriesSlider"
    >
      <ng-container class="p-5 p-lg-0" *ngFor="let item of recentItems">
        <ng-template carouselSlide [id]="item.id">
          <recent-item [item]="item"></recent-item>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>

  <div class="container my-lg-4 my-2 mb-3">
    <section class="category-section popular-categories">
      <div class="container my-lg-4 my-2">
        <div class="heading heading-center" contentAnim aniName="blurIn">
          <h1 class="title font-weight-bolder">
            {{ "index.all_categories" | translate }}
          </h1>
        </div>
        <owl-carousel-o
          carouselClasses="owl-theme"
          [options]="categoriesSlider"
        >
          <ng-container class="cat-block" *ngFor="let category of categories">
            <ng-template carouselSlide [id]="category.id">
              <figure>
                <a
                  routerLink="/ads/list"
                  [queryParams]="{
                    category: category.id,
                    type: category.type === 'mixte' ? 'product' : category.type
                  }"
                >
                  <buy1way-image
                    [src]="category.img"
                    alt="Category"
                    [objectFitValue]="'cover'"
                  ></buy1way-image>
                </a>
              </figure>
              <div class="cat-content">
                <h5 class="cat-title font-weight-bolder">
                  <a
                    routerLink="/ads/list"
                    [queryParams]="{
                      category: category.id,
                      type:
                        category.type === 'mixte' ? 'product' : category.type
                    }"
                  >
                    {{ translateCategory(category.id, locale) }}
                  </a>
                </h5>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </section>
  </div>
  <div class="container mb-3">
    <section class="category-section popular-categories">
      <div class="container">
        <div class="text-start">
          <div class="row">
            <div
              class="col-6 col-sm-6 col-md-4 col-lg-2 mb-2"
              style="max-width: 25rem"
              *ngFor="let category of categories"
            >
              <h5 class="cat-title font-weight-bolder">
                <a
                  style="width: 5rem; word-wrap: break-word"
                  routerLink="/ads/list"
                  [queryParams]="{
                    category: category.id,
                    type: category.type === 'mixte' ? 'product' : category.type
                  }"
                >
                  {{ translateCategory(category.id, locale) }}</a
                >
              </h5>
              <ul class="cat-list">
                <li
                  class="cat-item"
                  *ngFor="let subcategory of category.subcategory"
                >
                  <a
                    routerLink="/ads/list"
                    [queryParams]="{
                      subcategory: subcategory.id,
                      type: subcategory.type
                    }"
                  >
                    {{ translateSubCategory(subcategory.id, locale) }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
