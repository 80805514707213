<router-outlet></router-outlet>

<div class="mobile-menu-overlay" (click)="hideMobileMenu()"></div>

<buy1way-mobile-menu></buy1way-mobile-menu>

<button
  id="scroll-top"
  title="Back to Top"
  [ngClass]="{ show: utilsService.isSticky }"
  (click)="utilsService.scrollTop($event)"
>
  <i class="icon-arrow-up scroll-top"></i>
</button>
