<div class="mobile-menu-container mobile-menu-light">
  <div class="mobile-menu-wrapper">
    <div style="border-bottom: 1px solid #eac697; padding-bottom: 10px">
      <span class="mobile-menu-close m-3" (click)="hideMobileMenu()"
        ><i class="icon-close"></i
      ></span>
    </div>
    <div class="m-2 my-5 mx-4">
      <h6 routerLink="/new" class="d-flex align-items-center mb-2">
        <i class="bi bi-plus-square me-4"></i>
        <span>{{ "header.ad_button" | translate }}</span>
      </h6>
      <h6 routerLink="/account/wishlist" class="d-flex align-items-center mb-2">
        <i class="bi bi-heart me-4"></i>
        <span>{{ "header.favorites" | translate }}</span>
      </h6>
      <h6 routerLink="/account/messages" class="d-flex align-items-center mb-2">
        <i class="bi bi-chat-right-dots me-4"></i> <span>Messages</span>
      </h6>
      <h6 routerLink="/account/settings" class="d-flex align-items-center mb-2">
        <i class="bi bi-gear me-4"></i>
        <span>{{ "header.settings" | translate }}</span>
      </h6>
      <h6 routerLink="/account/ads" class="d-flex align-items-center mb-2">
        <i class="bi bi-collection me-4"></i>
        <span>{{ "header.my_ads" | translate }}</span>
      </h6>
    </div>
  </div>
</div>
