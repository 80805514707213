<div class="container" style="margin-top: 5%">
  <div class="form-box">
    <div class="form-tab nav-fill">
      <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
        <li ngbNavItem>
          <a routerLink="/" style="display: flex; justify-content: center">
            <img
              src="assets/images/home/logo.png"
              class="footer-logo"
              alt="Footer Logo"
              width="90"
            />
          </a>
          <a ngbNavLink>{{ "change_password.title" | translate }}</a>
          <ng-template ngbNavContent>
            <div class="form-choice" *ngIf="isValidCode == true">
              <form [formGroup]="changePasswordForm">
                <div class="form-group">
                  <label for="register-password"
                    >{{ "change_password.password" | translate }}
                    <abbr
                      class="text-danger"
                      title="{{
                        'change_password.this_is_required' | translate
                      }}"
                      >*</abbr
                    ></label
                  >
                  <input
                    [type]="fieldTextType ? 'text' : 'password'"
                    class="form-control"
                    id="register-password"
                    formControlName="password"
                    [ngClass]="{
                      'is-invalid':
                        f.password.errors &&
                        (f.password.touched || f.password.dirty),
                      'is-valid ': f.password.valid
                    }"
                  />
                </div>
                <div class="mb-1">
                  <div
                    class="row"
                    *ngIf="f.password.errors && f.password.dirty"
                  >
                    <div class="col-6">
                      <label
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.minlength,
                          'text-success': !f.password.errors?.minLength
                        }"
                        for="length"
                      >
                        <span
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.minlength,
                            'bi bi-check-circle': !f.password.errors?.minlength
                          }"
                        >
                        </span>
                        <small>{{
                          "change_password.at_least_8_charaxters" | translate
                        }}</small>
                      </label>
                      <label
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.hasSmallCase,
                          'text-success': !f.password.errors?.hasSmallCase
                        }"
                        for="lowcase"
                      >
                        <span
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.hasSmallCase,
                            'bi bi-check-circle':
                              !f.password.errors?.hasSmallCase
                          }"
                        >
                        </span>
                        <small>{{
                          "change_password.1_lower_case_letter" | translate
                        }}</small>
                      </label>
                      <label
                        style="margin-left: 10px"
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.hasNumber,
                          'text-success': !f.password.errors?.hasNumber
                        }"
                        for="number"
                      >
                        <span
                          class="ms-3"
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.hasNumber,
                            'bi bi-check-circle': !f.password.errors?.hasNumber
                          }"
                        >
                        </span>
                        <small>{{
                          "change_password.1_number" | translate
                        }}</small>
                      </label>
                    </div>
                    <div class="col-6">
                      <label
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.hasCapitalCase,
                          'text-success': !f.password.errors?.hasCapitalCase
                        }"
                        for="capital"
                      >
                        <span
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.hasCapitalCase,
                            'bi bi-check-circle':
                              !f.password.errors?.hasCapitalCase
                          }"
                        >
                        </span>
                        <small>{{
                          "change_password.1_upper_case_letter" | translate
                        }}</small>
                      </label>
                      <label
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.hasSpecialCharacters,
                          'text-success':
                            !f.password.errors?.hasSpecialCharacters
                        }"
                        for="special"
                      >
                        <span
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.hasSpecialCharacters,
                            'bi bi-check-circle':
                              !f.password.errors?.hasSpecialCharacters
                          }"
                        >
                        </span>
                        <small>{{
                          "change_password.1_special_character" | translate
                        }}</small>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="register-password-confirm"
                    >{{ "change_password.confirm_password" | translate }}
                    <abbr
                      class="text-danger"
                      title="{{ 'change_password.password' | translate }}"
                      >*</abbr
                    ></label
                  >
                  <div class="input-group">
                    <input
                      [type]="fieldTextType ? 'text' : 'password'"
                      class="form-control"
                      id="register-password-confirm"
                      formControlName="confirmPassword"
                      [ngClass]="{
                        'is-invalid':
                          f.confirmPassword.errors &&
                          (f.confirmPassword.touched ||
                            f.confirmPassword.dirty),
                        'is-valid ': f.confirmPassword.valid
                      }"
                    />
                    <span class="input-group-text">
                      <i
                        class="bi"
                        [ngClass]="{
                          'bi-eye': !fieldTextType,
                          'bi-eye-slash': fieldTextType
                        }"
                        (click)="toggleFieldTextType()"
                        style="font-size: 20px; cursor: pointer"
                      ></i>
                    </span>
                  </div>
                  <span class="text-danger">
                    <small *ngIf="f.confirmPassword.errors?.mustMatch">
                      {{ "change_password.password_not_match" | translate }}
                    </small>
                  </span>
                </div>

                <button
                  type="submit"
                  (click)="change()"
                  class="btn btn-outline-primary-2 mt-3"
                  [disabled]="isLoading"
                >
                  <span
                    *ngIf="isLoading"
                    class="spinner-border text-warning"
                    style="margin-right: 5px"
                    role="status"
                  >
                    <span class="sr-only"></span>
                  </span>
                  <span>{{ "change_password.change" | translate }}</span>
                  <i class="icon-long-arrow-right"></i>
                </button>
              </form>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
