<div class="container">
  <div class="d-flex justify-content-between mt-1">
    <buy1way-mobile-button></buy1way-mobile-button>
    <a routerLink="/" class="mobile-logo me-2">
      <img
        class="mobile-header-logo"
        src="assets/images/home/logo.png"
        alt="Buy1Way"
      />
    </a>
    <div class="header-dropdown">
      <a class="user" href="javascript:;" *ngIf="userName && !userProfile"
        ><ngx-avatars size="33" [name]="userName"></ngx-avatars
      ></a>
      <a class="user" href="javascript:;" *ngIf="userProfile"
        ><ngx-avatars size="30" [src]="userProfile"></ngx-avatars
      ></a>
      <a class="user" href="javascript:;" *ngIf="!userName && !userProfile"
        ><ngx-avatars size="30" src="assets/images/avatar.png"></ngx-avatars
      ></a>
      <div class="header-menu">
        <ul>
          <strong>
            <li>
              <a routerLink="/account" *ngIf="isLogin">{{
                "header.account" | translate
              }}</a
              ><a
                href="javascript:;"
                (click)="showRegisterModal($event)"
                *ngIf="!isLogin"
                >{{ "header.sign_up" | translate }}</a
              >
            </li>
            <li>
              <a
                href="javascript:;"
                (click)="showLoginModal($event)"
                *ngIf="!isLogin"
                >{{ "header.sign_in" | translate }}</a
              >
              <a href="javascript:;" (click)="logout()" *ngIf="isLogin">{{
                "header.sign_out" | translate
              }}</a>
            </li>
          </strong>
        </ul>
      </div>
    </div>
  </div>
  <div class="search">
    <div class="custom-search-input">
      <i class="bi bi-search search-icon"></i>
      <input
        type="text"
        class="form-control border-1"
        placeholder="{{ 'header.search' | translate }}"
        (focus)="showSearchMenu()"
        [(ngModel)]="keyword"
        style="caret-color: transparent"
      />
    </div>
    <div class="search-menu" [class.active]="menuActive">
      <button class="close-button" (click)="hideSearchMenu()">&times;</button>
      <!-- Contenu du menu de recherche -->
      <div class="container d-flex flex-column align-items-center">
        <h3>{{ "header.search" | translate }}</h3>
        <div class="search-form">
          <div class="switch">
            <input
              id="product"
              class="toggle toggle-left"
              name="toggle"
              value="product"
              type="radio"
              [(ngModel)]="type"
              (change)="onTypeChange()"
            />
            <label for="product" class="btn">{{
              "header.products" | translate
            }}</label>
            <input
              id="service"
              class="toggle toggle-right"
              name="toggle"
              value="service"
              type="radio"
              [(ngModel)]="type"
              (change)="onTypeChange()"
            />
            <label for="service" class="btn">Services</label>
          </div>
          <div class="form-group">
            <select
              class="form-control form-select select-custom border-1"
              id="cat"
              name="cat"
              (change)="onCategoryChange($event)"
            >
              <option [value]="null">
                {{ "header.all_categories" | translate }}
              </option>
              <option
                *ngFor="let category of categories"
                value="{{ category.id }}"
              >
                {{ translateCategory(category.id, locale) }}
              </option>
            </select>
          </div>

          <div class="custom-input">
            <i class="bi bi-geo-alt input-icon"></i>
            <input
              type="search"
              class="form-control border-1"
              id="city"
              placeholder="{{ 'header.town' | translate }}"
              [ngbTypeahead]="search"
              [(ngModel)]="city"
            />
          </div>
          <div class="custom-input border-1">
            <i class="bi bi-search input-icon"></i>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'header.search' | translate }}"
              [(ngModel)]="keyword"
            />
          </div>
          <button type="submit" class="btn btn-primary" (click)="onSearch()">
            {{ "header.search" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="sticky-wrapper"
  [style.height]="utilsService.isSticky ? utilsService.stickyHeight + 'px' : ''"
>
  <div
    class="header-bottom sticky-header"
    [class.fixed]="utilsService.isSticky"
  >
    <div [ngClass]="containerClass">
      <div class="row">
        <div class="col menu-list">
          <buy1way-main-menu></buy1way-main-menu>
        </div>
      </div>
    </div>
  </div>
</div>
