import { IItems } from '../../models/items.model';

export class AddProduct {
  static readonly type = '[Wishlist] Add Product';
  constructor(public product: IItems) {}
}

export class AddService {
  static readonly type = '[Wishlist] Add Service';
  constructor(public service: IItems) {}
}

export class RemoveProduct {
  static readonly type = '[Wishlist] Remove Product';
  constructor(public productId: string) {}
}

export class RemoveService {
  static readonly type = '[Wishlist] Remove Service';
  constructor(public serviceId: string) {}
}

export class ClearWishlist {
  static readonly type = '[Wishlist] Clear Wishlist';
}
