import { Injectable } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { IUser } from '../models/auth.state.model';
import { ClearWishlist } from '../state/actions/wishlist.action';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private storage: LocalStorageService,
    private authService: SocialAuthService,
    private store: Store
  ) {}

  logout() {
    this.authService.signOut();
    let user: IUser = { id: null };
    this.storage.store('user', user);
    this.store.dispatch(new ClearWishlist());
    this.storage.store('lang', this.getLanguage());
  }

  getLanguage(): string {
    if (typeof navigator !== 'undefined') {
      return navigator.language.startsWith('en') ? 'en' : 'fr';
    } else {
      return 'en';
    }
  }
}
