import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { categories } from '../../../../../pages/home/index/categories';
import { ModalService } from '../../../../services/modal.service';
import { DataService } from '../../../../../core/services/data.service';
import { IUser } from '../../../../../core/models/auth.state.model';

declare var $: any;

@Component({
  selector: 'buy1way-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent implements OnInit, OnDestroy {
  searchTerm = '';
  locale: string = 'fr';
  searchType: string;
  searchTown: string;
  userName: string;
  userProfile: string;
  isLogin: string;
  categories = categories;

  private subscr: Subscription;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private storage: LocalStorageService,
    public modalService: ModalService,
    private dataService: DataService
  ) {
    this.subscr = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.hideMobileMenu();
      }
    });
  }

  ngOnInit(): void {
    if (this.storage.retrieve('user')) {
      this.isLogin = this.storage.retrieve('user').id;
    }
    this.storage.observe('user').subscribe((user: IUser) => {
      this.isLogin = user.id;
    });
    this.locale = this.storage.retrieve('lang');
    this.translateService.use(this.locale);
    this.storage.observe('lang').subscribe({
      next: (lang) => (this.locale = lang),
    });
    this.dataService.getSearchType().subscribe({
      next: (type: string) => (this.searchType = type),
    });
  }

  onSearchTerm(event: any) {
    this.searchTerm = event.target.value;
  }
  onSearchLocation(event: any) {
    this.searchTown = event.target.value;
  }

  submitSearchForm(e: Event) {
    e.preventDefault();
    this.router.navigate(['/ads/list/' + this.searchType], {
      queryParams: {
        searchTerm: this.searchTerm,
        town: this.searchTown,
      },
    });
  }

  ngOnDestroy(): void {
    this.subscr.unsubscribe();
  }

  showLoginModal(event: Event): void {
    event.preventDefault();
    this.modalService.showLoginModal();
  }
  showRegisterModal(event: Event): void {
    event.preventDefault();
    this.modalService.showRegisterModal();
  }

  changeSearchType(event) {
    this.dataService.setSearchType(event.target.value);
  }

  submenuToggle(e) {
    const parent: HTMLElement = e.target.closest('li');
    const submenu: HTMLElement = parent.querySelector('ul');

    if (parent.classList.contains('open')) {
      $(submenu).slideUp(300, function () {
        parent.classList.remove('open');
      });
    } else {
      $(submenu).slideDown(300, function () {
        parent.classList.add('open');
      });
    }

    e.preventDefault();
    e.stopPropagation();
  }

  hideMobileMenu() {
    document.querySelector('body').classList.remove('mmenu-active');
    document.querySelector('html').removeAttribute('style');
  }

  changeLanguage(lang: string) {
    this.storage.store('lang', lang);
    this.translateService.use(lang);
  }

  getLanguage(): string {
    if (typeof navigator !== 'undefined') {
      return navigator.language.startsWith('en') ? 'en' : 'fr';
    } else {
      return 'en';
    }
  }

  logout() {
    let user: IUser = { id: null };
    this.storage.store('user', user);
    this.storage.store('wishlistService', []);
    this.storage.store('wishlistProduct', []);
    this.storage.store('lang', this.getLanguage());
    this.userName = '';
    this.isLogin = '';
    this.userName = '';
    this.userProfile = '';
  }

  translateCategory(id: string, locale: string) {
    const category = this.categories.find((category) => category.id === id);
    return locale === 'fr' ? category.nameFr : category.nameEn;
  }
  translateSubCategory(id: string, locale: string) {
    let subcategory;
    for (const category of categories) {
      subcategory = category.subcategory.find((sub) => sub.id === id);
      if (subcategory) {
        return locale === 'fr' ? subcategory.nameFr : subcategory.nameEn;
      }
    }
    return undefined;
  }
}
