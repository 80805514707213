<footer class="footer">
  <div
    class="cta cta-horizontal cta-horizontal-box bg-dark bg-image"
    style="background-image: url('assets/images/home/bg-1.jpg')"
  ></div>

  <div [ngClass]="containerClass">
    <div
      class="social-icons social-icons-color d-flex justify-content-center my-4"
    >
      <a
        href="https://www.facebook.com/buy1wayofficial"
        class="social-icon social-facebook"
        title="Facebook"
        ><i class="icon-facebook-f"></i
      ></a>
      <a
        href="https://twittercommunity.com/https://twitter.com/Buy1Way1"
        class="social-icon social-twitter"
        title="X"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-twitter-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
          />
        </svg>
      </a>
      <a
        href="https://www.instagram.com/buy1way/"
        class="social-icon social-instagram"
        title="Instagram"
        ><i class="icon-instagram"></i
      ></a>
      <a
        href="https://www.youtube.com/watch?v=DLJHvnyJB5M&feature=youtu.be"
        class="social-icon social-youtube"
        title="Youtube"
        ><i class="icon-youtube"></i
      ></a>
      <a
        href="https://www.pinterest.fr/Buy1way/"
        class="social-icon social-pinterest"
        title="Pinterest"
        ><i class="icon-pinterest"></i
      ></a>
    </div>
    <ul class="footer-menu d-flex justify-content-center my-4">
      <li>
        <a routerLink="/contact" href="javascript:;">{{
          "contact_us.contact_us" | translate
        }}</a>
      </li>
      <li class="text-end">
        <a routerLink="/terms-and-conditions" href="javascript:;">{{
          "footer.terms_and_conditions" | translate
        }}</a>
      </li>
    </ul>
  </div>

  <p class="text-center my-4">
    Copyright © {{ year }} Buy1way.com All Rights Reserved.
  </p>
  <div class="mb-10" *ngIf="isBottomSticky"></div>
</footer>
