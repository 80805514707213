<div class="main">
  <buy1way-page-header
    title="{{ 'add_an_ad.title' | translate }}"
  ></buy1way-page-header>

  <div class="container">
    <div class="add-an-ad">
      <section
        class="d-flex flex-row align-items-center justify-content-center"
        *ngIf="step == 1"
      >
        <div class="card" style="margin-right: 10px">
          <input
            type="radio"
            name="type"
            id="addProduct"
            value="product"
            [(ngModel)]="type"
            (change)="changeType($event)"
          />
          <label for="addProduct" class="text-center">
            <h5
              [ngClass]="{
                selected: type == 'product'
              }"
            >
              {{ "add_an_ad.product_for_sale" | translate }}
            </h5>
            <H5 *ngIf="type !== 'product'"><i class="bi bi-bag-plus"></i></H5>
            <H5 *ngIf="type == 'product'"
              ><i class="bi bi-bag-plus-fill selected"></i
            ></H5>
          </label>
        </div>
        <div class="card" style="margin-left: 10px">
          <input
            type="radio"
            name="type"
            id="addService"
            value="service"
            [(ngModel)]="type"
            (change)="changeType($event)"
          />
          <label for="addService" class="text-center">
            <h5
              [ngClass]="{
                selected: type == 'service'
              }"
            >
              {{ "add_an_ad.service_provision" | translate }}
            </h5>
            <h5 *ngIf="type !== 'service'">
              <i class="bi bi-person-gear"></i>
            </h5>
            <h5 *ngIf="type == 'service'">
              <i class="bi bi-person-fill-gear selected"></i>
            </h5>
          </label>
        </div>
      </section>
      <section class="step_two mx-auto" *ngIf="step == 2">
        <form [formGroup]="step2Form">
          <div class="form-group">
            <label for="name" *ngIf="type !== 'service'"
              >{{ "add_an_ad.product_name" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <label for="name" *ngIf="type == 'service'"
              >{{ "add_an_ad.name_of_service" | translate }}
              <abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  step2Form.controls.name.errors &&
                  (step2Form.controls.name.touched ||
                    step2Form.controls.name.dirty)
              }"
            />
          </div>
          <div class="form-group">
            <label for="category"
              >{{ "add_an_ad.category" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <select
              id="category"
              class="form-control form-select"
              formControlName="category"
              (change)="categoryChanges($event)"
              [ngClass]="{
                'is-invalid':
                  step2Form.controls.category.errors &&
                  (step2Form.controls.category.touched ||
                    step2Form.controls.category.dirty)
              }"
            >
              <option
                *ngFor="let category of categories"
                value="{{ category.id }}"
              >
                {{ translateCategory(category.id, locale) }}
              </option>
            </select>
          </div>
          <div class="form-group" *ngIf="subcategories.length > 0">
            <label for="subCategory"
              >{{ "add_an_ad.subcategory" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <select
              id="subCategory"
              formControlName="subCategory"
              class="form-control form-select"
              [ngClass]="{
                'is-invalid':
                  step2Form.controls.subCategory.errors &&
                  (step2Form.controls.subCategory.touched ||
                    step2Form.controls.subCategory.dirty)
              }"
            >
              <option
                *ngFor="let subcategory of subcategories"
                value="{{ subcategory.id }}"
              >
                {{ translateSubCategory(subcategory.id, locale) }}
              </option>
            </select>
          </div>
        </form>
      </section>
      <section class="step_two mx-auto" *ngIf="step == 3 && type == 'product'">
        <form [formGroup]="step3FormProduct">
          <div class="form-group">
            <label for="brand"
              >{{ "add_an_ad.brand" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <input
              type="text"
              class="form-control"
              id="brand"
              formControlName="brand"
              [ngClass]="{
                'is-invalid':
                  step3FormProduct.controls.brand.errors &&
                  (step3FormProduct.controls.brand.touched ||
                    step3FormProduct.controls.brand.dirty)
              }"
            />
          </div>
          <div class="form-group">
            <label for="price">
              {{ "add_an_ad.price" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <div class="input-group">
              <input
                type="number"
                min="0"
                class="form-control"
                id="price"
                formControlName="price"
                [ngClass]="{
                  'is-invalid':
                    step3FormProduct.controls.price.errors &&
                    (step3FormProduct.controls.price.touched ||
                      step3FormProduct.controls.price.dirty)
                }"
              />
              <span class="input-group-text" id="basic-addon2">XAF</span>
            </div>
          </div>
          <div class="form-group">
            <label for="location"
              >{{ "add_an_ad.location" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <input
              type="text"
              class="form-control"
              id="location"
              name="location"
              formControlName="location"
              [ngClass]="{
                'is-invalid':
                  step3FormProduct.controls.location.errors &&
                  (step3FormProduct.controls.location.touched ||
                    step3FormProduct.controls.location.dirty)
              }"
              ngx-google-places-autocomplete
              (onAddressChange)="handleAddressChange($event)"
            />
          </div>
        </form>
      </section>
      <section class="step_two mx-auto" *ngIf="step == 3 && type == 'service'">
        <form [formGroup]="step3FormService">
          <div class="form-group">
            <label for="days">
              {{ "add_an_ad.day_of_availability" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <ng-select
              [multiple]="true"
              [hideSelected]="true"
              placeholder="Selectionner les jours"
              id="days"
              formControlName="days"
              [ngClass]="{
                'is-invalid':
                  step3FormService.controls.days.errors &&
                  (step3FormService.controls.days.touched ||
                    step3FormService.controls.days.dirty)
              }"
            >
              <ng-option *ngFor="let item of daysList" [value]="item.id">{{
                item.nameFr
              }}</ng-option>
            </ng-select>
          </div>
          <div class="form-group">
            <label>
              {{ "add_an_ad.timetable" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <div class="row">
              <div class="col-12 col-sm-3">
                <div class="form-check">
                  <input
                    id="hour"
                    class="form-check-input"
                    type="checkbox"
                    name="hour"
                    style="margin-top: 7px"
                    (change)="isHour24 = !isHour24"
                  />
                  <label class="form-check-label" for="hour"> 24h/24</label>
                </div>
              </div>
              <div class="col-12 col-sm-9">
                <span *ngIf="!isHour24">
                  <label for="startTime">{{
                    "add_an_ad.start" | translate
                  }}</label
                  ><input
                    type="time"
                    formControlName="startTime"
                    id="startTime"
                  />
                </span>
                <span class="ms-2" *ngIf="!isHour24">
                  <label for="endTime">{{ "add_an_ad.end" | translate }}</label
                  ><input
                    type="time"
                    formControlName="endTime"
                    name="endTime"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="price">
              {{ "add_an_ad.basic_price" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <div class="input-group">
              <input
                type="number"
                min="0"
                class="form-control"
                id="priceService"
                formControlName="priceService"
                [ngClass]="{
                  'is-invalid':
                    step3FormService.controls.priceService.errors &&
                    (step3FormService.controls.priceService.touched ||
                      step3FormService.controls.priceService.dirty)
                }"
              />
              <span class="input-group-text" id="basic-addon2">XAF</span>
            </div>
          </div>
          <div class="form-group">
            <label for="locationService"
              >{{ "add_an_ad.location" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <input
              type="text"
              class="form-control"
              id="locationService"
              name="locationService"
              formControlName="locationService"
              [ngClass]="{
                'is-invalid':
                  step3FormService.controls.locationService.errors &&
                  (step3FormService.controls.locationService.touched ||
                    step3FormService.controls.locationService.dirty)
              }"
              ngx-google-places-autocomplete
              (onAddressChange)="handleAddressChange($event)"
            />
          </div>
        </form>
      </section>
      <section class="step_two mx-auto" *ngIf="step == 4">
        <form [formGroup]="step4Form">
          <div class="form-group mb-2">
            <label for="description"
              >{{ "add_an_ad.description" | translate
              }}<abbr
                class="text-danger"
                title="{{ 'add_an_ad.this_is_required' | translate }}"
                >*</abbr
              ></label
            >
            <textarea
              class="form-control"
              rows="10"
              maxlength="2000"
              id="description"
              formControlName="description"
              [ngClass]="{
                'is-invalid':
                  step4Form.controls.description.errors &&
                  (step4Form.controls.description.touched ||
                    step4Form.controls.description.dirty)
              }"
            ></textarea>
          </div>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="showPhone"
              checked
              (change)="isShowPhone = !isShowPhone"
            />
            <label class="form-check-label" for="showPhone">
              {{ "add_an_ad.show_phone" | translate }}</label
            >
          </div>
        </form>
      </section>
      <section class="step_two mx-auto" *ngIf="step == 5">
        <div class="form-group">
          <label class="mb-3"
            >{{ "add_an_ad.add_pictures" | translate
            }}<abbr
              class="text-danger"
              title="{{ 'add_an_ad.this_is_required' | translate }}"
              >*</abbr
            ><span class="text-danger">(min 2, max 5)</span>
          </label>
          <div class="container-image__upload">
            <div class="row">
              <div class="col col-5 col-sm-3 m-2">
                <div
                  class="upload-image"
                  [class.upload-max-reached]="uploadedImages.length >= 5"
                  (click)="uploadInput.click()"
                >
                  <input
                    class="upload-input visually-hidden"
                    type="file"
                    #uploadInput
                    accept="image/png, image/jpeg, image/jpg"
                    (change)="uploadImage($event)"
                  />
                  <div class="upload-icon">
                    <i
                      class="bi bi-camera bouncing-icon"
                      [ngStyle]="{ color: uploadIconColor }"
                      (mouseenter)="uploadIconColor = '#f9ba23'"
                      (mouseleave)="uploadIconColor = '#f9ba23'"
                    ></i>
                  </div>
                </div>
              </div>
              <div
                class="col col-5 col-sm-3 m-2"
                *ngFor="let image of uploadedImages"
              >
                <div class="uploaded-image">
                  <!-- Display the uploaded image -->
                  <img [src]="image.url" alt="Uploaded Image" />
                  <div class="image-number">{{ image.number }}</div>
                  <div class="delete-icon" (click)="deleteImage(image)">
                    <i class="bi bi-x-lg"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="mt-4 mx-sm-5 d-flex justify-content-around">
        <button
          type="button"
          class="btn btn-minwidth-sm btn-primary"
          *ngIf="step != 1"
          (click)="handlePrevious()"
        >
          <i class="icon-long-arrow-left"></i>
          <span>{{ "add_an_ad.back" | translate }}</span>
        </button>
        <button
          type="button"
          class="btn btn-minwidth-sm btn-primary"
          *ngIf="step != 5"
          (click)="handleNext()"
        >
          <span> {{ "add_an_ad.next" | translate }}</span>
          <i class="icon-long-arrow-right"></i>
        </button>
        <button
          type="button"
          class="btn btn-minwidth-sm btn-primary"
          *ngIf="step == 5"
          (click)="handleSubmit()"
          [disabled]="isLoading"
        >
          <span
            *ngIf="isLoading"
            class="spinner-border text-light"
            style="margin-right: 5px"
            role="status"
          >
          </span>
          <span>{{ "add_an_ad.publish" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
