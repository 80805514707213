<div class="modal-body">
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true"><i class="icon-close"></i></span>
  </button>

  <div class="form-box">
    <div class="form-tab nav-fill">
      <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
        <li ngbNavItem>
          <a ngbNavLink *ngIf="resetPassword == false">{{
            "login.title_sign_in" | translate
          }}</a>
          <a ngbNavLink *ngIf="resetPassword == true">{{
            "login.title_reset_password" | translate
          }}</a>
          <ng-template ngbNavContent>
            <div *ngIf="resetPassword == false">
              <div
                class="row mb-1 d-flex justify-content-center align-items-center"
              >
                <div class="col-sm-6 d-flex justify-content-center mb-2">
                  <button
                    id="googleButton"
                    style="font: normal 300 1.4rem/1.86 Poppins, sans-serif"
                  ></button>
                </div>
                <div class="col-sm-6 d-flex justify-content-center mb-2">
                  <button (click)="signInWithFB()" class="btn btn-login btn-f">
                    <i class="icon-facebook-f"></i>
                    {{ "login.with_facebook" | translate }}
                  </button>
                </div>
              </div>
              <div class="form-choice">
                <p class="text-center mb-2">
                  <strong>{{ "login.or_sign_in_with" | translate }}</strong>
                </p>
                <form [formGroup]="loginForm">
                  <div
                    class="alert alert-danger alert-dismissible fade show my-4 text-center"
                    role="alert"
                    *ngIf="isError"
                  >
                    {{ errorMessage }}
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div class="form-group">
                    <label for="singin-email"
                      >{{ "login.email_address" | translate }}
                      <abbr class="text-danger" title="This is required"
                        >*</abbr
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="singin-email"
                      formControlName="email"
                      [ngClass]="{
                        'is-invalid':
                          f.email.errors && (f.email.touched || f.email.dirty)
                      }"
                    />
                  </div>

                  <div class="form-group">
                    <label for="singin-password"
                      >{{ "login.password" | translate }}
                      <abbr class="text-danger" title="This is required"
                        >*</abbr
                      ></label
                    >
                    <div class="input-group">
                      <input
                        [type]="fieldTextType ? 'text' : 'password'"
                        class="form-control"
                        id="singin-password"
                        formControlName="password"
                        [ngClass]="{
                          'is-invalid':
                            f.password.errors &&
                            (f.password.touched || f.password.dirty)
                        }"
                      />
                      <span class="input-group-text">
                        <i
                          class="bi"
                          [ngClass]="{
                            'bi-eye': !fieldTextType,
                            'bi-eye-slash': fieldTextType
                          }"
                          (click)="toggleFieldTextType()"
                          style="font-size: 20px; cursor: pointer"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-sm-12">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="custom-control-input me-2"
                          id="signin-remember"
                          formControlName="rememberMe"
                        />
                        <label
                          class="form-check-label mb-1"
                          for="signin-remember"
                          >{{ "login.remember_me" | translate }}</label
                        >
                      </div>
                    </div>
                    <div
                      class="col-auto col-sm-12 ms-4 col-sm-12 d-flex align-items-center ms-2"
                    >
                      <a
                        href="javascript:;"
                        (click)="showResetPassword()"
                        class="forgot-link"
                        >{{ "login.forgot_your_password" | translate }}</a
                      >
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <button
                      type="button"
                      (click)="login()"
                      class="btn btn-outline-primary-2 mx-3 my-4"
                      [disabled]="isLoading"
                    >
                      <span
                        *ngIf="isLoading"
                        class="spinner-border text-warning"
                        style="margin-right: 5px"
                        role="status"
                      >
                        <span class="sr-only"></span>
                      </span>
                      <span>{{ "login.login" | translate }}</span>
                      <i class="icon-long-arrow-right"></i>
                    </button>
                    <a
                      href="javascript:;"
                      (click)="register()"
                      class="forgot-link my-4 text-center"
                      >Créer un compte</a
                    >
                  </div>
                </form>
              </div>
            </div>
            <div *ngIf="resetPassword == true">
              <div class="form-choice">
                <form [formGroup]="resetEmailForm">
                  <div class="form-group">
                    <label for="singin-email"
                      >{{ "login.email_address" | translate }}
                      <abbr class="text-danger" title="This is required"
                        >*</abbr
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="singin-email"
                      formControlName="email"
                      [ngClass]="{
                        'is-invalid':
                          resetEmailForm.controls.email.errors &&
                          (resetEmailForm.controls.email.touched ||
                            resetEmailForm.controls.email.dirty)
                      }"
                    />
                  </div>

                  <button
                    type="button"
                    (click)="sendEmail()"
                    class="btn btn-outline-primary-2 mr-3 mt-2"
                    [disabled]="isLoading"
                  >
                    <span
                      *ngIf="isLoading"
                      class="spinner-border text-warning"
                      style="margin-right: 5px"
                      role="status"
                    >
                      <span class="sr-only"></span>
                    </span>
                    <span>{{ "login.submit" | translate }}</span>
                    <i class="icon-long-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
