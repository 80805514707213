import { Component } from '@angular/core';

@Component({
  selector: 'buy1way-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss']
})
export class CguComponent {

}
