<div class="main">
  <nav class="breadcrumb-nav border-0 mb-0">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">Home</a>
        </li>
        <li class="breadcrumb-item active">About Us</li>
      </ol>
    </div>
  </nav>

  <div class="container">
    <div
      class="page-header page-header-big text-center"
      style="background-image: url('assets/images/about-header-bg.jpg')"
    >
      <h1 class="page-title text-white">
        About us<span class="text-white">Who we are</span>
      </h1>
    </div>
  </div>

  <div class="page-content pb-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3 mb-lg-0">
          <h2 class="title">Our Vision</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
            hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque,
            aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula
            sollicitudin laoreet viverra, tortor libero sodales leo, eget
            blandit nunc tortor eu nibh.
          </p>
        </div>

        <div class="col-lg-6">
          <h2 class="title">Our Mission</h2>
          <p>
            Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae
            luctus metus libero eu augue. Morbi purus libero, faucibus
            adipiscing, commodo quis, gravida id, est. Sed lectus.
            <br />Praesent elementum hendrerit tortor. Sed semper lorem at
            felis.
          </p>
        </div>
      </div>

      <div class="mb-5"></div>
    </div>

    <div class="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 mb-3 mb-lg-0">
            <h2 class="title">Who We Are</h2>
            <p class="lead text-primary mb-3">
              Pellentesque odio nisi, euismod pharetra a ultricies <br />in
              diam. Sed arcu. Cras consequat
            </p>
            <p class="mb-2">
              Sed pretium, ligula sollicitudin laoreet viverra, tortor libero
              sodales leo, eget blandit nunc tortor eu nibh. Suspendisse
              potenti. Sed egestas, ante et vulputate volutpat, uctus metus
              libero eu augue.
            </p>

            <a
              routerLink="/blog/classic"
              class="btn btn-sm btn-minwidth btn-outline-primary-2"
            >
              <span>VIEW OUR NEWS</span>
              <i class="icon-long-arrow-right"></i>
            </a>
          </div>

          <div class="col-lg-6 offset-lg-1">
            <div class="about-images">
              <img
                src="assets/images/about/img-1.jpg"
                alt="About"
                class="about-img-front"
              />
              <img
                src="assets/images/about/img-2.jpg"
                alt="About"
                class="about-img-back"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="brands-text">
            <h2 class="title">
              The world's premium design brands in one destination.
            </h2>
            <p>
              Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi
              neque, aliquet vel, dapibus id, mattis vel, nis
            </p>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="brands-display">
            <div class="row justify-content-center">
              <div class="col-6 col-sm-4" *ngFor="let brand of brands">
                <a href="javascript:;" class="brand">
                  <img [src]="brand.image" alt="Brand Name" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="mt-4 mb-6" />

      <h2 class="title text-center mb-4">Meet Our Team</h2>

      <owl-carousel-o [options]="teamOption">
        <div class="member member-anim text-center" *ngFor="let member of team">
          <figure class="member-media">
            <buy1way-image
              [src]="member.photo"
              width="376"
              height="500"
              alt="member photo"
            ></buy1way-image>

            <figcaption class="member-overlay">
              <div class="member-overlay-content">
                <h3 class="member-title">
                  {{ member.name }}<span>{{ member.title }}</span>
                </h3>
                <p>
                  Sed pretium, ligula sollicitudin viverra, tortor libero
                  sodales leo, eget blandit nunc.
                </p>
                <div class="social-icons social-icons-simple">
                  <a href="javascript:;" class="social-icon" title="Facebook"
                    ><i class="icon-facebook-f"></i
                  ></a>
                  <a href="javascript:;" class="social-icon" title="Twitter"
                    ><i class="icon-twitter"></i
                  ></a>
                  <a href="javascript:;" class="social-icon" title="Instagram"
                    ><i class="icon-instagram"></i
                  ></a>
                </div>
              </div>
            </figcaption>
          </figure>
          <div class="member-content">
            <h3 class="member-title">
              {{ member.name }}<span>{{ member.title }}</span>
            </h3>
          </div>
        </div>
      </owl-carousel-o>
    </div>

    <div class="mb-2"></div>

    <div
      class="about-testimonials bg-light-2 pt-6 pb-6"
      style="margin-bottom: -1px"
    >
      <div class="container">
        <h2 class="title text-center mb-3">What Customer Say About Us</h2>

        <owl-carousel-o [options]="sliderOption">
          <blockquote
            class="testimonial text-center"
            *ngFor="let custom of customSay"
          >
            <img [src]="custom.photo" width="70" height="70" alt="user" />
            <p>{{ custom.comment }}</p>
            <cite>
              {{ custom.name }}
              <span>{{ custom.type }}</span>
            </cite>
          </blockquote>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
