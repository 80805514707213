import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { ISearchStateModel } from '../models/search.model';
import {
  IImageProduct,
  IImageService,
  IItems,
  IUpdateProduct,
  IUpdateService,
  IrecentItem,
} from '../models/items.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private sharedData = new BehaviorSubject<any>([]);
  private searchtype = new BehaviorSubject<string>('product');
  private baseurl = environment.API_URL;

  constructor(private http: HttpClient, private store: Store) {}

  setData(data: any) {
    this.sharedData.next(data);
  }

  getData(): Observable<any> {
    return this.sharedData.asObservable();
  }

  setSearchType(type: string) {
    this.searchtype.next(type);
  }

  getSearchType(): Observable<string> {
    return this.searchtype.asObservable();
  }

  /**
   * Get Services by id
   */
  public getServicesById(
    user_id: string,
    page: number,
    limit: number
  ): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    return this.http
      .get<any>(this.baseurl + 'services/' + user_id)
      .pipe(catchError(this.handleHttpError));
  }
  /**
   * Get all locations
   */
  public getLocations(): Observable<string[]> {
    return this.http
      .get<any[]>(this.baseurl + 'locations')
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * Get Product by user ID
   */
  public getProductsById(
    user_id: string,
    page: number,
    limit: number
  ): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    return this.http
      .get<any>(this.baseurl + 'products/' + user_id, { params: params })
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * Get recents services
   */
  public getRecentServices(): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseurl + 'recent/services')
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * Get recents products
   */
  public getRecentProducts(): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseurl + 'recent/products')
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * Get recents items
   */
  public getRecentItems(): Observable<IrecentItem[]> {
    return this.http
      .get<IrecentItem[]>(this.baseurl + 'recent/items')
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * Get datas
   */

  getsData(search: ISearchStateModel): Observable<any> {
    const params = new HttpParams()
      .set('page', search.page.toString())
      .set('limit', search.limit.toString())
      .set('type', search.type)
      .set('category', search.category)
      .set('subcategory', search.subcategory)
      .set('city', search.city)
      .set('keyword', search.keyword);
    return this.http
      .get<any[]>(this.baseurl + 'search', { params: params })
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * Get single Product
   */
  public getSingleProduct(id: string): Observable<any> {
    return this.http
      .get<any>(this.baseurl + 'product/' + id)
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * Get single Service
   */
  public getSingleService(id: string): Observable<any> {
    return this.http
      .get<any>(this.baseurl + 'service/' + id)
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * update product
   */
  updateProduct(id: string, product: IUpdateProduct): Observable<any> {
    return this.http
      .post<any>(`${this.baseurl}product/update/${id}`, product)
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * update service
   */
  updateService(id: string, service: IUpdateService): Observable<any> {
    return this.http
      .post<any>(`${this.baseurl}service/update/${id}`, service)
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * delete image product by id
   */
  deleteImageProduct(id: string): Observable<any> {
    return this.http
      .delete<any>(`${this.baseurl}imageProduct/${id}`)
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * delete image service by id
   */
  deleteImageService(id: string): Observable<any> {
    return this.http
      .delete<any>(`${this.baseurl}imageService/${id}`)
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * add image service
   */
  public addImageService(image: FormData): Observable<IImageService> {
    return this.http
      .post<IImageService>(this.baseurl + 'imageService', image)
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * add image product
   */
  public addImageProduct(image: FormData): Observable<IImageProduct> {
    return this.http
      .post<IImageProduct>(this.baseurl + 'imageProduct', image)
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * Get count of services
   */
  getCountServices(user_id: string): Observable<number> {
    return this.http
      .get<number>(this.baseurl + 'services/count/' + user_id)
      .pipe(catchError(this.handleHttpError));
  }

  /**
   * Get count of products
   */
  getCountProducts(user_id: string): Observable<number> {
    return this.http
      .get<number>(this.baseurl + 'products/count/' + user_id)
      .pipe(catchError(this.handleHttpError));
  }

  private handleHttpError(err: HttpErrorResponse) {
    let error: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
      error = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${err.status}, ` + `body was: ${err.error}`
      );
      error = `Backend returned code ${err.status}, body was: ${err.error}`;
    }
    // Return an observable with a user-facing error message.
    return throwError(err);
  }
}
