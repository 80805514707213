import { Component, NgZone, OnInit } from '@angular/core';
import {
  FacebookLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { accounts } from 'google-one-tap';
import jwt_decode from 'jwt-decode';

import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CustomValidators } from './Customvalidator';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

import { WishlistService } from '../../../../core/services/wishlist.service';
import { IUser } from '../../../../core/models/auth.state.model';
import { UserService } from '../../../../core/services/user.service';
declare var google: any;

@Component({
  selector: 'buy1way-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss'],
})
export class RegisterModalComponent implements OnInit {
  isLoading: boolean = false;
  public registrationForm: UntypedFormGroup;
  public fieldTextType: boolean;

  constructor(
    private ngZone: NgZone,
    private authService: SocialAuthService,
    private useService: UserService,
    private storage: LocalStorageService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    public wishlistService: WishlistService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.authService.authState.subscribe((data) => {
      let user: IUser = {
        facebookId: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.name,
        email: data.email,
        profilePic: data.photoUrl,
      };

      this.useService.loginFacebook(user).subscribe({
        next: (user: IUser) => {
          this.storage.store('user', user);
          this.useService.getsWishListServices(user.id).subscribe({
            next: (data) => this.storage.store('wishlistService', data),
          });
          this.useService.getsWishListProducts(user.id).subscribe({
            next: (data) => this.storage.store('wishlistProduct', data),
          });
          this.toastr.success(
            this.translateService.instant(
              'login.you_are_successfully_logged_in'
            )
          );
          this.closeModal();
          this.wishlistService.getWishlist(user.id);
        },
        error: (err) => {
          if (err.status == '409') {
            this.toastr.error(
              this.translateService.instant(
                'login.this_email_address_is_already_used'
              )
            );
          } else {
            this.toastr.error(
              this.translateService.instant('login.an_error_has_occurred')
            );
          }
        },
      });
    });
  }

  ngAfterViewInit() {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: ((window as any)['handleCredentialResponse'] = (
        response: any
      ) =>
        this.ngZone.run(() => {
          this.loginGoogle(jwt_decode(response.credential));
        })),
    });

    google.accounts.id.renderButton(document.getElementById('googleButton'), {
      type: 'standard',
      text: 'Google',
      theme: 'outline',
      size: 'medium',
      width: '100',
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  closeModal() {
    let modal = document.querySelector('.login-modal') as HTMLElement;
    if (modal) modal.click();
  }

  loginGoogle(googleDetails: any) {
    let user: IUser = {
      googleId: googleDetails.sub,
      firstName: googleDetails.given_name,
      lastName: googleDetails.family_name,
      userName: googleDetails.name,
      email: googleDetails.email,
      profilePic: googleDetails.picture,
    };
    this.useService.loginGoogle(user).subscribe({
      next: (user: IUser) => {
        this.storage.store('user', user);
        this.useService.getsWishListServices(user.id).subscribe({
          next: (data) => this.storage.store('wishlistService', data),
        });
        this.useService.getsWishListProducts(user.id).subscribe({
          next: (data) => this.storage.store('wishlistProduct', data),
        });
        this.toastr.success(
          this.translateService.instant('login.you_are_successfully_logged_in')
        );
        this.closeModal();
        this.wishlistService.getWishlist(user.id);
      },
      error: (err) => {
        if (err.status == '409') {
          this.toastr.error(
            this.translateService.instant(
              'login.this_email_address_is_already_used'
            )
          );
        } else {
          this.toastr.error(
            this.translateService.instant('login.an_error_has_occurred')
          );
        }
      },
    });
  }

  initForm(): void {
    this.registrationForm = this.fb.group(
      {
        userName: ['', [Validators.required]],
        email: [
          '',
          [
            Validators.required,
            CustomValidators.patternValidator(
              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              { hasEmail: true }
            ),
          ],
        ],
        password: [
          null,
          [
            Validators.minLength(8),
            Validators.required,
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            CustomValidators.patternValidator(/[#?!@$%^_\-&*~(){}\[\]|\\]/, {
              hasSpecialCharacters: true,
            }),
          ],
        ],
        policy: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: CustomValidators.mustMatch('password', 'confirmPassword'),
      }
    );
  }

  create(): void {
    if (this.registrationForm.valid) {
      this.isLoading = true;
      let user: IUser = {
        userName: this.registrationForm.controls.userName.value,
        email: this.registrationForm.controls.email.value,
        password: this.registrationForm.controls.password.value,
      };
      const formData = new FormData();
      formData.append(
        'userName',
        this.registrationForm.controls.userName.value
      );
      formData.append('email', this.registrationForm.controls.email.value);
      formData.append(
        'password',
        this.registrationForm.controls.password.value
      );
      formData.append('locale', this.getLanguage());
      this.useService.createUser(formData).subscribe({
        next: () => {
          this.isLoading = false;
          Swal.fire(
            this.translateService.instant(
              'register.thank_you_for_your_registration'
            ),
            this.translateService.instant(
              'register.you_account_has_been_successfully_created'
            ),
            'success'
          );
          this.closeModal();
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
          this.toastr.error(
            this.translateService.instant('register.an_error_has_occurred')
          );
        },
      });
    } else {
      this.validateAllFormFields(this.registrationForm);
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  get f() {
    return this.registrationForm.controls;
  }

  getLanguage(): string {
    if (typeof navigator !== 'undefined') {
      return navigator.language.startsWith('en') ? 'en' : 'fr';
    } else {
      return 'en';
    }
  }
}
function isLocalImage(str) {
  return !str.startsWith('http://') && !str.startsWith('https://');
}
