import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './shared/layout/layout.component';
import { IndexComponent } from './pages/home/index/index.component';
import { AddAnAdComponent } from './pages/add-an-ad/add-an-ad.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { AccountConfirmationComponent } from './pages/account-confirmation/account-confirmation.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { CguComponent } from './pages/cgu/cgu.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: IndexComponent,
      },
      {
        path: 'new',
        canActivate: [AuthGuard],
        pathMatch: 'full',
        component: AddAnAdComponent,
      },
      {
        path: '404',
        pathMatch: 'full',
        component: PageNotFoundComponent,
      },
      {
        path: 'email-verification/:token',
        pathMatch: 'full',
        component: AccountConfirmationComponent,
      },
      {
        path: 'about-us',
        pathMatch: 'full',
        component: AboutUsComponent,
      },
      {
        path: 'terms-and-conditions',
        pathMatch: 'full',
        component: CguComponent,
      },
      {
        path: 'coming-soon',
        pathMatch: 'full',
        component: ComingSoonComponent,
      },
      {
        path: 'contact',
        pathMatch: 'full',
        component: ContactUsComponent,
      },

      {
        path: 'account',
        loadChildren: () =>
          import('./pages/account/account.module').then((m) => m.AccountModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'ads',
        loadChildren: () =>
          import('./pages/ads-list/ads.module').then((m) => m.AdsModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/ad-details/ad-detail.module').then(
            (m) => m.AdDetailModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/professional/professional.module').then(
            (m) => m.ProfessionalModule
          ),
      },
    ],
  },
  {
    path: 'change-password/:code',
    pathMatch: 'full',
    component: ChangePasswordComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'disabled',
    scrollPositionRestoration: 'disabled',
    initialNavigation: 'enabledBlocking'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
