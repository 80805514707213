<div class="page-wrapper">
  <buy1way-header></buy1way-header>

  <div [@routeAnimations]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>

  <buy1way-footer
    [containerClass]="containerClass"
    [isBottomSticky]="isBottomSticky"
  ></buy1way-footer>
</div>
