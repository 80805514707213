<main class="main">
  <nav class="breadcrumb-nav breadcrumb-nav-fluid border-0 mb-0">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">{{ "not_found.home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active">404</li>
      </ol>
    </div>
  </nav>

  <div
    class="error-content text-center position-relative"
    style="
      background-image: url(assets/images/backgrounds/error-bg.jpg);
      margin-bottom: -1px;
    "
  >
    <div class="container">
      <h1 class="error-title">{{ "not_found.error_404" | translate }}</h1>
      <p>{{ "not_found.we_are_sorry" | translate }}</p>
      <a routerLink="/" class="btn btn-outline-primary-2 btn-minwidth-lg">
        <span>{{ "not_found.back_to_home" | translate }}</span>
        <i class="icon-long-arrow-right"></i>
      </a>
    </div>
  </div>
</main>
