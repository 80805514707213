<div
  class="product product-5 text-center p-lg-0"
  style="border-radius: 10px; border: 1px rgba(14, 30, 37, 0.12) solid"
>
  <figure>
    <!-- <span class="product-label label-new" *ngIf="product.new">New</span>
    <span class="product-label label-sale" *ngIf="product.sale_price"
      >Sale</span
    >
    <span class="product-label label-top" *ngIf="product.top">Top</span>
    <span
      class="product-label label-out"
      *ngIf="!product.stock || product.stock == 0"
      >Out Of Stock</span
    > -->

    <a
      [routerLink]="['/ad-detail/' + item.slug]"
      [queryParams]="{ id: item.id, type: item.type }"
    >
      <buy1way-image
        [src]="item.images[0].url"
        alt="Product"
        [height]="60"
        [width]="60"
        [objectFitValue]="'cover'"
        class="product-image"
      >
      </buy1way-image>
      <buy1way-image
        [height]="60"
        [width]="60"
        [src]="item.images[1].url"
        alt="Product"
        [objectFitValue]="'cover'"
        class="product-image-hover"
        *ngIf="item.images[1]"
      ></buy1way-image>
    </a>
    <div class="product-action-vertical">
      <a
        href="javascript:;"
        class="btn-product-icon btn-wishlist btn-expandable"
        [class.added-to-wishlist]="isInWishlist()"
        (click)="addToWishlist($event)"
      >
        <span>{{ "ad_details.add_to_favourites" | translate }}</span>
      </a>
    </div>
  </figure>

  <div
    class="product-body"
    style="
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      border-radius: 10px;
    "
  >
    <h3 class="product-title">
      <a
        [routerLink]="['/ad-detail/' + item.slug]"
        [queryParams]="{ id: item.id, type: item.type }"
        >{{ item.name }}</a
      >
    </h3>

    <div class="product-price">{{ item.price | currencyFormatter }}</div>
  </div>
</div>
