import { Action, Selector, State, StateContext } from '@ngxs/store';

import IWishlistModel from '../../models/wishlist.model';
import {
  AddProduct,
  AddService,
  ClearWishlist,
  RemoveProduct,
  RemoveService,
} from '../actions/wishlist.action';
import { Injectable } from '@angular/core';

@State<IWishlistModel>({
  name: 'wishlist',
  defaults: {
    products: [],
    services: [],
    total: 0,
  },
})
@Injectable()
export class WishlistState {
  @Selector()
  static getProducts(state: IWishlistModel) {
    return state.products;
  }

  @Selector()
  static getServices(state: IWishlistModel) {
    return state.services;
  }

  @Selector()
  static getTotal(state: IWishlistModel) {
    return state.total;
  }

  @Action(AddProduct)
  addProduct(ctx: StateContext<IWishlistModel>, action: AddProduct) {
    const state = ctx.getState();
    const isProductExist = state.products.some(
      (product) => product.id === action.product.id
    );
    if (!isProductExist) {
      const updatedProducts = [...state.products, action.product];
      const updatedTotal = state.total + 1;
      ctx.patchState({
        products: updatedProducts,
        total: updatedTotal,
      });
    }
  }

  @Action(AddService)
  addService(ctx: StateContext<IWishlistModel>, action: AddService) {
    const state = ctx.getState();
    const isServiceExist = state.services.some(
      (service) => service.id === action.service.id
    );
    if (!isServiceExist) {
      const updatedServices = [...state.services, action.service];
      const updatedTotal = state.total + 1;
      ctx.patchState({
        services: updatedServices,
        total: updatedTotal,
      });
    }
  }

  @Action(RemoveProduct)
  removeProduct(ctx: StateContext<IWishlistModel>, action: RemoveProduct) {
    const state = ctx.getState();
    const updatedProducts = state.products.filter(
      (product) => product.id !== action.productId
    );
    const updatedTotal = state.total - 1;
    ctx.patchState({
      products: updatedProducts,
      total: updatedTotal,
    });
  }

  @Action(RemoveService)
  removeService(ctx: StateContext<IWishlistModel>, action: RemoveService) {
    const state = ctx.getState();
    const updatedServices = state.services.filter(
      (service) => service.id !== action.serviceId
    );
    const updatedTotal = state.total - 1;
    ctx.patchState({
      services: updatedServices,
      total: updatedTotal,
    });
  }

  @Action(ClearWishlist)
  clearWishlist(ctx: StateContext<IWishlistModel>) {
    ctx.setState({
      products: [],
      services: [],
      total: 0,
    });
  }
}
