import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
import { UtilsService } from './core/services/utils.service';
import { IUser } from './core/models/auth.state.model';

declare var $: any;

@Component({
  selector: 'buy1way-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private navigator: Navigator;

  constructor(
    public router: Router,
    public viewPort: ViewportScroller,
    public utilsService: UtilsService,
    public modalService: NgbModal,
    private storage: LocalStorageService
  ) {
    const navigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navigationEnd.pipe(first()).subscribe(() => {
      if (typeof document !== 'undefined') {
        document.querySelector('body')?.classList.add('loaded');
      }
      if (typeof document !== 'undefined') {
        var timer = setInterval(() => {
          if (
            window.getComputedStyle(document.querySelector('body'))
              .visibility == 'visible'
          ) {
            clearInterval(timer);
            $('.owl-carousel').trigger('refresh.owl.carousel');
          }
        }, 300);
      }
    });

    navigationEnd.subscribe((event: any) => {
      if (
        !event.url.includes('/shop/sidebar') &&
        !event.url.includes('/shop/nosidebar') &&
        !event.url.includes('/shop/market') &&
        !event.url.includes('/blog')
      ) {
        this.viewPort.scrollToPosition([0, 0]);
      }

      this.modalService.dismissAll();
    });
  }
  ngOnInit(): void {
    if (!this.storage.retrieve('user')) {
      let user: IUser = { id: null };
      this.storage.store('user', user);
      this.storage.store('wishlistService', []);
      this.storage.store('wishlistProduct', []);
      this.storage.store('lang', this.getLanguage());
    }
  }

  @HostListener('window: scroll', ['$event'])
  onWindowScroll(e: Event) {
    this.utilsService.setStickyHeader();
  }

  hideMobileMenu() {
    if (typeof document !== 'undefined') {
      document.querySelector('body').classList.remove('mmenu-active');
      document.querySelector('html').style.overflowX = 'unset';
    }
  }

  getLanguage(): string {
    if (typeof navigator !== 'undefined') {
      return navigator.language.startsWith('en') ? 'en' : 'fr';
    } else {
      return 'en';
    }
  }
}
