<div
  class="container newsletter-popup-container h-auto"
  id="newsletter-popup-form"
>
  <div class="modal-content overflow-hidden m-0">
    <div class="row justify-content-center position-relative">
      <div class="col-12">
        <div class="row no-gutters bg-white newsletter-popup-content">
          <div class="col-xl-3-5col col-lg-7 banner-content-wrap">
            <div class="banner-content text-center">
              <img
                src="assets/images/popup/newsletter/logo.png"
                class="logo"
                alt="logo"
                width="60"
                height="15"
              />
              <h2 class="banner-title">
                get <span>25<span style="font-weight: 400">%</span></span> off
              </h2>
              <p>
                Subscribe to the buy1way eCommerce newsletter to receive timely
                updates from your favorite products.
              </p>
              <form action="#">
                <div class="input-group input-group-round">
                  <input
                    type="email"
                    class="form-control form-control-white"
                    placeholder="Your Email Address"
                    aria-label="Email Adress"
                    required
                  />
                  <div class="input-group-append">
                    <button class="btn" type="submit"><span>go</span></button>
                  </div>
                </div>
              </form>

              <div class="custom-control custom-checkbox pl-4 ml-3">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="register-policy-2"
                  (click)="changeCheck()"
                />
                <label class="custom-control-label" for="register-policy-2"
                  >Do not show this popup again</label
                >
              </div>
            </div>
          </div>

          <div class="col-xl-2-5col col-lg-5 d-none d-lg-block">
            <buy1way-image
              src="assets/images/popup/newsletter/img-1.jpg"
              width="396"
              height="420"
              class="newsletter-img"
              alt="newsletter"
            ></buy1way-image>
          </div>
        </div>
      </div>

      <button
        title="Close (Esc)"
        type="button"
        class="mfp-close"
        (click)="closeModal()"
      >
        <span>×</span>
      </button>
    </div>
  </div>
</div>
