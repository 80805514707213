import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
import { ModalService } from '../services/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private storage: LocalStorageService,
    public modalService: ModalService,
    private translateService: TranslateService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.storage.retrieve('user').id) {
      return true;
    } else {
      Swal.fire({
        title: this.translateService.instant('header.warning'),
        text: this.translateService.instant('header.you_must_be_logged_in'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(() => {
        this.modalService.showLoginModal();
      });
      return false;
    }
  }
}
