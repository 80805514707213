import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxImageCompressService } from 'ngx-image-compress';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

import { categories } from '../home/index/categories';
import { AdService } from '../../core/services/ad.service';
import { IItems } from '../../core/models/items.model';

interface CustomFile extends File {
  url: string;
  number: number;
}

@Component({
  selector: 'buy1way-add-an-ad',
  templateUrl: './add-an-ad.component.html',
  styleUrls: ['./add-an-ad.component.scss'],
})
export class AddAnAdComponent implements OnInit {
  defaultCategories = categories;
  categories = categories;
  currentCategory = null;
  subcategories = [];
  step = 1;
  type = 'product';
  userAddress: any;
  isHour24 = false;
  step2Form: UntypedFormGroup;
  step4Form: UntypedFormGroup;
  step3FormService: UntypedFormGroup;
  step3FormProduct: UntypedFormGroup;
  isShowPhone = true;
  isLoading: boolean = false;
  locale: string = 'fr';
  uploadedImages: CustomFile[] = [];
  uploadIconColor = '#f9ba23';

  daysList = [
    { id: 'all', nameFr: 'Tous les jours', nameEn: 'Everyday' },
    { id: 'monday', nameFr: 'Lundi', nameEn: 'Monday' },
    { id: 'tuesday', nameFr: 'Mardi', nameEn: 'Tuesday' },
    { id: 'wednesday', nameFr: 'Mercredi', nameEn: 'Wednesday' },
    { id: 'thursday', nameFr: 'Jeudi', nameEn: 'Thursday' },
    { id: 'friday', nameFr: 'Vendredi', nameEn: 'Friday' },
    { id: 'saturday', nameFr: 'Samedi', nameEn: 'Saturday' },
    { id: 'sunday', nameFr: 'Dimanche', nameEn: 'Sunday' },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private adService: AdService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private storage: LocalStorageService,
    private imageCompress: NgxImageCompressService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.categories = this.defaultCategories.filter(
      (category) => category.type == this.type || category.type == 'mixte'
    );
    this.initStep2Form();
    this.initStep4Form();
    this.initStep3FormProduct();
    this.initStep3FormService();
    this.locale = this.storage.retrieve('lang');
    this.storage.observe('lang').subscribe({
      next: (lang) => (this.locale = lang),
    });
  }

  initStep2Form(): void {
    this.step2Form = this.fb.group({
      name: ['', [Validators.required]],
      category: [null, [Validators.required]],
      subCategory: [null, [Validators.required]],
    });
  }

  initStep3FormProduct(): void {
    this.step3FormProduct = this.fb.group({
      price: ['', [Validators.required]],
      brand: ['', [Validators.required]],
      location: ['', [Validators.required]],
    });
  }
  initStep3FormService(): void {
    this.step3FormService = this.fb.group({
      days: ['all'],
      startTime: [],
      endTime: [],
      locationService: ['', [Validators.required]],
      priceService: ['', [Validators.required]],
    });
  }
  initStep4Form(): void {
    this.step4Form = this.fb.group({
      description: ['', [Validators.required]],
    });
  }

  categoryChanges(event: any) {
    this.currentCategory = event.target.value;
    this.subcategories = categories.find(
      (category) => category.id === this.currentCategory
    )?.subcategory;
    this.subcategories = this.subcategories.filter((subcategory) => {
      if (subcategory.type == this.type) {
        return subcategory;
      }
    });
  }
  changeType(event: any) {
    this.categories = this.defaultCategories.filter(
      (category) =>
        category.type == event.target.value || category.type == 'mixte'
    );
  }

  handleNext() {
    this.subcategories = this.step == 1 ? [] : this.subcategories;
    if (this.step > 1) {
      switch (this.step) {
        case 2: {
          if (this.step2Form.valid) {
            this.step++;
          } else {
            this.validateAllFormFields(this.step2Form);
          }
          break;
        }
        case 3: {
          if (this.step3FormProduct.valid || this.step3FormService.valid) {
            this.step++;
          } else {
            if (this.type == 'product') {
              this.validateAllFormFields(this.step3FormProduct);
            } else {
              this.validateAllFormFields(this.step3FormService);
            }
          }
          break;
        }
        case 4: {
          if (this.step4Form.valid) {
            this.step++;
          } else {
            this.validateAllFormFields(this.step4Form);
          }
          break;
        }
      }
    } else {
      this.step++;
    }
  }
  handlePrevious() {
    this.subcategories = this.step == 1 ? [] : this.subcategories;
    this.step--;
  }

  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address;
  }

  // Upload images

  uploadImage(event: any): void {
    if (this.uploadedImages.length >= 5) {
      Swal.fire(
        this.translateService.instant('add_an_ad.warning'),
        this.translateService.instant(
          'add_an_ad.you_reached_the_maximum_images'
        ),
        'warning'
      );
      return;
    }

    const files: FileList = event.target.files;
    if (files.length === 0) {
      return;
    }

    const file: File = files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const imageData = e.target.result;
      const uploadedImage: CustomFile = {
        url: imageData,
        number: this.uploadedImages.length + 1,
        ...file,
      };

      this.uploadedImages.push(uploadedImage); // Add the uploaded image to the array
    };

    reader.readAsDataURL(file); // Read the file as data URL
  }

  deleteImage(image: CustomFile): void {
    const index = this.uploadedImages.indexOf(image);
    if (index !== -1) {
      this.uploadedImages.splice(index, 1);
      this.updateImageNumbers();
    }
  }

  updateImageNumbers() {
    this.uploadedImages.forEach((image, index) => {
      image.number = index + 1;
    });
  }

  handleSubmit() {
    if (this.uploadedImages.length >= 2) {
      this.isLoading = true;
      this.resizeImages(this.uploadedImages)
        .then((files) => {
          if (this.type === 'product') {
            let product: IItems = {
              name: this.step2Form.controls.name.value,
              category: this.step2Form.controls.category.value,
              subCategory: this.step2Form.controls.subCategory.value,
              price: this.step3FormProduct.controls.price.value,
              brand: this.step3FormProduct.controls.brand.value,
              location: this.step3FormProduct.controls.location.value,
              description: this.step4Form.controls.description.value,
              isShowNumber: this.isShowPhone,
              user_id: this.storage.retrieve('user').id,
            };
            this.adService.createProduct(product).subscribe({
              next: (data: IItems) => {
                const formData = new FormData();
                for (var i = 0; i < files.length; i++) {
                  formData.append('images[]', files[i]);
                }
                formData.append('product_id', data.id);
                this.adService.createImageProduct(formData).subscribe({
                  next: () => {
                    this.router.navigateByUrl('account/ads');
                    this.toastr.success(
                      this.translateService.instant(
                        'add_an_ad.your_ad_has_been_successfully_added'
                      )
                    );
                    this.isLoading = false;
                    this.resetProductForm();
                  },
                  error: () => {
                    this.toastr.error(
                      this.translateService.instant(
                        'add_an_ad.something_bad_happened'
                      )
                    );
                    this.isLoading = false;
                  },
                });
              },
            });
          }
          if (this.type === 'service') {
            this.isLoading = true;
            let service: IItems = {
              name: this.step2Form.controls.name.value,
              category: this.step2Form.controls.category.value,
              subCategory: this.step2Form.controls.subCategory.value,
              days: this.step3FormService.controls.days.value.join(),
              location: this.step3FormService.controls.locationService.value,
              price: this.step3FormService.controls.priceService.value,
              description: this.step4Form.controls.description.value,
              isShowNumber: this.isShowPhone,
              user_id: this.storage.retrieve('user').id,
            };
            if (this.isHour24) {
              service.hours = '24h/24';
            } else {
              service.hours =
                this.step3FormService.controls.startTime.value +
                '-' +
                this.step3FormService.controls.endTime.value;
            }
            this.adService.createService(service).subscribe({
              next: (data: IItems) => {
                const formData = new FormData();
                for (var i = 0; i < files.length; i++) {
                  formData.append('images[]', files[i]);
                }
                formData.append('service_id', data.id);
                this.adService.createImageService(formData).subscribe({
                  next: () => {
                    this.router.navigateByUrl('account/ads');
                    this.toastr.success(
                      this.translateService.instant(
                        'add_an_ad.your_ad_has_been_successfully_added'
                      )
                    );
                    this.isLoading = false;
                    this.resetServiceForm();
                  },
                  error: () => {
                    this.toastr.error(
                      this.translateService.instant(
                        'add_an_ad.something_bad_happened'
                      )
                    );
                    this.isLoading = false;
                  },
                });
              },
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.toastr.error(
            this.translateService.instant('add_an_ad.something_bad_happened')
          );
          this.isLoading = false;
        });
    } else {
      Swal.fire(
        this.translateService.instant('add_an_ad.warning'),
        this.translateService.instant(
          'add_an_ad.you_must_add_at_least_two_images'
        ),
        'warning'
      );
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  async resizeImages(
    files: { url: string; number: number }[]
  ): Promise<File[]> {
    const resizedFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      try {
        const file = await this.resizeImageFromUrl(files[i].url);
        resizedFiles.push(file);
      } catch (error) {
        console.error('Error while compressing the image:', error);
      }
    }

    return resizedFiles;
  }

  private resizeImageFromUrl(url: string): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      // Convert the URL into a byte array
      const byteCharacters = atob(url.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      // Create a File from the Blob
      const fileName = 'image.jpg'; // You can set the desired file name here
      const file = new File([blob], fileName, { type: 'image/jpeg' });

      // Read the file as a data URL
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Data = event.target.result as string;

        // Resize the image using your image compression library (imageCompress)
        this.imageCompress
          .compressFile(base64Data, 1, 100, 100, 1200, 1200) // Pass base64 data here
          .then((result) => {
            const compressedFile = base64toFile(result, fileName, 'image/jpeg');
            resolve(compressedFile);
          })
          .catch((error) => {
            reject(error);
          });
      };

      // Read the blob as a data URL
      reader.readAsDataURL(blob);
    });
  }

  resetProductForm() {
    this.step = 1;
    this.step2Form.reset();
    this.step3FormProduct.reset();
    this.step4Form.reset();
    this.isShowPhone = true;
  }
  resetServiceForm() {
    this.step = 1;
    this.step2Form.reset();
    this.step3FormService.reset();
    this.step4Form.reset();
    this.isShowPhone = true;
  }

  translateCategory(id: string, locale: string) {
    const category = this.categories.find((category) => category.id === id);
    return locale === 'fr' ? category.nameFr : category.nameEn;
  }

  translateSubCategory(id: string, locale: string) {
    let subcategory;
    for (const category of categories) {
      subcategory = category.subcategory.find((sub) => sub.id === id);
      if (subcategory) {
        return locale === 'fr' ? subcategory.nameFr : subcategory.nameEn;
      }
    }
    return undefined;
  }
}

function base64toFile(base64, filename, mimeType) {
  const byteString = atob(base64.split(',')[1]);

  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new File([ab], filename, { type: mimeType });
}
