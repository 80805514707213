<div class="soon">
  <div class="container">
    <div class="row">
      <div class="col-md-9 col-lg-8">
        <div class="soon-content text-center">
          <div class="soon-content-wrapper">
            <img
              src="assets/images/home/logo.png"
              alt="Logo"
              class="soon-logo mx-auto"
            />
            <h1 class="soon-title">Coming Soon</h1>
            <buy1way-count-down
              until="2023-06-08"
              format="DHMS"
              wrap="coming-countdown countdown-separator"
            >
            </buy1way-count-down>

            <hr class="mt-2 mb-3 mt-md-3" />
            <p>
              We are currently working on an awesome new site. Stay tuned for
              more information. Subscribe to our newsletter to stay updated on
              our progress.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="soon-bg bg-image"
    style="background-image: url(assets/images/backgrounds/error-bg.jpg)"
  ></div>
</div>
