import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AuthState } from './state/states/auth.state';
import { NgxsModule } from '@ngxs/store';
import { SearchState } from './state/states/search.state';
import { WishlistState } from './state/states/wishlist.state';
import { CurrencyFormatterPipe } from './pipes/currency-formatter.pipe';

@NgModule({
  declarations: [CurrencyFormatterPipe],
  imports: [
    CommonModule,

    NgxsModule.forRoot([AuthState, SearchState, WishlistState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot(),
  ],
  exports: [CurrencyFormatterPipe],
})
export class CoreModule {}
