import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { LocalStorageService } from 'ngx-webstorage';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ISearchStateModel } from '../../../../../core/models/search.model';
import { categories } from '../../../../../pages/home/index/categories';
import { UpdateSearch } from '../../../../../core/state/actions/search.action';
import { DataService } from '../../../../../core/services/data.service';

@Component({
  selector: 'buy1way-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnInit {
  products = [];
  suggestions = [];
  categories = categories;
  timer: any;
  locale: string = 'fr';
  locationsList: string[];
  type: string = 'product';
  categoty: string = '';
  subcategory: string = '';
  city: string = '';
  keyword: string = '';

  constructor(
    private router: Router,
    private dataService: DataService,
    private storage: LocalStorageService,
    private activeRoute: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.locale = this.storage.retrieve('lang');
    this.storage.observe('lang').subscribe({
      next: (lang) => (this.locale = lang),
    });

    this.dataService.getLocations().subscribe({
      next: (locationList: any) => {
        this.locationsList = locationList.locations;
      },
    });

    this.activeRoute.queryParamMap.subscribe((params) => {
      this.keyword = params.get('keyword') || '';
      this.city = params.get('city') || '';
      this.type = params.get('type') || 'product';
      this.categoty = params.get('categoty') || '';
    });
  }

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2
          ? []
          : this.locationsList
              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .slice(0, 10)
      )
    );

  onSearch() {
    const search: ISearchStateModel = {
      type: this.type,
      category: this.categoty,
      keyword: this.keyword,
      subcategory: '',
      city: this.city,
      page: 1,
      limit: 10,
    };
    this.router.navigate(['/ads/list'], {
      queryParams: search,
    });
  }

  onCategoryChange(event: any) {
    this.categoty = event.currentTarget.value;
  }

  onTypeChange() {
    const search: ISearchStateModel = { type: this.type };
    this.store.dispatch(new UpdateSearch(search));
  }

  translateCategory(id: string, locale: string) {
    const category = this.categories.find((category) => category.id === id);
    return locale === 'fr' ? category.nameFr : category.nameEn;
  }
}
