import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadImageModule } from 'ng-lazyload-image';

// Header Element
import { MySearchMenuComponent } from './components/headers/shared/message-menu/messages-menu.component';
import { WishlistMenuComponent } from './components/headers/shared/wishlist-menu/wishlist-menu.component';
import { MainMenuComponent } from './components/headers/shared/main-menu/main-menu.component';
import { HeaderSearchComponent } from './components/headers/shared/header-search/header-search.component';
import { MobileButtonComponent } from './components/headers/shared/mobile-button/mobile-button.component';
import { MobileMenuComponent } from './components/headers/shared/mobile-menu/mobile-menu.component';

// Header Component
import { HeaderComponent } from './components/headers/header/header.component';

// Footer Component
import { FooterComponent } from './components/footer/footer.component';
// // Page Element
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PaginationComponent } from './components/pagination/pagination.component';

// Product Element
import { QuantityInputComponent } from './components/quantity-input/quantity-input.component';
import { CountDownComponent } from './components/count-down/count-down.component';

// // single use component
import { NewsletterModalComponent } from './components/modals/newsletter-modal/newsletter-modal.component';
import { LoginModalComponent } from './components/modals/login-modal/login-modal.component';
import { RegisterModalComponent } from './components/modals/register-modal/register-modal.component';
import { IsotopeGridComponent } from './components/isotope-grid/isotope-grid.component';
import { ImageComponent } from './components/image/image.component';
import { MessageModalComponent } from './components/modals/message-modal/message-modal.component';

// // Custom Directives
import { BgParallaxDirective } from './directives/bg-parallax.directive';
import { TabClickDirective } from './directives/custom-tab-click.directive';
import { ProductHoverDirective } from './directives/product-hover.directive';
import { ContentAnimDirective } from './directives/content-anim.directive';

// Pipes
import { SafeContentPipe } from './pipes/safe-content.pipe';

import { AvatarModule } from 'ngx-avatars';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component';

@NgModule({
  declarations: [
    // header
    MySearchMenuComponent,
    WishlistMenuComponent,
    MainMenuComponent,
    HeaderSearchComponent,
    MobileButtonComponent,
    MobileMenuComponent,
    HeaderComponent,
    FooterComponent,
    // single-use components
    BreadcrumbComponent,
    PageHeaderComponent,
    NewsletterModalComponent,
    LoginModalComponent,
    RegisterModalComponent,
    QuantityInputComponent,
    CountDownComponent,
    PaginationComponent,
    IsotopeGridComponent,
    ImageComponent,
    MessageModalComponent,
    // directives
    BgParallaxDirective,
    TabClickDirective,
    ProductHoverDirective,
    ContentAnimDirective,
    // pipes
    SafeContentPipe,
    MobileHeaderComponent,
    DesktopHeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    LazyLoadImageModule,
    AvatarModule,
    GooglePlaceModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgbTypeaheadModule,
  ],
  exports: [
    // header
    HeaderComponent,
    HeaderSearchComponent,
    // mobile-menus
    MobileMenuComponent,
    // footer
    FooterComponent,
    // // single-use components
    BreadcrumbComponent,
    PageHeaderComponent,
    CountDownComponent,
    PaginationComponent,
    QuantityInputComponent,
    IsotopeGridComponent,
    ImageComponent,
    // directives
    BgParallaxDirective,
    TabClickDirective,
    ProductHoverDirective,
    ContentAnimDirective,
    // pipes
    SafeContentPipe,
  ],
})
export class SharedModule {}
