import { Component, OnInit } from '@angular/core';
import { brands, customSay, team } from './about-one-data';
import { sliderOpt } from '../../shared/data';

@Component({
  selector: 'buy1way-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  customSay = customSay;
  brands = brands;
  team = team;
  sliderOption = {
    ...sliderOpt,
    nav: false,
    dots: true,
    margin: 20,
    loop: false,
    responsive: {
      1200: {
        nav: true,
      },
    },
  };
  teamOption = {
    ...sliderOpt,
    nav: false,
    dots: false,
    items: 3,
    margin: 20,
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}
}
