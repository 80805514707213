<div class="modal-body">
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true"><i class="icon-close"></i></span>
  </button>
  <div class="form-box">
    <div class="form-tab nav-fill">
      <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
        <li ngbNavItem>
          <a ngbNavLink>{{ "register.title" | translate }}</a>
          <ng-template ngbNavContent>
            <div
              class="row mb-1 d-flex justify-content-center align-items-center"
            >
              <div class="col-sm-6 d-flex justify-content-center mb-2">
                <button
                  id="googleButton"
                  style="font: normal 300 1.4rem/1.86 Poppins, sans-serif"
                ></button>
              </div>
              <div class="col-sm-6 d-flex justify-content-center mb-2">
                <button (click)="signInWithFB()" class="btn btn-login btn-f">
                  <i class="icon-facebook-f"></i>
                  {{ "register.with_facebook" | translate }}
                </button>
              </div>
            </div>

            <div class="form-choice">
              <p class="text-center mb-2">
                <strong> {{ "register.or_register_with" | translate }}</strong>
              </p>

              <form [formGroup]="registrationForm">
                <div class="form-group">
                  <label for="register-name"
                    >{{ "register.user_name" | translate }}
                    <abbr
                      class="text-danger"
                      title="{{ 'register.this_is_required' | translate }}"
                      >*</abbr
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="register-name"
                    formControlName="userName"
                    [ngClass]="{
                      'is-invalid':
                        f.userName.errors &&
                        (f.userName.touched || f.userName.dirty),
                      'is-valid ': f.userName.valid
                    }"
                  />
                  <span class="text-danger">
                    <small
                      *ngIf="f.userName.touched && f.userName.errors?.required"
                    >
                      {{ "register.this_is_required" | translate }}
                    </small>
                  </span>
                </div>
                <div class="form-group">
                  <label for="register-email">
                    {{ "register.your_email_address" | translate }}
                    <abbr
                      class="text-danger"
                      title="{{ 'register.this_is_required' | translate }}"
                      >*</abbr
                    ></label
                  >
                  <input
                    type="email"
                    class="form-control"
                    formControlName="email"
                    [ngClass]="{
                      'is-invalid':
                        f.email.errors && (f.email.touched || f.email.dirty),
                      'is-valid ': f.email.valid
                    }"
                  />
                  <span class="text-danger">
                    <small *ngIf="f.email.touched && f.email.errors?.required">
                      {{ "register.this_is_required" | translate }}
                    </small>
                  </span>
                  <span class="text-danger">
                    <small *ngIf="f.email.errors?.hasEmail">
                      {{ "register.invalid_email" | translate }}
                    </small>
                  </span>
                </div>

                <div class="form-group">
                  <label for="register-password">
                    {{ "register.password" | translate }}
                    <abbr
                      class="text-danger"
                      title="{{ 'register.this_is_required' | translate }}"
                      >*</abbr
                    ></label
                  >
                  <input
                    [type]="fieldTextType ? 'text' : 'password'"
                    class="form-control"
                    id="register-password"
                    formControlName="password"
                    [ngClass]="{
                      'is-invalid':
                        f.password.errors &&
                        (f.password.touched || f.password.dirty),
                      'is-valid ': f.password.valid
                    }"
                  />
                </div>
                <div class="mb-1">
                  <div
                    class="row"
                    *ngIf="f.password.errors && f.password.dirty"
                  >
                    <div class="col-6">
                      <label
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.minlength,
                          'text-success': !f.password.errors?.minLength
                        }"
                        for="length"
                      >
                        <span
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.minlength,
                            'bi bi-check-circle': !f.password.errors?.minlength
                          }"
                        >
                        </span>
                        <small>{{
                          "register.at_least_8_charaxters" | translate
                        }}</small>
                      </label>
                      <label
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.hasSmallCase,
                          'text-success': !f.password.errors?.hasSmallCase
                        }"
                        for="lowcase"
                      >
                        <span
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.hasSmallCase,
                            'bi bi-check-circle':
                              !f.password.errors?.hasSmallCase
                          }"
                        >
                        </span>
                        <small>{{
                          "register.1_lower_case_letter" | translate
                        }}</small>
                      </label>
                      <label
                        style="margin-left: 10px"
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.hasNumber,
                          'text-success': !f.password.errors?.hasNumber
                        }"
                        for="number"
                      >
                        <span
                          class="ms-3"
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.hasNumber,
                            'bi bi-check-circle': !f.password.errors?.hasNumber
                          }"
                        >
                        </span>
                        <small>{{ "register.1_number" | translate }}</small>
                      </label>
                    </div>
                    <div class="col-6">
                      <label
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.hasCapitalCase,
                          'text-success': !f.password.errors?.hasCapitalCase
                        }"
                        for="capital"
                      >
                        <span
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.hasCapitalCase,
                            'bi bi-check-circle':
                              !f.password.errors?.hasCapitalCase
                          }"
                        >
                        </span>
                        <small>{{
                          "register.1_upper_case_letter" | translate
                        }}</small>
                      </label>
                      <label
                        [ngClass]="{
                          'text-danger':
                            f.password.errors?.required ||
                            f.password.errors?.hasSpecialCharacters,
                          'text-success':
                            !f.password.errors?.hasSpecialCharacters
                        }"
                        for="special"
                      >
                        <span
                          [ngClass]="{
                            'bi bi-x-circle':
                              f.password.errors?.required ||
                              f.password.errors?.hasSpecialCharacters,
                            'bi bi-check-circle':
                              !f.password.errors?.hasSpecialCharacters
                          }"
                        >
                        </span>
                        <small>{{
                          "register.1_special_character" | translate
                        }}</small>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="register-password-confirm"
                    >{{ "register.confirm_password" | translate }}
                    <abbr
                      class="text-danger"
                      title="{{ 'register.this_is_required' | translate }}"
                      >*</abbr
                    ></label
                  >
                  <div class="input-group">
                    <input
                      [type]="fieldTextType ? 'text' : 'password'"
                      class="form-control"
                      id="register-password-confirm"
                      formControlName="confirmPassword"
                      [ngClass]="{
                        'is-invalid':
                          f.confirmPassword.errors &&
                          (f.confirmPassword.touched ||
                            f.confirmPassword.dirty),
                        'is-valid ': f.confirmPassword.valid
                      }"
                    />
                    <span class="input-group-text">
                      <i
                        class="bi"
                        [ngClass]="{
                          'bi-eye': !fieldTextType,
                          'bi-eye-slash': fieldTextType
                        }"
                        (click)="toggleFieldTextType()"
                        style="font-size: 20px; cursor: pointer"
                      ></i>
                    </span>
                  </div>
                  <span class="text-danger">
                    <small *ngIf="f.confirmPassword.errors?.mustMatch">
                      {{ "register.password_not_match" | translate }}
                    </small>
                  </span>
                </div>

                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="register-policy"
                    formControlName="policy"
                    [ngClass]="{
                      'is-invalid': f.policy.errors && f.policy.touched
                    }"
                  />
                  <label
                    routerLink="/terms-and-conditions"
                    class="form-check-label"
                  >
                    <a href="javascript:;">{{
                      "register.privacy_policy" | translate
                    }}</a>
                  </label>
                </div>
                <button
                  type="submit"
                  (click)="create()"
                  class="btn btn-outline-primary-2 mt-3"
                  [disabled]="isLoading"
                >
                  <span
                    *ngIf="isLoading"
                    class="spinner-border text-warning"
                    style="margin-right: 5px"
                    role="status"
                  >
                    <span class="sr-only"></span>
                  </span>
                  <span>{{ "register.create" | translate }}</span>
                  <i class="icon-long-arrow-right"></i>
                </button>
              </form>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
