import { State, Selector, Action, StateContext } from '@ngxs/store';
import { ISearchStateModel } from '../../models/search.model';
import { UpdateSearch } from '../actions/search.action';
import { Injectable } from '@angular/core';

@State<ISearchStateModel>({
  name: 'search',
  defaults: {
    type: '',
    category: '',
    subcategory: '',
    city: '',
    keyword: '',
    page: 1,
    limit: 10,
  },
})
@Injectable()
export class SearchState {
  @Selector()
  static getSearchCriterias(state: ISearchStateModel) {
    return state;
  }

  @Selector()
  static getSearchType(state: ISearchStateModel) {
    return state.type;
  }

  @Action(UpdateSearch)
  updateSearch(
    { patchState }: StateContext<ISearchStateModel>,
    { payload }: UpdateSearch
  ) {
    patchState(payload);
  }
}
