import { State, Action, StateContext, Selector } from '@ngxs/store';
import { IAuthStateModel, IUser } from '../../models/auth.state.model';
import { Login, Logout, UpdateMe } from '../actions/auth.action';
import { Injectable } from '@angular/core';

@State<IAuthStateModel>({
  name: 'auth',
  defaults: {
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
    me: null,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static isAuthenticated(state: IAuthStateModel) {
    return state.isAuthenticated;
  }

  @Selector()
  static currentUser(state: IAuthStateModel) {
    return state.me;
  }

  @Action(Login)
  login(ctx: StateContext<IAuthStateModel>, action: Login) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      isAuthenticated: true,
      accessToken: action.payload.accessToken,
      refreshToken: action.payload.refreshToken,
    });
  }

  @Action(UpdateMe)
  updateMe(ctx: StateContext<IAuthStateModel>, action: UpdateMe) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      me: action.payload,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<IAuthStateModel>) {
    ctx.setState({
      isAuthenticated: false,
      accessToken: null,
      refreshToken: null,
      me: null,
    });
  }
}
