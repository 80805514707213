import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MessageService } from '../../core/services/message.service';
import { IsendContact } from '../../core/models/messages.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  isLoading: boolean = false;
  public isOpen = false;
  contactForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      phone: ['', [Validators.pattern(/^\+?\d{6,}$/)]],
      subject: ['', [Validators.required, Validators.minLength(3)]],
      message: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  // Add getters to easily access form controls
  get phone() {
    return this.contactForm.get('phone');
  }

  get subject() {
    return this.contactForm.get('subject');
  }

  get message() {
    return this.contactForm.get('message');
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.contactForm.valid) {
      this.isLoading = true;
      const formData: IsendContact = {
        phone: this.contactForm.get('phone').value,
        subject: this.contactForm.get('subject').value,
        message: this.contactForm.get('message').value,
      };
      this.messageService.sendContactForm(formData).subscribe({
        next: () => {
          this.contactForm.reset();
          this.isLoading = false;
          this.toastr.success(
            this.translateService.instant(
              'message_modal.your_message_has_been_sent'
            )
          );
        },
        error: () => {
          this.isLoading = false;
          this.toastr.error(
            this.translateService.instant(
              'message_modal.something_bad_happened'
            )
          );
        },
      });
    } else {
      this.validateAllFormFields(this.contactForm);
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  toggleBtn(): void {
    const float_btns = document.querySelector('.float_btns') as HTMLElement;
    const add = document.getElementById('add') as HTMLElement;
    const remove = document.getElementById('remove') as HTMLElement;
    const float_btn = document.querySelectorAll(
      '.float_btns a'
    ) as NodeListOf<HTMLElement>;

    float_btns.classList.toggle('open');
    this.isOpen = float_btns.classList.contains('open');

    if (this.isOpen) {
      remove.style.display = 'block';
      add.style.display = 'none';
      float_btn.forEach((e, i) => {
        setTimeout(() => {
          const bottom = 40 * i;
          e.style.bottom = bottom + 'px';
        }, 100 * i);
      });
    } else {
      add.style.display = 'block';
      remove.style.display = 'none';
      float_btn.forEach((e, i) => {
        e.style.bottom = '0px';
      });
    }
  }
}
