export const categories = [
  {
    id: 'beauty-wellness',
    nameFr: 'Beauté & Bien-être',
    nameEn: 'Beauty & Well-being',
    img: 'assets/images/home/categories/beauty.png',
    type: 'mixte',
    subcategory: [
      {
        id: 'hairstyling',
        nameFr: 'Coiffure',
        nameEn: 'Hairstyling',
        type: 'service',
      },
      {
        id: 'make-up',
        nameFr: 'Maquillage',
        nameEn: 'Make-up',
        type: 'service',
      },
      {
        id: 'pedicure-manicure',
        nameFr: 'Pédicure & Manucure',
        nameEn: 'Pedicure & Manucure',
        type: 'service',
      },
      {
        id: 'beauty-care',
        nameFr: 'Soins de beauté',
        nameEn: 'Beauty care',
        type: 'service',
      },
      {
        id: 'sport-fitness',
        nameFr: 'Sport & Fitness',
        nameEn: 'Sport & Fitness',
        type: 'service',
      },
      {
        id: 'products-accessories',
        nameFr: 'Produits & Accessoires',
        nameEn: 'Products & Accessories',
        type: 'product',
      },
    ],
  },
  {
    id: 'events',
    nameFr: 'Evènementiel',
    nameEn: 'Events',
    img: 'assets/images/home/categories/event.png',
    type: 'service',
    subcategory: [
      {
        id: 'djs-sound',
        nameFr: 'Djs & Sonorisation',
        nameEn: 'DJs & Sound system',
        type: 'service',
      },
      {
        id: 'presenter-announcer',
        nameFr: 'Imprésario & Présentateur',
        nameEn: 'Presenter & Announcer',
        type: 'service',
      },
      {
        id: 'animation',
        nameFr: 'Animation',
        nameEn: 'Animation',
        type: 'service',
      },
      {
        id: 'decoration',
        nameFr: 'Décoration',
        nameEn: 'Decoration',
        type: 'service',
      },
      {
        id: 'equipment-rental',
        nameFr: 'Location de matériel',
        nameEn: 'Equipment rental',
        type: 'service',
      },
      {
        id: 'catering-service',
        nameFr: 'Service Traiteur',
        nameEn: 'Catering service',
        type: 'service',
      },
      {
        id: 'catering-gastronomy',
        nameFr: 'Restauration & Gastronomie',
        nameEn: 'Catering & Gastronomy',
        type: 'service',
      },
      {
        id: 'photography-audiovisual',
        nameFr: 'Photographie & Audiovisuel',
        nameEn: 'Photography & Audiovisual',
        type: 'service',
      },
    ],
  },
  {
    id: 'property',
    nameFr: 'Immobilier',
    nameEn: 'Property',
    img: 'assets/images/home/categories/house.webp',
    type: 'service',
    subcategory: [
      {
        id: 'land-plots',
        nameFr: 'Terrains & Parcelles',
        nameEn: 'Land & Plots',
        type: 'service',
      },
      {
        id: 'rooms-studios',
        nameFr: 'Chambres & Studios',
        nameEn: 'Rooms & Studios',
        type: 'service',
      },
      {
        id: 'houses-apartments',
        nameFr: 'Maisons & Appartements',
        nameEn: 'Houses & Apartments',
        type: 'service',
      },
      {
        id: 'furnished-flats',
        nameFr: 'Appartements meublés',
        nameEn: 'Furnished flats',
        type: 'service',
      },
      {
        id: 'hostels-motels-hotels',
        nameFr: 'Auberges, Motels & Hôtels',
        nameEn: 'Hostels, Motels & Hotels',
        type: 'service',
      },
      {
        id: 'commercial-area',
        nameFr: 'Surface commerciale',
        nameEn: 'Commercial area',
        type: 'service',
      },
      {
        id: 'industrial-buildings',
        nameFr: 'Locaux industriels',
        nameEn: 'Industrial buildings',
        type: 'service',
      },
      {
        id: 'real-estate-investments',
        nameFr: 'Investissements Immobiliers',
        nameEn: 'Real Estate Investments',
        type: 'service',
      },
    ],
  },
  {
    id: 'vehicles',
    nameFr: 'Véhicules',
    nameEn: 'Vehicles',
    img: 'assets/images/home/categories/vehicle.png',
    type: 'mixte',
    subcategory: [
      {
        id: 'bicycle',
        nameFr: 'Vélo',
        nameEn: 'Bicycle',
        type: 'product',
      },
      {
        id: 'bikes-tricycles',
        nameFr: 'Motos & Tricycles',
        nameEn: 'Bikes & Tricycles',
        type: 'product',
      },
      {
        id: 'houses-apartments',
        nameFr: 'Maisons & Appartements',
        nameEn: 'Houses & Apartments',
        type: 'product',
      },
      {
        id: 'cars',
        nameFr: 'Voitures',
        nameEn: 'Cars',
        type: 'product',
      },
      {
        id: 'trucks-buses',
        nameFr: 'Camions & Bus',
        nameEn: 'Trucks & Buses',
        type: 'product',
      },
      {
        id: 'car-rental',
        nameFr: 'Location de véhicules',
        nameEn: 'Car rental',
        type: 'service',
      },
      {
        id: 'professional-vehicles',
        nameFr: 'Véhicules professionnels',
        nameEn: 'Professional vehicles',
        type: 'product',
      },
      {
        id: 'spare-parts-accessories',
        nameFr: 'Pièces détachées & Accessoires',
        nameEn: 'Spare parts & accessories',
        type: 'product',
      },
      {
        id: 'workshop',
        nameFr: 'Garage',
        nameEn: 'Workshop',
        type: 'service',
      },
    ],
  },
  {
    id: 'fashion-clothing',
    nameFr: 'Mode & Vêtements',
    nameEn: 'Fashion & Clothing',
    img: 'assets/images/home/categories/mode.png',
    type: 'mixte',
    subcategory: [
      {
        id: 'jewellery',
        nameFr: 'Bijoux',
        nameEn: 'Jewellery',
        type: 'product',
      },
      {
        id: 'watches',
        nameFr: 'Montres',
        nameEn: 'Watches',
        type: 'product',
      },
      {
        id: 'fragrances',
        nameFr: 'Parfums',
        nameEn: 'Fragrances',
        type: 'product',
      },
      {
        id: 'shoes',
        nameFr: 'Chaussures',
        nameEn: 'Shoes',
        type: 'product',
      },
      {
        id: 'photos-cameras',
        nameFr: 'Photos & Caméras',
        nameEn: 'Photos & Cameras',
        type: 'product',
      },
      {
        id: 'bags-luggage',
        nameFr: 'Sacs & bagagerie',
        nameEn: 'Bags & Luggage',
        type: 'product',
      },
      {
        id: 'women-clothing',
        nameFr: 'Vêtements féminins',
        nameEn: "Women's clothing",
        type: 'product',
      },
      {
        id: 'men-clothing',
        nameFr: 'Vêtements masculins',
        nameEn: "Men's clothing",
        type: 'product',
      },
      {
        id: 'children-clothing',
        nameFr: "Vêtements d'enfants",
        nameEn: "Children's clothing",
        type: 'product',
      },

      {
        id: 'afritude-and-fabrics',
        nameFr: 'Afritude et tissus',
        nameEn: 'Afritude and fabrics',
        type: 'product',
      },

      {
        id: 'other',
        nameFr: 'Autres',
        nameEn: 'Other',
        type: 'product',
      },
    ],
  },
  {
    id: 'miscellaneous-product',
    nameFr: 'Produits divers',
    nameEn: 'Miscellaneous products',
    img: 'assets/images/home/categories/product.webp',
    type: 'product',
    subcategory: [
      {
        id: 'wines-drinks',
        nameFr: 'Boissons & Vins',
        nameEn: 'Wines & Drinks',
        type: 'product',
      },
      {
        id: 'gastronomy',
        nameFr: 'Gastronomie',
        nameEn: 'Gastronomy',
        type: 'product',
      },
      {
        id: 'patisserie',
        nameFr: 'Pâtisserie',
        nameEn: 'Patisserie',
        type: 'product',
      },
      {
        id: 'agri-food',
        nameFr: 'Agroalimentaires',
        nameEn: 'Agri-food',
        type: 'product',
      },
      {
        id: 'ironmongery',
        nameFr: 'Quincaillerie',
        nameEn: 'Ironmongery',
        type: 'product',
      },
      {
        id: 'office-supplies',
        nameFr: 'Fournitures de bureaux',
        nameEn: 'Office supplies',
        type: 'product',
      },
      {
        id: 'school-supplies',
        nameFr: 'Fournitures scolaires',
        nameEn: 'School supplies',
        type: 'product',
      },
      {
        id: 'building-materials',
        nameFr: 'Matériaux de construction',
        nameEn: 'Building materials',
        type: 'product',
      },
      {
        id: 'laboratory-equipment',
        nameFr: 'Matériels de laboratoires',
        nameEn: 'Laboratory equipment',
        type: 'product',
      },
      {
        id: 'professional-equipment',
        nameFr: 'Equipements professionnels',
        nameEn: 'Professional equipment',
        type: 'product',
      },
      {
        id: 'other',
        nameFr: 'Autres',
        nameEn: 'Other',
        type: 'product',
      },
    ],
  },
  {
    id: 'multimedia',
    nameFr: 'Multimédia',
    nameEn: 'Multimedia',
    img: 'assets/images/home/categories/multimedia.webp',
    type: 'mixte',
    subcategory: [
      {
        id: 'sound-speakers',
        nameFr: 'Son & enceintes',
        nameEn: 'Sound & speakers',
        type: 'product',
      },
      {
        id: 'phones-tablets',
        nameFr: 'Téléphones & tablettes',
        nameEn: 'Phones & tablets',
        type: 'product',
      },
      {
        id: 'connected-objects',
        nameFr: 'Objets connectés',
        nameEn: 'Connected objects',
        type: 'product',
      },
      {
        id: 'video-games-consoles',
        nameFr: 'Jeux vidéo & Consoles',
        nameEn: 'Video Games & Consoles',
        type: 'product',
      },
      {
        id: 'tv-video',
        nameFr: 'TV & Vidéo',
        nameEn: 'TV & Video',
        type: 'product',
      },
      {
        id: 'photos-cameras',
        nameFr: 'Photos & Caméras',
        nameEn: 'Photos & Cameras',
        type: 'product',
      },
      {
        id: 'computers',
        nameFr: 'Ordinateurs',
        nameEn: 'Computers',
        type: 'product',
      },
      {
        id: 'phone-accessories',
        nameFr: 'Accessoires pour téléphones',
        nameEn: 'Phone accessories',
        type: 'product',
      },
      {
        id: 'computer-accessories',
        nameFr: 'Accessoires informatiques',
        nameEn: 'Computer accessories',
        type: 'product',
      },
      {
        id: 'professional-equipment',
        nameFr: 'Equipements professionnels',
        nameEn: 'Professional equipment',
        type: 'product',
      },
      {
        id: 'repair-maintenance',
        nameFr: 'Réparation & Maintenance',
        nameEn: 'Repair & Maintenance',
        type: 'service',
      },
      {
        id: 'other',
        nameFr: 'Autres',
        nameEn: 'Other',
        type: 'product',
      },
    ],
  },
  {
    id: 'studies',
    nameFr: 'Formations et emplois',
    nameEn: 'Education, Training and Jobs',
    img: 'assets/images/home/categories/study.webp',
    type: 'service',
    subcategory: [
      {
        id: 'support-courses',
        nameFr: 'Cours de soutien',
        nameEn: 'Support courses',
        type: 'service',
      },
      {
        id: 'job-offers',
        nameFr: 'Offres d’emploi',
        nameEn: 'Job offers',
        type: 'service',
      },
      {
        id: 'study-work-abroad',
        nameFr: 'Etude & travaille à l’étranger',
        nameEn: 'Study & work abroad',
        type: 'service',
      },
      {
        id: 'study-training',
        nameFr: 'Etudes & Formations',
        nameEn: 'Studies & Training',
        type: 'service',
      },
      {
        id: 'professional-training',
        nameFr: 'Formations Professionnelles',
        nameEn: 'Professional Training',
        type: 'service',
      },
      {
        id: 'job-search',
        nameFr: 'Recherche d’emploi',
        nameEn: 'Job search',
        type: 'service',
      },
    ],
  },

  {
    id: 'agriculture-livestock',
    nameFr: 'Agriculture & élevage',
    nameEn: 'Agriculture & livestock',
    img: 'assets/images/home/categories/agriculture-livestock.webp',
    type: 'mixte',
    subcategory: [
      {
        id: 'poultry-cattle',
        nameFr: 'Volaille & bétail',
        nameEn: 'Poultry & Cattle',
        type: 'product',
      },
      {
        id: 'pets',
        nameFr: 'Animaux de compagnie',
        nameEn: 'Pets',
        type: 'product',
      },
      {
        id: 'agricultural-equipment',
        nameFr: 'Matériels agricoles',
        nameEn: 'Agricultural equipment',
        type: 'product',
      },
      {
        id: 'agricultural-products',
        nameFr: 'Produits agricoles',
        nameEn: 'Agricultural products',
        type: 'product',
      },
      {
        id: 'livestock-equipment',
        nameFr: 'Matériels d’élevage',
        nameEn: 'Livestock equipment',
        type: 'product',
      },
      {
        id: 'Livestock products',
        nameFr: 'Produits d’élevage',
        nameEn: 'Livestock products',
        type: 'product',
      },
      {
        id: 'support-for-works',
        nameFr: 'Aide aux travaux',
        nameEn: 'Support for works',
        type: 'service',
      },
    ],
  },
  {
    id: 'dwelling',
    nameFr: 'Habitation',
    nameEn: 'Dwelling',
    img: 'assets/images/home/categories/dwelling.webp',
    type: 'product',
    subcategory: [
      {
        id: 'bedding',
        nameFr: 'Literie',
        nameEn: 'Bedding',
        type: 'product',
      },
      {
        id: 'furniture-decoration',
        nameFr: 'Ameublement & Décoration',
        nameEn: 'Furniture & Decoration',
        type: 'product',
      },
      {
        id: 'furniture-lighting',
        nameFr: 'Meubles & Luminaires',
        nameEn: 'Furniture & Lighting',
        type: 'product',
      },
      {
        id: 'tableware-crockery',
        nameFr: 'Arts de la table & Vaisselles',
        nameEn: 'Tableware & Crockery',
        type: 'product',
      },
      {
        id: 'domestic-appliances-kitchen',
        nameFr: 'Electroménager & cuisine',
        nameEn: 'Domestic appliances & kitchen',
        type: 'product',
      },
    ],
  },
  {
    id: 'leisure-holidays',
    nameFr: 'Loisirs et vacances',
    nameEn: 'Leisure and holidays',
    img: 'assets/images/home/categories/leisure-holidays.webp',
    type: 'product',
    subcategory: [
      {
        id: 'events',
        nameFr: 'Evènements',
        nameEn: 'Events',
        type: 'product',
      },
      {
        id: 'musical-instruments',
        nameFr: 'Instruments de musique',
        nameEn: 'Musical instruments',
        type: 'product',
      },
      {
        id: 'toys-board-games',
        nameFr: 'Jouets, jeux de société',
        nameEn: 'Toys, board games',
        type: 'product',
      },
      {
        id: 'magazines-books',
        nameFr: 'Revues & Livres',
        nameEn: 'Magazines & Books',
        type: 'product',
      },
      {
        id: 'travel-tourism',
        nameFr: 'Voyage & Tourisme',
        nameEn: 'Travel & Tourism',
        type: 'product',
      },
    ],
  },
  {
    id: 'arts',
    nameFr: 'Arts',
    nameEn: 'Arts',
    img: 'assets/images/home/categories/arts.webp',
    type: 'product',
    subcategory: [
      {
        id: 'tables',
        nameFr: 'Tableaux',
        nameEn: 'Tables',
        type: 'product',
      },
      {
        id: 'handicrafts',
        nameFr: 'Artisanat',
        nameEn: 'Handicrafts',
        type: 'product',
      },
      {
        id: 'paint',
        nameFr: 'Peinture',
        nameEn: 'Paint',
        type: 'service',
      },
      {
        id: 'sewings',
        nameFr: 'Couture',
        nameEn: 'Sewing',
        type: 'service',
      },
      {
        id: 'artistic-paintings',
        nameFr: 'Peintures artistiques',
        nameEn: 'Artistic paintings',
        type: 'service',
      },
      {
        id: 'digital-drawing',
        nameFr: 'Dessin numérique',
        nameEn: 'Digital drawing',
        type: 'product',
      },
    ],
  },
  {
    id: 'technical-services',
    nameFr: 'Services techniques',
    nameEn: 'Technical services',
    img: 'assets/images/home/categories/technical-services.webp',
    type: 'service',
    subcategory: [
      {
        id: 'woodwork',
        nameFr: 'Menuiserie',
        nameEn: 'Woodwork',
        type: 'service',
      },
      {
        id: 'plumbing',
        nameFr: 'Plomberie',
        nameEn: 'Plumbing',
        type: 'service',
      },
      {
        id: 'patisserie',
        nameFr: 'Pâtisserie',
        nameEn: 'Patisserie',
        type: 'service',
      },
      {
        id: 'masonry',
        nameFr: 'Maçonnerie',
        nameEn: 'Masonry',
        type: 'service',
      },
      {
        id: 'mechanics',
        nameFr: 'Mécanique',
        nameEn: 'Mechanics',
        type: 'service',
      },

      {
        id: 'domestic-worker',
        nameFr: 'Employé de maison',
        nameEn: 'Domestic worker',
        type: 'service',
      },
      {
        id: 'other',
        nameFr: 'Autres',
        nameEn: 'Other',
        type: 'service',
      },
    ],
  },
];
