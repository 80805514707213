import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';

import { WishlistService } from '../../../../core/services/wishlist.service';
import { IrecentItem } from '../../../../core/models/items.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'recent-item',
  templateUrl: './recent-item.component.html',
  styleUrls: ['./recent-item.component.scss'],
})
export class RecentItemComponent {
  @Input() item: IrecentItem;

  constructor(
    private wishlistService: WishlistService,
    private storage: LocalStorageService,
    private translateService: TranslateService
  ) {}

  addToWishlist(event: Event) {
    event.preventDefault();
    if (this.storage.retrieve('user').id) {
      this.wishlistService.addToWishList(this.item, this.item.type);
    } else {
      Swal.fire(
        'Warning!',
        this.translateService.instant('account.you_must_be_logged_in'),
        'warning'
      );
    }
  }

  isInWishlist() {
    return this.wishlistService.isInWishlist(this.item, this.item.type);
  }
}
