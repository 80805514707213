<!-- cgu.component.html -->
<div class="container mt-5">
  <h4 class="text-center">
    Conditions Générales de Vente et d'Utilisation (CGV/CGU) du site buy1way.com
  </h4>
  <div class="cgu">
    <div>
      <p align="center">Version applicable à compter du 17&nbsp;Août 2020</p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 1 : Objet</strong></p>

      <p>
        Les présentes « Conditions Générales de Vente et d’Utilisation
        (‘‘CGV/CGU’’) » ont pour objet l'encadrement juridique de l’utilisation
        du site buy1way.com et de ses services.
      </p>

      <p>Ce contrat est conclu entre :</p>

      <ol style="list-style-type: lower-roman">
        <li>
          Le gérant du site internet, ci-après désigné
          «&nbsp;l’Éditeur&nbsp;»&nbsp;;
        </li>
        <li value="2">
          Toute personne physique ou morale qui accède au site et à ses
          services, ci-après appelé «&nbsp;l’Utilisateur&nbsp;».
        </li>
      </ol>

      <p>
        La société Buy1way.com invite les Utilisateurs à lire attentivement les
        présentes Conditions Générales de Vente et d’Utilisation. L'accès au
        site, sa consultation et son utilisation sont subordonnés à
        l'acceptation sans réserve des présentes Conditions Générales de Vente
        et d’Utilisation.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 2 : Mentions légales</strong></p>

      <p>
        Le site buy1way.com est édité par la société Buy1way.com, SAS en cours
        de constitution.
      </p>

      <p>
        En attendant la finalisation du processus de constitution de la société,
        vous pouvez, pour toute question sur l’entreprise, nous envoyer un mail
        via l’adresse email ci-après&nbsp;:
      </p>

      <p><a href="mailto:contact@buyetyamo.com">contact@buy1way.com</a>.</p>

      <p>
        La société est représentée par son Directeur Général par ailleurs
        Directeur de Publication du site.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 3 : Définitions</strong></p>

      <p>
        Les définitions ci-après sont admises dans le cadre des présentes
        conditions générales.
      </p>

      <p>
        <strong>Acheteur&nbsp;</strong>: désigne tout Utilisateur majeure
        titulaire d’un Compte Personnel qui achète ou souhaite acheter un ou
        plusieurs Produits ou Services sur le Site à un Vendeur&nbsp;;
      </p>

      <p>
        <strong>Annonce&nbsp;</strong>: désigne l'ensemble des éléments et
        données visuelles, textuelles, sonores, photographiques (y compris les
        dessins), déposé par un Vendeur sous sa responsabilité éditoriale, en
        vue d'acheter ou non, de louer ou de vendre un Produit ou un Service, de
        proposer ou rechercher un Produit ou un Service sur le Site&nbsp;;
      </p>

      <p>
        <strong>Annonceur&nbsp;</strong>: désigne tout Utilisateur qui publie
        une Annonce sur le Site&nbsp;;
      </p>

      <p>
        <strong>Avis</strong>&nbsp;: désigne le commentaire que tout Utilisateur
        peut faire à l’égard d’un autre Utilisateur, après que ce dernier lui
        ait vendu un Produit ou rendu un Service.
      </p>

      <p>
        <strong>CGV/CGU</strong>&nbsp;: désignent les présentes Conditions
        Générales de Vente et d’Utilisation&nbsp;;
      </p>

      <p>
        <strong>Client</strong>&nbsp;: désigne tout Utilisateur, Professionnel
        ou non, ayant passé la commande d’un Produit ou effectué la réservation
        d’un Service&nbsp;;
      </p>

      <p>
        <strong>Colis</strong>&nbsp;: désigne le paquet précautionneusement
        emballé par le Vendeur ou tout Utilisateur accédant aux Services de
        livraison proposés par d’autres Utilisateurs sur le Site, contenant le
        ou les Produits achetés par l’Acheteur, ou tout autre Bien Personnel et
        légal qu’un Utilisateur souhaiterait expédier légalement à travers le
        Site&nbsp;;&nbsp;
      </p>

      <p>
        <strong>Commission</strong>&nbsp;: désigne le pourcentage équivalent à
        des frais de services que Buy1way.com peut prélever à ses Utilisateurs,
        en contrepartie de l’utilisation du Site. Cette Commission n’est
        prélevée par défaut que sur les Frais perçus par le Vendeur ou
        Prestataire, et s’élève à 3%&nbsp;; Buy1way.com se réserve le droit de
        modifier ce pourcentage, à la hausse ou à la baisse, sans obligation de
        préavis et de justification ;
      </p>

      <p>
        <strong>Compte Personnel</strong>&nbsp;: désigne l'espace gratuit qui
        doit être crée, accessible depuis le Site Internet&nbsp;<a
          href="https://www.buyetyamo.com/"
          >https://www.buy1way.com</a
        >&nbsp;à partir duquel tout Utilisateur peut diffuser, gérer, visualiser
        ses Annonces et Transactions, éditer ses informations personnelles, ses
        informations de payement et de livraison&nbsp;;
      </p>

      <p>
        <strong>Frais</strong>&nbsp;: désignent le montant total net,
        correspondant au prix de vente d’un Produit ou d’un Service, incluant
        éventuellement le prix d’expédition&nbsp;; Buy1way.com se Buy1way.com se
        réserve le droit d’arrondir (par excès ou par défaut, à un nombre entier
        naturel, ou un nombre décimal au centième) ces Frais ;
      </p>

      <p>
        <strong>Modération&nbsp;</strong>: désigne le contrôle par un
        administrateur du Site, de tout contenu (textes, images, etc.) ajouté
        sur le Site. Une Modération est ainsi effectuée lorsque l’Utilisateur
        souhaite publier une Annonce. Tout Utilisateur accepte que son Annonce
        ne sera visible sur le Site qu’après Modération et validation du contenu
        de cette Annonce, ce qui peut prendre quelques minutes ou quelques
        heures&nbsp;;
      </p>

      <p>
        <strong>Note</strong>&nbsp;: désigne toute évaluation d'un Produit ou
        d’un Service par un Acheteur, représentée quantitativement par une note
        allant de 1 à 5 étoiles affichée sur la page contenant les autres
        informations sur ce Produit ou Service. L’Acheteur peut laisser une Note
        sur un Produit ou un Service uniquement après l’achat de ce Produit ou
        Service sur le Site&nbsp;;
      </p>

      <p>
        <strong>Plateforme</strong>&nbsp;: a le même sens que le Site, défini
        ci-dessous, qui permet la mise en relation d’Utilisateurs&nbsp;;
      </p>

      <p>
        <strong>Prestataire</strong>&nbsp;: désigne tout Utilisateur qui propose
        des services en contrepartie&nbsp;d’un paiement&nbsp;;
      </p>

      <p>
        <strong>Prestation de Services</strong>&nbsp;: désigne le contrat par
        lequel un Utilisateur s’engage à fournir un Service à un autre
        Utilisateur (Client), en contrepartie d’un paiement ;
      </p>

      <p>
        <strong>Produit</strong>&nbsp;: désigne les choses matérielles légales
        pouvant faire l’objet d’une appropriation et qui sont proposées en vente
        sur la Plateforme par un Vendeur ;
      </p>

      <p>
        <strong>Professionnel</strong>&nbsp;: désigne tout Utilisateur qui agit
        dans le cadre de son activité professionnelle&nbsp;;
      </p>

      <p>
        <strong>Publication</strong>&nbsp;: a le même sens que l’Annonce,
        définie ci-dessus&nbsp;;
      </p>

      <p>
        <strong>Recommandation</strong>&nbsp;: désigne toute évaluation d'un
        Vendeur ou d'un Acheteur représentée quantitativement par une note
        allant de 1 à 5 étoiles affichée sur son profil. L’Acheteur ou le
        Vendeur peut laisser une recommandation uniquement après qu’une
        Transaction ait été réalisée entre eux sur le Site
      </p>

      <p>
        <strong>Réservation&nbsp;</strong>: désigne le fait de réserver un
        Produit ou un Service sur le Site, en vue de son achat ultérieur après
        acceptation par le Vendeur ou Prestataire&nbsp;;
      </p>

      <p>
        <strong>Service&nbsp;</strong>:&nbsp; désigne la capacité intellectuelle
        ou technique, mise à la disposition d’un Client ;
      </p>

      <p>
        <strong>Services de livraison</strong>&nbsp;: désigne tout service à
        travers lequel un Utilisateur du Site transporte et achemine légalement,
        en assumant toutes les responsabilités à l’origine de ce type de
        Service, et par les moyens qu’il aura jugé fiables, un Colis destiné à
        un autre Utilisateur&nbsp;du Site ;
      </p>

      <p>
        <strong>Site</strong>&nbsp;: désigne le site internet exploité par
        Buy1way.com accessible principalement depuis l'URL&nbsp;<a
          href="https://www.buyetyamo.com/"
          >https://www.buy1way.com</a
        >&nbsp;et permettant aux Utilisateurs d'accéder via internet aux
        services décrits à l'article 4 des présentes CGV/CGU&nbsp;;
      </p>

      <p>
        <strong>Statuts de Transaction</strong>&nbsp;: désigne l’état de la
        Transaction, visible sur le Tableau de bord des Utilisateurs. Après
        qu’une Réservation ait été effectuée, ces Statuts renseignent sur
        l’avancement du processus de la Réservation jusqu’au payement
        (Transaction en attente, acceptée, refusée, payer, payée, commande
        livrée, commande reçue, commande expédiée, service réalisé, service
        terminé, etc.)&nbsp;;
      </p>

      <p>
        <strong>Tableau de bord Acheteur&nbsp;</strong>: désigne l’espace
        personnel visible dans le Compte Personnel de tout Utilisateur, à partir
        duquel il peut suivre et gérer ses Transactions d’achats&nbsp;;
      </p>

      <p>
        <strong>Tableau de bord Vendeur</strong>&nbsp;: désigne l’espace
        personnel visible dans le Compte Personnel de tout Utilisateur, à partir
        duquel il peut publier et gérer ses Annonces et Transactions de
        ventes&nbsp;;
      </p>

      <p>
        <strong>Transaction&nbsp;</strong>: désigne l’échange commercial de
        Produits ou Services d’un Vendeur à un Acheteur, à compter de la
        validation du paiement et la réception du Produit par l’Acheteur ou la
        réalisation du Service par le Prestataire, en vertu du contrat de vente
        conclu entre eux&nbsp;;
      </p>

      <p>
        <strong>Utilisateur</strong>&nbsp;: désigne toute personne physique ou
        morale qui accède au site et à ses services&nbsp;;
      </p>

      <p>
        <strong>Vendeur&nbsp;</strong>: désigne tout Utilisateur majeure
        titulaire d’un Compte Personnel qui vend ou souhaite vendre un ou
        plusieurs Produits ou Services sur le Site à un Acheteur&nbsp;;
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 4&nbsp;: Accès aux services</strong></p>

      <p>
        Les services suivants sont accessibles pour l’Utilisateur membre du site
        buy1way.com (c’est-à-dire après son identification à l’aide de ses
        identifiants de connexion)&nbsp;:
      </p>

      <ol style="list-style-type: lower-roman">
        <li>
          Edition des informations personnelles à partir du Compte
          Personnel&nbsp;;
        </li>
        <li>Consultation des Annonces&nbsp;;</li>
        <li>Accès aux Tableaux de bord Vendeur et Acheteur&nbsp;;</li>
        <li>Accès aux paramètres du Compte Personnel&nbsp;;</li>
        <li>Publication/Ajout d’Annonces&nbsp;;</li>
        <li>
          Gestion des Annonces&nbsp;: modification, suivi, suppression&nbsp;;
        </li>
        <li>Attribution de Notes, Avis et Recommandations&nbsp;;</li>
        <li>Accès à l’historique des Transactions effectuées&nbsp;;</li>
        <li>Accès aux informations de contact des Vendeurs.</li>
      </ol>

      <p>&nbsp;</p>

      <p>
        En créant un Compte Personnel, chaque Utilisateur reconnaît et accepte
        que Buy1way.com puisse supprimer, à tout moment, un compte qui serait
        contraire notamment à la loi en vigueur dans son pays et/ou aux règles
        de diffusion fixées dans ces CGV/CGU.
      </p>

      <p>
        L’Annonceur reconnait et accepte que Buy1way.com puisse lui demander
        tout document permettant de vérifier les informations renseignées lors
        de la création de son Compte Personnel et la détention des droits et/ou
        l’obtention des autorisations nécessaires à la publication de son
        Annonce.
      </p>

      <p>
        A défaut de communication des justificatifs demandés, Buy1way.com est en
        droit de supprimer, ou refuser, à tout moment, les Annonces et/ou le
        Compte Personnel de l’Annonceur.
      </p>

      <p>
        Tout Utilisateur ayant accès à internet peut accéder gratuitement et
        depuis n’importe quel lieu au Site. Les frais supportés par
        l’Utilisateur pour y accéder (connexion internet, matériel informatique,
        etc.) ne sont pas à la charge de l’Éditeur.
      </p>

      <p>
        Un Utilisateur non connecté au Site à partir de ses identifiants de
        connexion et donc non identifié ne pourra pas réaliser de Transactions.
      </p>

      <p>Il pourra néanmoins consulter les Annonces.</p>

      <p>&nbsp;</p>

      <p>
        Le Site et ses différents services peuvent être interrompus ou suspendus
        par l’Éditeur, notamment à l’occasion d’une maintenance, sans obligation
        de préavis ou de justification.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong
          >ARTICLE 5&nbsp;: Modalités de payement et de reversement des Frais au
          Vendeur de Produits et Prestataire de Services</strong
        >
      </p>

      <p>
        En utilisant la Plateforme en tant que Vendeur de Produits ou
        Prestataire de Services, vous confiez à Buy1way.com un mandat
        d’encaissement du montant des Frais de Produits et Services, en votre
        nom et pour votre compte.
      </p>

      <p>
        Par conséquent, dans le cadre d’une Transaction effectuée sur le Site
        depuis la Réservation jusqu’au payement, les Frais sont reçus par
        Buy1way.com et déposés sur un compte dédié au payement des Vendeurs et
        Prestataires.
      </p>

      <p>&nbsp;</p>

      <p>
        Les moyens de payement mis à disposition des Utilisateurs sur la
        Plateforme sont&nbsp;:
      </p>

      <ol style="list-style-type: lower-roman">
        <li>
          Mobile money&nbsp;: vous pouvez utiliser votre compte mobile money
          (Orange money, MTN money, etc.) pour effectuer un payement sur la
          plateforme&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          PayPal, en utilisant un compte PayPal ou une carte bancaire (VISA,
          MASTERCARD, etc.).
        </li>
      </ol>

      <p>&nbsp;</p>

      <p>Tous ces moyens de payement sont sécurisés.</p>

      <p>&nbsp;</p>

      <p>
        Dès la fin de la Transaction (réception d’une commande, réalisation d’un
        service, etc.)&nbsp;:
      </p>

      <ol style="list-style-type: lower-roman">
        <li>
          Le Vendeur ou Prestataire de Services doit impérativement actualiser
          le Status de Transaction (Commande livrée à l’Acheteur, Service
          réalisé, etc.) via son Tableau de bord Vendeur&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          De même, l’Acheteur est invité (fortement recommandé) à actualiser le
          Statut de Transaction via son Tableau de bord Acheteur&nbsp;;
        </li>
      </ol>

      <p style="margin-left: 54pt">
        En effet, l’Acheteur dispose d’un délai de 24 heures après la
        confirmation de fin de Transaction par le Vendeur ou Prestataire, pour
        présenter une réclamation à Buy1way.com.
      </p>

      <p style="margin-left: 54pt">
        Cette réclamation peut être faite à travers l’actualisation du Statut de
        Transaction via son Tableau de bord Acheteur, ou directement en envoyant
        un mail à&nbsp;<a href="mailto:serviceclient@buyetyamo.com"
          >serviceclient@buy1way.com</a
        >.
      </p>

      <ol style="list-style-type: lower-roman">
        <li value="3">
          En l’absence de contestation de la part de l’Acheteur dans cette
          période de 24 heures, Buy1way.com considère la confirmation de fin de
          Transaction comme acquise.
        </li>
      </ol>

      <p style="margin-left: 54pt">
        Le Vendeur ou Prestataire de Services dispose alors d’un crédit sur son
        Compte personnel. Ce crédit correspond au montant total payé par
        l’Acheteur au moment de la confirmation de la Réservation, diminué des
        Frais de Commission de la Plateforme.
      </p>

      <p style="margin-left: 54pt">
        Les Utilisateurs du Site acceptent et reconnaissent que le pourcentage
        de cette Commission peut être amené à varier à la hausse ou à la baisse,
        sans préavis et sans justification de la part de Buy1way.com.
      </p>

      <ol style="list-style-type: lower-roman">
        <li value="4">
          Un ordre de virement au nom du Vendeur ou Prestataire est transmis
          sous 48 heures, après la confirmation effective de fin de Transaction.
        </li>
      </ol>

      <p style="margin-left: 54pt">
        Le Vendeur ou Prestataire reçoit alors les Frais de vente de son Produit
        ou Service, directement dans son compte PayPal (préalablement renseigné
        dans ses paramètres de Compte personnel) ou son compte mobile money.
      </p>

      <p style="margin-left: 54pt">
        Vous reconnaissez et acceptez que Buy1way.com peut, à son entière
        discrétion, arrondir (par excès ou par défaut, à un nombre entier
        naturel, ou un nombre décimal au centième) ces Frais.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 6&nbsp;: Conditions financières</strong></p>

      <p>
        L’accès et l’inscription à la Plateforme, de même que la recherche, la
        consultation et la publication d’Annonces, la Réservation de Produits et
        Services sont gratuits.
      </p>

      <p>La mise en relation entre Utilisateurs est également gratuite.</p>

      <p>
        En effet, lors de la Réservation d’un Produit ou d’un Service sur la
        Plateforme, des informations de contact sont envoyées aux deux
        Utilisateurs&nbsp;:
      </p>

      <ol style="list-style-type: lower-roman">
        <li>
          Le Vendeur de Produits ou Prestataire de Services reçoit un mail avec
          les informations sur le potentiel Acheteur.
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          De même, l’Acheteur de Produits ou Services reçoit un mail avec les
          informations sur le Vendeur ou Prestataire de Services.
        </li>
      </ol>

      <p>
        Toutes ces informations sont également consultables dans leurs Tableaux
        de bord, en cliquant sur le Produit ou Service réservé.
      </p>

      <p>
        A partir de ces informations, les deux Utilisateurs sont libres de se
        contacter, et de se mettre d’accord sur la Transaction et ses modalités.
        Deux options s’offrent alors à eux&nbsp;:
      </p>

      <p>&nbsp;</p>

      <ol style="list-style-type: lower-alpha">
        <li>
          <strong
            >Non utilisation des moyens de payements de la Plateforme</strong
          >
        </li>
      </ol>

      <p style="margin-left: 54pt">
        Les Utilisateurs peuvent décider de continuer la Transaction en dehors
        de la Plateforme. Dans ce cas, Buy1way.com ne saurait voir sa
        responsabilité engagée au titre du déroulement d’une telle Transaction,
        les Utilisateurs étant seuls responsables de leurs actes et des
        conséquences qui peuvent en découler.
      </p>

      <ol style="list-style-type: lower-alpha">
        <li value="2">
          <strong
            >&nbsp;Utilisation des moyens de payements sécurisés de la
            Plateforme</strong
          >
        </li>
      </ol>

      <p style="margin-left: 54pt">
        Les Utilisateurs peuvent décider de continuer la Transaction jusqu’au
        payement sur la Plateforme, en utilisant les moyens de payement
        sécurisés prévus à cet effet (décrits dans l’article 5 ci-dessus).
      </p>

      <p style="margin-left: 54pt">
        L’Acheteur doit alors se connecter sur le Site, et procéder au payement
        du Produit ou Service via son Tableau de bord Acheteur, après avoir
        sélectionné le Produit ou Service concerné.
      </p>

      <p>&nbsp;</p>

      <p>&nbsp;</p>

      <p>
        <strong
          >ARTICLE 7&nbsp;: Options payantes et achat de packs boosters /
          publicité Facebook</strong
        >
      </p>

      <p>&nbsp;</p>

      <ol style="list-style-type: lower-roman">
        <li>
          <strong>Ajout et Gestion des publications sur la plateforme</strong>
        </li>
      </ol>

      <p>
        <strong>​</strong>Deux packs sont à distinguer pour l'ajout et la
        gestion ​des publications sur la plateforme :&nbsp;
      </p>

      <p>&nbsp;</p>

      <ol style="list-style-type: lower-alpha">
        <li><strong>Le&nbsp;« Pack Post Autonomy » : Gratuit</strong></li>
      </ol>

      <p>
        Après son inscription sur la plateforme, tout utilisateur qui souhaite
        publier des offres se voit directement et gratuitement
        attribué&nbsp;le&nbsp;<strong>«&nbsp;Pack Post Autonomy ».</strong>
      </p>

      <p>
        Ce pack permet aux utilisateurs d'être autonome dans la publication et
        la gestion de leurs offres sur la plateforme.
      </p>

      <p>
        Leurs publications sont visibles par les visiteurs et utilisateurs de la
        plateforme. Elles sont également visibles sur Facebook après leur
        diffusion par Buy1way sur sa page Facebook.
      </p>

      <p>&nbsp;</p>

      <ol style="list-style-type: lower-alpha">
        <li value="2">
          &nbsp;<strong>Le&nbsp;« Pack Tranquillity » : Gratuit</strong
          ><strong>*</strong>
        </li>
      </ol>

      <p>
        La&nbsp;souscription&nbsp;à ce pack se fait&nbsp;par demande explicite
        écrite à&nbsp;<a href="mailto:contact@buy1way.com"
          >contact@buy1way.com</a
        >, ou en envoyant un message dans ce sens via les pages&nbsp;Messenger
        et WhatsApp de Buy1way&nbsp;:
      </p>

      <p>
        &nbsp;<a href="https://wa.me/message/AZJBG6N5GA66L1"
          >https://wa.me/message/AZJBG6N5GA66L1</a
        >&nbsp;/+237 6 93 40 49 45.
      </p>

      <p>
        Ce pack permet à l'annonceur (vendeur, prestataire, etc.)&nbsp;de
        déléguer&nbsp;à Buy1way, la publication et la gestion de ses&nbsp;offres
        sur la plateforme, y compris la gestion des commandes. L'annonceur devra
        envoyer à Buy1way par mail (<a href="mailto:contact@buy1way.com"
          >contact@buy1way.com</a
        >), Messenger ou WhatsApp, les différentes offres (photos, descriptions,
        prix).
      </p>

      <p>
        Buy1way s'occupe alors de tout, et ne revient vers l'annonceur que
        lorsqu'une commande est passée. L'annonceur se charge de finaliser la
        transaction avec le client (payement, livraison).&nbsp;
      </p>

      <p>
        Les&nbsp;publications des annonceurs sont visibles par les visiteurs et
        utilisateurs de la plateforme. Elles sont également visibles sur
        Facebook après leur diffusion par Buy1way sur sa page Facebook.
      </p>

      <p>
        <strong>*</strong>&nbsp;Une commission de 12% est prélevée sur chaque
        produit vendu.
      </p>

      <p>&nbsp;</p>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          <strong>Boost des publications / Publicité</strong
          ><strong>&nbsp;Facebook<strong>&nbsp;</strong></strong>
        </li>
      </ol>

      <p>
        Ces options permettent d'améliorer la visibilité des publications ou
        offres quelconques sur la plateforme Buy1way, sur une autre plateforme /
        site web, et sur les réseaux sociaux Facebook et Instagram.
      </p>

      <p>
        Si l'annonceur dispose de pages Facebook et Instagram professionnelles,
        le boost des publications et les publicités Facebook peuvent&nbsp;être
        réalisées directement sur ses&nbsp;pages.
      </p>

      <p>
        Dans le cas contraire, ce&nbsp;sont les pages de Buy1way qui sont
        utilisées.
      </p>

      <p>
        Un échange préalable (entretien téléphonique, mail, WhatsApp, Messenger)
        est réalisé entre l'annonceur et Buy1way pour préciser l'objectif
        souhaité pour sa campagne publicitaire (interactions avec les
        publications, impressions, trafic, etc.).
      </p>

      <p>
        Ensuite l'annonceur devra choisir entre 3 offres standards (définies en
        fonction de nos objectifs de performances pour les publications boostées
        et publicités Facebook)&nbsp;:&nbsp;
      </p>

      <p>&nbsp;</p>

      <ol style="list-style-type: lower-alpha">
        <li><strong>Le&nbsp;« Pack Boost 3 » : 7500 FCFA</strong></li>
      </ol>

      <p style="margin-left: 36pt">
        Publications boostées ou Publicité sur Facebook pendant trois (03) jours
        consécutifs. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      </p>

      <ol style="list-style-type: lower-alpha">
        <li value="2">
          <strong>Le&nbsp;« Pack Boost 5&nbsp;» : 12500 FCFA</strong>
        </li>
      </ol>

      <p style="margin-left: 36pt">
        Publications boostées ou Publicité sur Facebook pendant cinq&nbsp;(05)
        jours consécutifs.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      </p>

      <ol style="list-style-type: lower-alpha">
        <li value="3">
          <strong
            >Le&nbsp;« Pack Boost Pro&nbsp;» : 12500 FCFA pour les cinq (05)
            premiers jours + 2500 FCFA par jour à partir du sixième jour</strong
          >
        </li>
      </ol>

      <p style="margin-left: 36pt">
        Publications boostées ou Publicité sur Facebook pendant au moins
        cinq&nbsp;(05) jours consécutifs.
      </p>

      <p>&nbsp;</p>

      <p>
        Ces prix comportent les frais de publicité à payer à Facebook pour le
        nombre de jours souhaités, ainsi que les frais de commission Buy1way
        pour la mise en place des publicités/publications à booster et la
        gestion de ces dernières.
      </p>

      <p>
        La gestion consiste à modérer toutes les interactions avec la(les)
        publication(s) boostée(s) / publicité(s), et à rediriger les potentiels
        clients vers l’annonceur.
      </p>

      <p>
        L'achat de ces packs se fait&nbsp;par demande explicite écrite à&nbsp;<a
          href="mailto:contact@buy1way.com"
          >contact@buy1way.com</a
        >, ou en envoyant un message dans ce sens via les pages&nbsp;Messenger
        et WhatsApp de Buy1way&nbsp;:
      </p>

      <p>
        <a href="https://wa.me/message/AZJBG6N5GA66L1"
          >https://wa.me/message/AZJBG6N5GA66L1</a
        >&nbsp;/+237 6 93 40 49 45.
      </p>

      <p>
        L'annonceur devra acheter ces offres en procédant à un payement par
        virement Mobile Money sur les comptes de Buy1way :&nbsp;
      </p>

      <ul>
        <li>Orange :&nbsp;+237 6 93 40 49 45</li>
        <li>MTN : +237 6 78 37 34 36</li>
      </ul>

      <p>
        Les annonceurs qui ont un budget restreint mais qui souhaitent booster
        leurs publications ou profiter de la publicité Facebook peuvent nous
        contacter via l'un des moyens ci-dessus, pour recevoir une offre en
        fonction de leur budget.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 8&nbsp;: Politique de remboursement</strong></p>

      <p>
        Les Transactions confirmées et payées ne peuvent être remboursées qu’au
        terme d’un commun accord du Vendeur et de l’Acheteur.
      </p>

      <p>
        En effet, après avoir reçu un Produit, ou au moment de la réalisation
        effective d’un Service, ce dernier peut ne pas être conforme à la
        description donnée par le Vendeur dans son Annonce.
      </p>

      <p>
        L’Acheteur doit alors en discuter avec le Vendeur ou Prestataire pour se
        mettre d’accord sur la façon de procéder.
      </p>

      <p>
        S’ils se mettent d’accord pour un remboursement, tous deux doivent
        contacter le service client de Buy1way.com à l’adresse mail&nbsp;<a
          href="mailto:serviceclient@buyetyamo.com"
          >serviceclient@buy1way.com</a
        >&nbsp;pour effectuer cette demande. Cette demande sera traitée dans les
        plus brefs délais et un ordre de virement sera effectué au nom de
        l’Acheteur, selon le moyen utilisé pour l’achat initial (compte PayPal,
        préalablement renseigné dans ses paramètres de Compte personnel, ou
        compte Mobile money).
      </p>

      <p>
        Le Vendeur et l’Acheteur sont seuls responsables d’organiser les
        conditions éventuelles de retour de Produits.
      </p>

      <p>
        Pour éviter d’en arriver là, il est fortement recommandé de bien
        échanger avec le Vendeur ou Prestataire dès la réception du mail
        contenant ses informations de contact, de manière à vérifier toutes
        informations décrites dans l’Annonce avant de continuer la Transaction
        sur le Site ou en dehors du Site.
      </p>

      <p>
        Privilégiez les échanges écrits (mails, sms, etc.) puisqu’ils pourront
        être utilisés ultérieurement dans le cadre de réclamations.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 9&nbsp;: Droit de rétraction</strong></p>

      <p>
        En acceptant les présentes CGV/CGU, vous acceptez expressément que le
        contrat entre vous et Buy1way.com consistant en la mise en relation avec
        un autre Utilisateur soit exécuté, et renoncez expressément à votre
        droit de rétraction.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 10&nbsp;: Statuts de Transaction</strong></p>

      <p>
        Des Statuts de Transaction (Service réalisé, Commande expédiée, Commande
        livrée, etc.) sont disponibles sur les Tableaux de bord des
        Utilisateurs, à partir du moment où une Transaction est initiée.
      </p>

      <p>
        Les Utilisateurs s’engagent à n’actualiser ces Statuts de Transaction
        qu’aux moments appropriés, de façon à ce que le Statut renseigné sur le
        Site décrive effectivement la réalité du Statut de Transaction en cours
        de réalisation.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 11&nbsp;: Responsabilité de l’Utilisateur</strong></p>

      <p>
        L'Utilisateur déclare être majeur conformément à la législation de son
        pays.
      </p>

      <p>
        Il est responsable des risques liés à l’utilisation de son identifiant
        de connexion et de son mot de passe.
      </p>

      <p>
        Tout Utilisateur, en particulier les Vendeurs et Prestataires, déclare
        avoir pris connaissance des présentes Conditions Générales de Vente et
        d’Utilisation, et s’engage à se conformer à la loi en vigueur régissant
        le commerce électronique dans son pays de résidence.
      </p>

      <p>L’Utilisateur s’engage également&nbsp;à :</p>

      <ol style="list-style-type: lower-roman">
        <li>
          Ne transmettre à Buy1way.com (notamment lors de la création ou de la
          mise à jour de votre Compte) aucune information fausse, trompeuse,
          mensongère ou frauduleuse&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          Ne renseigner (notamment lors de l’ajout d’Annonces/Publications sur
          le Site) aucune information fausse, trompeuse, mensongère ou
          frauduleuse&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="3">
          Ne pas ouvrir plus d’un Compte personnel sur la Plateforme et ne pas
          ouvrir de Compte personnel au nom d’un tiers&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="4">
          Ne pas contacter un autre Utilisateur (par exemple après avoir reçu
          ses informations de contact par l’intermédiaire de la Plateforme) à
          une autre fin que celle de définir les modalités de la
          Transaction&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="5">
          Ne pas porter atteinte aux droits et à l’image de Buy1way.com,
          notamment à ses droits de propriété intellectuelle&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="6">
          Utiliser de manière conforme et sincère la mise à jour des Statuts de
          Transaction&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="7">Se conformer aux présentes CGV/CGU.</li>
      </ol>

      <p>&nbsp;</p>

      <p>
        Le mot de passe de l’Utilisateur doit rester secret. En cas de
        divulgation de mot de passe, l’Éditeur décline toute responsabilité.
      </p>

      <p>
        L’Utilisateur assume l’entière et exclusive responsabilité de
        l’utilisation qu’il fait des informations et contenus présents sur le
        site buy1way.com. Tout Utilisateur qui de par son usage cause
        directement ou indirectement des dommages au Site et/ou à la société
        Buy1way.com s’oblige à le réparer et peut faire l’objet de poursuites
        judiciaires.
      </p>

      <p>
        L’Utilisateur s’engage à agir dans le respect des lois en vigueurs, à ne
        pas tenir des propos irrespectueux à l’égard des autres et accepte que
        ses Publications soient modérées ou refusées par l’Éditeur, sans
        obligation de justification.
      </p>

      <p>
        L'Utilisateur renonce également à exercer tout recourt contre
        Buy1way.com dans le cas de poursuites diligentées par un tiers à son
        encontre du fait de l'utilisation et/ou de l'exploitation illicite du
        Site buy1way.com, en cas de perte par un Utilisateur ou un Annonceur de
        son mot de passe ou en cas d'usurpation de son identité.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong
          >ARTICLE 12&nbsp;: Responsabilité du Vendeur ou Prestataire de
          Services</strong
        >
      </p>

      <p>
        En déposant toute Annonce, chaque Vendeur ou Prestataire consent à
        Buy1way.com le droit de la supprimer ou de la refuser à tout moment, en
        cas de juste motif, sans indemnité ni droit à remboursement.
      </p>

      <p>
        Toute Annonce est diffusée, à compter du jour de son dépôt et ce pour
        une durée maximale de 60 jours. Passée cette durée initiale de 60 jours,
        Buy1way.com envoie à l'Annonceur un e-mail l'informant que son Annonce a
        expiré et qu'elle n'est plus en ligne et lui propose de reconduire son
        Annonce. Si l'Annonceur ne reconduit pas son Annonce dans les 5 jours
        suivant la réception de cet e-mail, l’Annonce sera supprimée du Site.
      </p>

      <p>
        L’Annonceur devra alors la renouveler s’il veut qu’elle soit de nouveau
        publiée sur le Site.
      </p>

      <p>Les Vendeurs de Produits et Prestataires de Services doivent :</p>

      <ol style="list-style-type: lower-roman">
        <li>
          Détenir tous les droits (notamment des droits de propriété
          intellectuelle) ou avoir obtenu préalablement à la publication de son
          Annonce, les autorisations et déclarations nécessaires à la
          Transaction ;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          Se conformer à l’ensemble des lois, règlementations et usages en
          vigueur applicables à la vente, au transport, à l’usage et à la
          consommation des Produits concernés, à la sécurité, à la santé et à
          protection de l’environnement&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="3">
          Garantir que l'Annonce ne contrevient à aucune réglementation en
          vigueur (notamment relatives à la publicité, à la concurrence, à
          l'utilisation de données personnelles, à la prohibition de la
          commercialisation de certains Produits ou Services), ni aux droits des
          tiers (notamment aux droits de la propriété intellectuelle et aux
          droits de la personnalité) et qu'elle ne comporte aucun message
          diffamatoire ou dommageable à l'égard de tiers&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="4">
          Publier des Annonces correspondant uniquement à des ventes ou
          prestations réellement envisagées ;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="5">
          Publier des Annonces correspondant uniquement à des Produits ou
          Services disponibles&nbsp;;&nbsp;En cas d’indisponibilité de Produits
          ou Services, le Vendeur ou Prestataire s’engage à procéder au retrait
          de l’Annonce du site buy1way.com&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="6">
          Donner dans l’Annonce, la description la plus complète et la plus
          explicite possible de leurs Produits, et de n’envoyer aux Acheteurs
          que des Produits conformes à cette description&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="7">
          Donner dans l’Annonce, la description la plus complète et la plus
          explicite possible de leurs Services, et de ne réaliser que des
          Services conformes à cette description ;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="8">
          Ne pas publier des Annonces contenant des liens hypertextes&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="9">
          Ne pas publier des Annonces contenant des informations fausses,
          mensongères ou de nature à induire en erreur les Utilisateurs&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="10">
          Ne pas publier sur le Site, les Produits et Services non autorisés
          ci-dessous&nbsp;:
        </li>
      </ol>

      <ul>
        <li>
          Tabac, drogue et objets associés, substances dangereuses et
          illicites&nbsp;;
        </li>
        <li>
          Armes blanches de combat ou d’autodéfense, armes à feu,
          explosifs&nbsp;;
        </li>
        <li>Contenu réservé aux adultes (pornographie, nudité, etc.)&nbsp;;</li>
        <li>
          Certaines espèces végétales et animales protégées, menacées ou
          prélevées dans leur milieu naturel&nbsp;;
        </li>
        <li>Objets consignés ou saisies&nbsp;;</li>
        <li>Appel aux dons financiers privés ou à but humanitaire&nbsp;;</li>
        <li>
          Offres pouvant mener à des pratiques illicites et
          anti-concurrentielles&nbsp;;
        </li>
        <li>Avis de recherche d’une personne&nbsp;;</li>
        <li>Produit portant des emblèmes protégés.</li>
      </ul>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 13&nbsp;: Responsabilité de Buy1way.com</strong></p>

      <p>
        En utilisant le Site et en acceptant les présentes CGV/CGU, vous
        reconnaissez que Buy1way.com n’est partie à aucun accord conclu entre
        vous et les autres Utilisateurs.
      </p>

      <p>
        Buy1way.com n’a aucun contrôle sur le comportement de ses Utilisateurs,
        et ne possède pas, n’exploite pas, ne fournit pas, ne gère pas les
        Annonces.
      </p>

      <p>
        Vous reconnaissez et acceptez que Buy1way.com ne contrôle ni la
        validité, ni la véracité, ni la fiabilité (bien que les informations
        diffusées sur le Site soient réputées fiables), ni la légalité des
        Annonces. En sa qualité d’intermédiaire, Buy1way.com ne saurait voir sa
        responsabilité engagée au titre du déroulement d’une Transaction,
        notamment du fait&nbsp;:
      </p>

      <ol style="list-style-type: lower-roman">
        <li>
          D’informations erronées communiquées par les Utilisateurs&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          Du comportement de ses Utilisateurs avant, pendant ou après la
          Transaction&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="3">
          D’une force majeure ou du fait imprévisible et insurmontable d'un
          tiers.
        </li>
      </ol>

      <p style="margin-left: 54pt">&nbsp;</p>

      <p>
        Tout dysfonctionnement du serveur ou du réseau ne peut engager la
        responsabilité de Buy1way.com.
      </p>

      <p>
        Le site buy1way.com s'engage à mettre en œuvre tous les moyens
        nécessaires pour garantir la sécurité et la confidentialité des données.
        Toutefois, il n’apporte pas une garantie de sécurité totale.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 14&nbsp;: Propriété intellectuelle</strong></p>

      <p>
        Les contenus du site buy1way.com (logos, textes, éléments graphiques,
        vidéos, etc.) sont protégés par le droit d’auteur, en vertu des textes
        en vigueur en matière de propriété intellectuelle.
      </p>

      <p>
        L’Utilisateur devra obtenir l’autorisation écrite de l’Editeur du site
        avant toute reproduction, copie ou publication de ces différents
        contenus.
      </p>

      <p>
        Vous vous interdisez toute autre utilisation ou exploitation du Site,de
        ses services, et de son contenu sans l’autorisation préalable écrite de
        Buy1way.com. Notamment, vous vous interdisez de&nbsp;:
      </p>

      <ol style="list-style-type: lower-roman">
        <li>
          Reproduire, modifier, adapter, distribuer, représenter publiquement,
          diffuser la Plateforme, ses services et son contenu, à l’exception de
          ce qui est expressément autorisé par Buy1way.com&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="2">
          Décompiler, procéder à de l’ingénierie inverse de la Plateforme ou de
          ses services, sous réserve des exceptions prévues par les textes en
          vigueur&nbsp;;
        </li>
      </ol>

      <ol style="list-style-type: lower-roman">
        <li value="3">
          Extraire ou tenter d’extraire (notamment en utilisant des robots
          d’aspiration de données ou tout autre outil similaire de collecte de
          données) une partie substantielle des données de la Plateforme.
        </li>
      </ol>

      <p>
        L’Utilisateur est entièrement responsable de tout contenu qu’il met en
        ligne et il s’engage à ne pas porter atteinte à un tiers.
      </p>

      <p>
        L’Éditeur du site se réserve le droit de modérer ou de supprimer
        librement et à tout moment les contenus mis en ligne par les
        utilisateurs, et ce sans justification.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 15&nbsp;: Données personnelles</strong></p>

      <p>
        En utilisant le site buy1way.com, vous êtes amenés à nous transmettre
        des informations « Données personnelles », dont certaines sont de nature
        à vous identifier.
      </p>

      <p>
        Ces informations sont recueillies notamment lorsque vous remplissez des
        formulaires, comme le formulaire d’inscription et d’édition de profil.
      </p>

      <p>
        Elles peuvent également être recueillies lors de l’inscription à partir
        de réseaux sociaux, et lors de réactions (mentions «&nbsp;J’aime&nbsp;»,
        «&nbsp;partage&nbsp;», etc.) aux publications effectuées sur les réseaux
        sociaux par buy1way.com.
      </p>

      <p>Les informations recueillies peuvent contenir notamment&nbsp;:</p>

      <ol style="list-style-type: lower-roman">
        <li>Noms</li>
        <li>Prénoms</li>
        <li>Pseudos</li>
        <li>Date de naissance</li>
        <li>Adresse email</li>
        <li>Adresse postale</li>
        <li>Numéro de téléphone</li>
        <li>Photographie</li>
      </ol>

      <p>
        Dans le cadre de l’utilisation de nos services, certaines de vos
        informations sont transmises aux autres Utilisateurs. Par exemple, nous
        communiquons les informations ci-dessus aux deux parties (Acheteurs
        &amp; Vendeurs) lorsqu’une Transaction est initiée entre eux sur le
        Plateforme.
      </p>

      <p>
        Conformément à la législation applicable et avec votre consentement
        lorsqu’il est requis, nous pourrons utiliser les données que vous nous
        fournissez à des fins de prospection commerciale (par exemple pour vous
        adresser nos newsletters, ou pour toute autre communication susceptible
        de vous intéresser et afficher des publicités ciblées sur les
        plateformes de médias sociaux ou sites tiers).
      </p>

      <p>
        Le site buy1way.com garantie le respect de la vie privée de
        l’utilisateur, conformément à la loi en vigueur relative à
        l'informatique, aux fichiers et aux libertés.
      </p>

      <p>
        En vertu de cette loi, l'Utilisateur dispose d'un droit d'accès, de
        rectification, de suppression et d'opposition de ses données
        personnelles. L'Utilisateur exerce ce droit via :
      </p>

      <ol style="list-style-type: lower-roman">
        <li>Son espace personnel sur le site ;</li>
        <li>Un formulaire de contact ;</li>
        <li>Par mail à contact@buy1way.com.</li>
      </ol>

      <p>
        Buy1way.com assure un niveau de sécurité approprié et proportionnel aux
        risques encourus ainsi qu'à leur probabilité, conformément au Règlement
        Général sur la Protection des Données 2016/679 du 27 avril 2016.
      </p>

      <p>
        Toutefois, ces mesures ne constituent en aucun cas une garantie et
        n'engagent pas Buy1way.com à une obligation de résultat concernant la
        sécurité des données.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 16&nbsp;: Cookies</strong></p>

      <p>
        Pour permettre à ses Utilisateurs de bénéficier d’une navigation
        optimale sur le Site et d’un meilleur fonctionnement des différentes
        interfaces, Buy1way.com est susceptible d’implanter un
        «&nbsp;cookie&nbsp;» sur l’ordinateur ou le smartphone de l’Utilisateur.
        Ce cookie permet de stocker des informations relatives à la navigation
        sur le Site, ainsi qu’aux éventuelles données saisies par les
        Utilisateurs (notamment recherches, login, email, mot de passe).
      </p>

      <p>
        L’Utilisateur dispose de la possibilité de bloquer, modifier la durée de
        conservation, ou supprimer ce cookie via l’interface de son navigateur.
        Si la désactivation systématique des cookies sur le navigateur de
        l’Utilisateur l’empêche d’utiliser certains services ou fonctionnalités
        du Site, ce dysfonctionnement ne saurait en aucun cas constituer un
        dommage pour le membre qui ne pourra prétendre à aucune indemnité de ce
        fait.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 17&nbsp;: Liens hypertextes</strong></p>

      <p>
        Les domaines vers lesquels mènent les liens hypertextes présents sur le
        site n’engagent pas la responsabilité de l’Éditeur de buy1way.com, qui
        n’a pas de contrôle sur ces liens.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong
          >ARTICLE 18&nbsp;: Évolution des conditions générales de vente et
          d’utilisation (CGV/CGU)</strong
        >
      </p>

      <p>
        Le site buy1way.com se réserve le droit de modifier les clauses de ces
        conditions générales de vente et d’utilisation (CGV/CGU) à tout moment
        et sans justification.
      </p>

      <p>&nbsp;</p>

      <p><strong>ARTICLE 19&nbsp;: Durée du contrat</strong></p>

      <p>
        La durée du présent contrat est indéterminée. Le contrat produit ses
        effets à l'égard de l'Utilisateur à compter du début de l’utilisation du
        Site et ses services.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong
          >ARTICLE 20&nbsp;: Droit applicable et juridiction compétente</strong
        >
      </p>

      <p>
        Le présent contrat dépend de la législation en vigueur dans le pays de
        son utilisation.
      </p>

      <p>
        En cas de litige non résolu à l’amiable entre l’Utilisateur et
        l’Éditeur, les litiges peuvent être portés devant les juridictions du
        siège social de Buy1way.com, ou les juridictions du pays de résidence de
        l’Utilisateur, selon les cas.
      </p>

      <p>&nbsp;</p>
      <p></p>
    </div>
  </div>
</div>
