import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IImageProduct, IImageService, IItems } from '../models/items.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdService {
  private baseurl = environment.API_URL;
  constructor(private http: HttpClient) {}

  //**Product */IItems

  public incrementedViewsProduct(id: string): Observable<IItems> {
    return this.http
      .get<IItems>(this.baseurl + 'product/views/' + id)
      .pipe(catchError(this.handleHttpError));
  }

  public createImageProduct(image: FormData): Observable<IImageProduct> {
    return this.http
      .post<IImageProduct>(this.baseurl + 'imageProduct', image)
      .pipe(catchError(this.handleHttpError));
  }

  public createProduct(product: IItems): Observable<IItems> {
    return this.http
      .post<IItems>(this.baseurl + 'product', product)
      .pipe(catchError(this.handleHttpError));
  }

  public getAllProduct(): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseurl + 'products')
      .pipe(catchError(this.handleHttpError));
  }

  public getProductByUserId(id: string): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseurl + 'products/' + id)
      .pipe(catchError(this.handleHttpError));
  }

  public deleteProductById(id: string): Observable<{}> {
    return this.http
      .delete<{}>(this.baseurl + 'product/' + id)
      .pipe(catchError(this.handleHttpError));
  }

  //**Service */
  public incrementedViewsService(id: string): Observable<IItems> {
    return this.http
      .get<IItems>(this.baseurl + 'service/views/' + id)
      .pipe(catchError(this.handleHttpError));
  }

  public createImageService(image: FormData): Observable<IImageService> {
    return this.http
      .post<IImageService>(this.baseurl + 'imageService', image)
      .pipe(catchError(this.handleHttpError));
  }

  public createService(service: IItems): Observable<IItems> {
    return this.http
      .post<IItems>(this.baseurl + 'service', service)
      .pipe(catchError(this.handleHttpError));
  }

  public getAllService(): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseurl + 'services')
      .pipe(catchError(this.handleHttpError));
  }

  public getServiceByUserId(id: string): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseurl + 'services/' + id)
      .pipe(catchError(this.handleHttpError));
  }

  public deleteServiceById(id: string): Observable<{}> {
    return this.http
      .delete<{}>(this.baseurl + 'service/' + id)
      .pipe(catchError(this.handleHttpError));
  }

  private handleHttpError(err: HttpErrorResponse) {
    let error: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
      error = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${err.status}, ` + `body was: ${err.error}`
      );
      error = `Backend returned code ${err.status}, body was: ${err.error}`;
    }
    // Return an observable with a user-facing error message.
    return throwError(err);
  }
}
