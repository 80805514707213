import { sliderOpt } from 'src/app/shared/data';

export const introSlider = {
  ...sliderOpt,
  nav: false,
  dots: true,
  loop: false,
};

export const brandSlider = {
  ...sliderOpt,
  nav: false,
  dots: false,
  margin: 20,
  loop: false,
  responsive: {
    0: {
      items: 2,
    },
    420: {
      items: 3,
    },
    600: {
      items: 4,
    },
    900: {
      items: 5,
    },
    1600: {
      items: 6,
      nav: true,
    },
  },
};

export const categoriesSlider = {
  ...sliderOpt,
  nav: true,
  dots: true,
  margin: 20,
  autoplay: true,
  autoplayTimeout: 15000,
  autoplayHoverPause: true,
  loop: true,
  responsive: {
    0: {
      items: 1,
      nav: true,
    },
    576: {
      items: 2,
      nav: true,
    },
    768: {
      items: 3,
      nav: true,
    },
    992: {
      items: 3,
      nav: true,
    },
    1200: {
      items: 4,
    },
    1800: {
      items: 6,
    },
  },
};
export const recentsSlider = {
  ...sliderOpt,
  nav: true,
  dots: true,
  margin: 20,
  autoplay: true,
  autoplayTimeout: 15000,
  autoplayHoverPause: true,
  loop: true,
  responsive: {
    0: {
      items: 1,
      nav: true,
    },
    576: {
      items: 1,
      nav: true,
    },
    768: {
      items: 3,
      nav: true,
    },
    992: {
      items: 3,
      nav: true,
    },
    1200: {
      items: 4,
    },
    1800: {
      items: 6,
    },
  },
};

export const productSlider1 = {
  ...sliderOpt,
  nav: false,
  dots: true,
  margin: 20,
  loop: false,
  responsive: {
    0: {
      items: 2,
    },
    480: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
    1280: {
      items: 5,
      nav: true,
    },
  },
};

export const productSlider2 = {
  ...sliderOpt,
  nav: true,
  dots: false,
  margin: 20,
  loop: false,
  responsive: {
    0: {
      items: 2,
    },
    480: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
    1200: {
      items: 3,
    },
    1600: {
      items: 4,
    },
  },
};

export const blogSlider = {
  ...sliderOpt,
  nav: false,
  dots: true,
  autoHeight: true,
  loop: true,
};
