import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { IMessage, IsendContact } from '../models/messages.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private receiverId = new BehaviorSubject<string>('');
  private baseurl = environment.API_URL;

  constructor(private http: HttpClient) {}

  setReceiverId(receiverId: string) {
    this.receiverId.next(receiverId);
  }

  getReceiverId(): Observable<string> {
    return this.receiverId.asObservable();
  }

  // Method to send a new message
  public sendMessage(message: IMessage): Observable<any> {
    return this.http
      .post<IMessage>(`${this.baseurl}message`, message)
      .pipe(catchError(this.handleHttpError));
  }

  // Method to get all user conversations
  public getAllConversations(userId: string): Observable<any> {
    return this.http
      .get<any>(`${this.baseurl}conversations/${userId}`)
      .pipe(catchError(this.handleHttpError));
  }

  // Method to get all conversation messages
  public getAllConversationMessages(conversationId: string): Observable<any> {
    return this.http
      .get<any>(`${this.baseurl}messages/${conversationId}`)
      .pipe(catchError(this.handleHttpError));
  }

  // Method for defining read message*/
  public markMessageAsRead(messageId: string): Observable<any> {
    return this.http
      .get<any>(this.baseurl + 'message/read/' + messageId)
      .pipe(catchError(this.handleHttpError));
  }

  // Method to count all unread messages
  public countMessages(id: string): Observable<string> {
    return this.http
      .get<string>(`${this.baseurl}messages/count/${id}`)
      .pipe(catchError(this.handleHttpError));
  }

  // Method to delete message by id
  public deleteMessageById(id: string): Observable<any> {
    return this.http
      .delete<any>(`${this.baseurl}message/${id}`)
      .pipe(catchError(this.handleHttpError));
  }

  // Method to delete conversation by id
  public deleteConversationById(id: string): Observable<any> {
    return this.http
      .delete<any>(`${this.baseurl}conversation/${id}`)
      .pipe(catchError(this.handleHttpError));
  }

  // send contact us
  sendContactForm(sendContact: IsendContact): Observable<any> {
    return this.http
      .post(`${this.baseurl}contact_us`, sendContact)
      .pipe(catchError(this.handleHttpError));
  }

  private handleHttpError(err: HttpErrorResponse): Observable<never> {
    let error: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
      error = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${err.status}, ` + `body was: ${err.error}`
      );
      error = `Backend returned code ${err.status}, body was: ${err.error}`;
    }
    // Return an observable with a user-facing error message.
    return throwError(err);
  }
}
