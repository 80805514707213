import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

import { MessageService } from '../../../../../core/services/message.service';

@Component({
  selector: 'buy1way-messages-menu',
  templateUrl: './messages-menu.component.html',
  styleUrls: ['./messages-menu.component.scss'],
})
export class MySearchMenuComponent implements OnInit, OnDestroy {
  count = '0';
  constructor(
    private messageService: MessageService,
    private storage: LocalStorageService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.storage.retrieve('user').id) {
      this.messageService
        .countMessages(this.storage.retrieve('user').id)
        .subscribe({
          next: (res: any) => (this.count = res.unread_messages),
        });
    }
  }

  showMessagesPage() {
    if (this.storage.retrieve('user').id) {
      this.router.navigateByUrl('/account/messages');
    } else {
      Swal.fire(
        this.translateService.instant('header.warning'),
        this.translateService.instant('header.you_must_be_logged_in'),
        'warning'
      );
    }
  }

  ngOnDestroy(): void {}
}
