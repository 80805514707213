<div class="home_search_header">
  <div
    class="header-search header-search-visible header-search-no-radius d-none d-lg-block"
  >
    <div class="search_radio">
      <div class="radio-btn">
        <div class="radio-btn-inline">
          <input
            type="radio"
            class="checkradio"
            id="product"
            value="product"
            [(ngModel)]="type"
            (change)="onTypeChange()"
          />
          <label for="product"
            ><span></span>{{ "header.products" | translate }}</label
          >
        </div>
        <div class="radio-btn-inline">
          <input
            type="radio"
            class="checkradio"
            id="service"
            value="service"
            [(ngModel)]="type"
            (change)="onTypeChange()"
          />
          <label for="service"
            ><span></span>{{ "header.service_provision" | translate }}</label
          >
        </div>
      </div>
    </div>

    <a href="#" class="search-toggle" role="button">
      <i class="icon-search"></i>
    </a>
    <div class="header-search-wrapper search-wrapper-wide">
      <div class="select-custom">
        <select id="cat" name="cat" (change)="onCategoryChange($event)">
          <option [value]="null">
            {{ "header.all_categories" | translate }}
          </option>
          <option *ngFor="let category of categories" value="{{ category.id }}">
            {{ translateCategory(category.id, locale) }}
          </option>
        </select>
      </div>
      <input
        type="text"
        class="form-control input-border-left"
        name="keyword"
        placeholder="{{ 'header.search' | translate }}..."
        [(ngModel)]="keyword"
      />
      <input
        id="typeahead-basic"
        style="padding-left: 20px"
        class="form-control input-border-left"
        id="city"
        type="text"
        placeholder="{{ 'header.town' | translate }}..."
        [ngbTypeahead]="search"
        [(ngModel)]="city"
      />
      <button class="btn btn-primary" type="submit" (click)="onSearch()">
        <i class="icon-search"></i>
      </button>
    </div>
  </div>
</div>
