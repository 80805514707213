// auth.actions.ts

import { IUser } from '../../models/auth.state.model';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(
    public payload: { accessToken: string; refreshToken: string; me: IUser }
  ) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class UpdateMe {
  static readonly type = '[Auth] Update Me';
  constructor(public payload: IUser) {}
}
