<nav class="main-nav">
  <ul class="menu sf-arrows">
    <li
      *ngFor="let category of categories"
      [class.active]="current.includes('/elements')"
    >
      <a
        class="sf-with-ul"
        routerLink="/ads/list"
        [queryParams]="{
          category: category.id,
          type: category.type === 'mixte' ? 'product' : category.type
        }"
        >{{ translateCategory(category.id, locale) }}</a
      >
      <ul>
        <li
          *ngFor="let subcategory of category.subcategory"
          [class.active]="current.includes('elements/products')"
        >
          <a
            routerLink="/ads/list"
            [queryParams]="{
              subcategory: subcategory.id,
              type: subcategory.type
            }"
          >
            {{ translateSubCategory(subcategory.id, locale) }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
