import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { IProfessional, IUser } from '../models/auth.state.model';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { IItems, IUserReview } from '../models/items.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.API_URL;
  constructor(private http: HttpClient) {}

  // Get wishlist product by id user
  public getsWishListServices(userId: string): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseUrl + 'whishlistService/' + userId)
      .pipe(catchError(this.handleHttpError));
  }

  // Get wishlist product by id user
  public getsWishListProducts(userId: string): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseUrl + 'wishlistProduct/' + userId)
      .pipe(catchError(this.handleHttpError));
  }

  // Reset password send email
  public resetPasswordSendEmail(formData: FormData): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + 'user/reset-email', formData)
      .pipe(catchError(this.handleHttpError));
  }

  // Reset password check code
  public resetPasswordCheckCode(code: string): Observable<any> {
    return this.http
      .get<any>(this.baseUrl + 'user/reset-password/' + code)
      .pipe(catchError(this.handleHttpError));
  }
  // Reset password check code
  public resetPasswordSend(formData: FormData): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + 'user/reset-password', formData)
      .pipe(catchError(this.handleHttpError));
  }

  // Verification enmail
  public verifyUserEmail(token: string): Observable<any> {
    return this.http
      .get<any>(this.baseUrl + 'user/account/confirmation/' + token)
      .pipe(catchError(this.handleHttpError));
  }

  public createUser(formData: FormData): Observable<IUser> {
    return this.http
      .post<IUser>(this.baseUrl + 'user', formData)
      .pipe(catchError(this.handleHttpError));
  }

  public updateUser(data: FormData, id: string): Observable<IUser> {
    return this.http
      .post<IUser>(this.baseUrl + 'user/update/' + id, data)
      .pipe(catchError(this.handleHttpError));
  }

  public login(user: IUser): Observable<IUser> {
    return this.http
      .post<IUser>(this.baseUrl + 'user/login', user)
      .pipe(catchError(this.handleHttpError));
  }

  public loginFacebook(user: IUser): Observable<IUser> {
    return this.http
      .post<IUser>(this.baseUrl + 'user/login/facebook', user)
      .pipe(catchError(this.handleHttpError));
  }

  public loginGoogle(user: IUser): Observable<IUser> {
    return this.http
      .post<IUser>(this.baseUrl + 'user/login/google', user)
      .pipe(catchError(this.handleHttpError));
  }

  public deleteUser(id: string): Observable<any> {
    const url = `${this.baseUrl + 'user'}/${id}`;
    return this.http.delete<any>(url).pipe(catchError(this.handleHttpError));
  }

  public getUserById(id: string): Observable<IUser> {
    const url = `${this.baseUrl + 'user'}/${id}`;
    return this.http.get<IUser>(url).pipe(catchError(this.handleHttpError));
  }

  public getUserReviews(user_id: string): Observable<IUserReview[]> {
    return this.http
      .get<IUserReview[]>(this.baseUrl + 'userReviews/' + user_id)
      .pipe(catchError(this.handleHttpError));
  }

  getLastItems(userId: string): Observable<any> {
    return this.http
      .get<any>(this.baseUrl + 'professional/lastItems/' + userId)
      .pipe(catchError(this.handleHttpError));
  }

  //Send User review
  public sendUserReview(userReview: IUserReview): Observable<IUserReview> {
    return this.http
      .post<IUserReview>(this.baseUrl + 'userReview/', userReview)
      .pipe(catchError(this.handleHttpError));
  }

  // Method to upload a logo image for a professional
  uploadLogo(professionalId: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('logo', file, file.name);
    return this.http
      .post(`${this.baseUrl}professional/logo/${professionalId}`, formData)
      .pipe(catchError(this.handleHttpError));
  }

  // Method to save other details of a professional
  saveProfessionalDetails(
    professionalId: string,
    data: IProfessional
  ): Observable<any> {
    return this.http
      .post(`${this.baseUrl}professional/details/${professionalId}`, data)
      .pipe(catchError(this.handleHttpError));
  }

  // Method to save availability
  saveAvailability(availabilityData: any): Observable<any> {
    return this.http
      .post(`${this.baseUrl}availability`, availabilityData)
      .pipe(catchError(this.handleHttpError));
  }

  // Method to delete availability
  deleteAvailability(availabilityId: number): Observable<any> {
    return this.http
      .delete(`${this.baseUrl}availability/${availabilityId}`)
      .pipe(catchError(this.handleHttpError));
  }

  // Retrieve a professional by ID
  getProfessionalById(user_id: number): Observable<any> {
    return this.http
      .get(`${this.baseUrl}professional/${user_id}`)
      .pipe(catchError(this.handleHttpError))
      .pipe(catchError(this.handleHttpError));
  }

  //Retrieve users availabilities
  getUserAvailabilities(userId: number): Observable<any> {
    return this.http
      .get(`${this.baseUrl}availabilities/${userId}`)
      .pipe(catchError(this.handleHttpError));
  }

  private handleHttpError(err: HttpErrorResponse) {
    let error: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
      error = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${err.status}, ` + `body was: ${err.error}`
      );
      error = `Backend returned code ${err.status}, body was: ${err.error}`;
    }
    // Return an observable with a user-facing error message.
    return throwError(err);
  }
}
