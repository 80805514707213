import { Component, OnInit, ViewChild } from '@angular/core';

import { categories } from './categories';
import {
  introSlider,
  brandSlider,
  categoriesSlider,
  recentsSlider,
} from '../data';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { IrecentItem } from '../../../core/models/items.model';
import { DataService } from '../../../core/services/data.service';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'buy1way-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  categories = categories;
  loaded = false;
  introSlider = introSlider;
  brandSlider = brandSlider;
  categoriesSlider = categoriesSlider;
  recentsSlider = recentsSlider;
  locale: string = 'fr';
  isMobile: boolean = false;
  type: string = 'product';
  recentItems: IrecentItem[] = [];
  isLoading = true;

  constructor(
    public utilsService: UtilsService,
    private storage: LocalStorageService,
    private dataService: DataService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth < 992) this.isMobile = true;
    window.onresize = () => {
      this.isMobile = window.innerWidth < 992 ? true : false;
    };
    this.locale = this.storage.retrieve('lang');
    this.storage.observe('lang').subscribe({
      next: (lang) => (this.locale = lang),
    });
    this.getRecentItems();
  }

  getRecentItems() {
    this.dataService.getRecentItems().subscribe((products: IrecentItem[]) => {
      this.recentItems = products;
    });
  }

  toggleSidebar() {
    if (
      document.querySelector('body').classList.contains('sidebar-home-active')
    ) {
      document.querySelector('body').classList.remove('sidebar-home-active');
    } else {
      document.querySelector('body').classList.add('sidebar-home-active');
    }
  }

  hideSidebar() {
    document.querySelector('body').classList.remove('sidebar-home-active');
  }

  translateCategory(id: string, locale: string) {
    const category = this.categories.find((category) => category.id === id);
    return locale === 'fr' ? category.nameFr : category.nameEn;
  }
  translateSubCategory(id: string, locale: string) {
    let subcategory;
    for (const category of categories) {
      subcategory = category.subcategory.find((sub) => sub.id === id);
      if (subcategory) {
        return locale === 'fr' ? subcategory.nameFr : subcategory.nameEn;
      }
    }
    return undefined; // sous-catégorie non trouvée
  }
}
