import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { UserService } from '../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'buy1way-account-confirmation',
  templateUrl: './account-confirmation.component.html',
  styleUrls: ['./account-confirmation.component.scss'],
})
export class AccountConfirmationComponent implements OnInit {
  constructor(
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.userService.verifyUserEmail(params['token']).subscribe({
        next: () =>
          Swal.fire(
            'Success',
            this.translateService.instant(
              'account.email_verified_successfully'
            ),
            'success'
          ),
        error: (err) => {
          if (err.status == 401) {
            Swal.fire(
              'Warning',
              this.translateService.instant(
                'account.account_activation_time_limit_expired'
              ),
              'warning'
            );
          } else if (err.status == 404) {
            Swal.fire(
              'Error',
              this.translateService.instant(
                'account.account_activation_token_incorrect'
              ),
              'error'
            );
          }
        },
      });
    });
  }
}
