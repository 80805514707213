import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../../core/services/message.service';
import { IMessage } from '../../../../core/models/messages.model';

@Component({
  selector: 'buy1way-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})
export class MessageModalComponent implements OnInit {
  isLoading: boolean = false;
  messageForm: UntypedFormGroup;
  user_id = null;

  constructor(
    private storage: LocalStorageService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.messageService.getReceiverId().subscribe({
      next: (id) => {
        this.user_id = id;
      },
    });
  }

  initForm(): void {
    this.messageForm = this.fb.group({
      message: ['', [Validators.required]],
    });
  }

  send() {
    if (this.messageForm.valid) {
      this.isLoading = true;
      let message: IMessage = {
        sender_id: this.storage.retrieve('user').id,
        receiver_id: this.user_id,
        content: this.messageForm.controls.message.value,
      };
      this.closeModal();
      this.messageService.sendMessage(message).subscribe({
        next: () => {
          this.isLoading = false;
          this.toastr.success(
            this.translateService.instant(
              'message_modal.your_message_has_been_sent'
            )
          );
        },
        error: (err) => {
          if (err.status === 422) {
            this.toastr.info(
              'Vous ne pouvez pas vous envoyer un message à vous-même.'
            );
          } else {
            this.toastr.error(
              this.translateService.instant(
                'message_modal.something_bad_happened'
              )
            );
          }
          this.isLoading = false;
        },
      });
    } else {
      this.validateAllFormFields(this.messageForm);
    }
  }

  closeModal() {
    let modal = document.querySelector('.login-modal') as HTMLElement;
    if (modal) modal.click();
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  get f() {
    return this.messageForm.controls;
  }
}
