<div class="modal-body">
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true"><i class="icon-close"></i></span>
  </button>

  <div class="form-box">
    <div class="form-tab nav-fill">
      <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
        <li ngbNavItem>
          <a ngbNavLink>Message</a>
          <ng-template ngbNavContent>
            <div class="form-choice">
              <form [formGroup]="messageForm">
                <div class="form-group">
                  <label for="message">Message</label>
                  <textarea
                    id="message"
                    formControlName="message"
                    class="form-control"
                    aria-label="With textarea"
                    [ngClass]="{
                      'is-invalid':
                        f.message.errors &&
                        (f.message.touched || f.message.dirty)
                    }"
                  ></textarea>
                </div>

                <button
                  type="button"
                  (click)="send()"
                  class="btn btn-primary mr-3"
                  [disabled]="isLoading"
                >
                  <span
                    *ngIf="isLoading"
                    class="spinner-border text-warning"
                    style="margin-right: 5px"
                    role="status"
                  >
                    <span class="sr-only"></span>
                  </span>
                  <span>{{ "message_modal.send" | translate }}</span>
                  <i class="bi bi-send"></i>
                </button>
              </form>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
