import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { WishlistState } from '../../../../../core/state/states/wishlist.state';

@Component({
  selector: 'buy1way-wishlist-menu',
  templateUrl: './wishlist-menu.component.html',
  styleUrls: ['./wishlist-menu.component.scss'],
})
export class WishlistMenuComponent implements OnInit {
  @Select(WishlistState.getTotal)
  total!: Observable<number>;

  constructor() {}

  ngOnInit(): void {}
}
