<main class="main">
  <div class="container">
    <div
      class="page-header page-header-big text-center mt-3"
      style="background-image: url('assets/images/contact.avif')"
    >
      <h1 class="page-title text-white">
        {{ "contact_us.contact_us" | translate }}
        <span class="text-white">{{
          "contact_us.stay_in_touch" | translate
        }}</span>
      </h1>
    </div>
  </div>

  <div class="page-content pb-0">
    <div class="container">
      <div class="row p-4">
        <div class="col-lg-4 mb-2 mb-lg-0">
          <h2 class="title mb-1">
            {{ "contact_us.contact_information" | translate }}
          </h2>

          <div class="contact-info">
            <ul class="contact-list">
              <li>
                <i class="icon-map-marker"></i>
                Yaoundé - Cameroun
              </li>
              <li>
                <i class="icon-phone"></i>
                <a href="tel:#">{{
                  "+237 6 93 40 49 45 / +237 6 78 37 34 36" | translate
                }}</a>
              </li>
              <li>
                <i class="icon-envelope"></i>
                <a href="mailto:#">contact@buy1way.com </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <h2 class="title mb-1">
            {{ "contact_us.have_questions" | translate }}
          </h2>
          <p class="mb-2">
            {{ "contact_us.contact_instructions" | translate }}
          </p>

          <form
            [formGroup]="contactForm"
            (ngSubmit)="onSubmit($event)"
            class="contact-form mb-3"
          >
            <div class="row">
              <div class="col-sm-6 my-2">
                <input
                  type="tel"
                  class="form-control"
                  id="phone"
                  placeholder="{{ 'contact_us.phone' | translate }}"
                  formControlName="phone"
                />
                <small
                  *ngIf="phone.invalid && phone.touched"
                  class="text-danger"
                >
                  {{ "contact_us.phone_length_error" | translate }}
                </small>
              </div>
              <div class="col-sm-6 my-2">
                <input
                  type="text"
                  class="form-control"
                  id="subject"
                  placeholder="{{ 'contact_us.subject' | translate }}"
                  formControlName="subject"
                />
                <small
                  *ngIf="subject.invalid && subject.touched"
                  class="text-danger"
                >
                  {{ "contact_us.subject_length_error" | translate }}
                </small>
              </div>
            </div>

            <div class="my-2">
              <textarea
                class="form-control"
                cols="30"
                rows="4"
                id="message"
                formControlName="message"
                placeholder="Message"
              ></textarea>
              <small
                *ngIf="message.invalid && message.touched"
                class="text-danger"
              >
                {{ "contact_us.message_length_error" | translate }}
              </small>
            </div>

            <button
              type="submit"
              class="btn btn-outline-primary-2 btn-minwidth-sm"
              [disabled]="isLoading"
            >
              <span
                *ngIf="isLoading"
                class="spinner-border text-warning"
                style="margin-right: 5px"
                role="status"
              >
                <span class="sr-only"></span>
              </span>
              <span>{{ "contact_us.send" | translate }}</span>
              <i class="icon-long-arrow-right"></i>
            </button>
          </form>
        </div>
      </div>

      <hr class="mt-4 mb-5" />
    </div>
    <div class="fabs" (click)="toggleBtn()">
      <div class="action">
        <i class="bi bi-chat-text-fill" id="add"></i>
        <i class="bi bi-x-lg" id="remove" style="display: none"></i>
      </div>

      <div class="float_btns">
        <a
          target="_blank"
          href="whatsapp://send?phone=+237656369040"
          class="float_btn"
          style="background: #25d366"
        >
          <i class="bi bi-whatsapp"></i>
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/buy1wayofficial"
          class="float_btn"
          style="background: #2aabee"
        >
          <i class="bi bi-telegram"></i>
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/buy1wayofficial"
          class="float_btn"
          style="background: #3b5999"
        >
          <i class="bi bi-facebook"></i>
        </a>
      </div>
    </div>
  </div>
</main>
