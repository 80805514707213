import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { ModalService } from '../../services/modal.service';
import { categories } from '../../../pages/home/index/categories';
import { ISearchStateModel } from '../../../core/models/search.model';
import { UpdateSearch } from '../../../core/state/actions/search.action';
import { UtilsService } from '../../../core/services/utils.service';
import { AuthService } from '../../../core/services/auth.service';
import { IUser } from '../../../core/models/auth.state.model';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent {
  @Input() containerClass = 'container';
  isMobile: boolean = false;
  userName: string;
  userProfile: string;
  isLogin: string;
  categories = categories;
  locale: string = 'fr';
  menuActive = false;
  type: string = 'product';
  categoty: string = '';
  subcategory: string = '';
  city: string = '';
  keyword: string = '';
  locationsList: string[];

  showSearchMenu() {
    this.menuActive = true;
  }

  hideSearchMenu() {
    this.menuActive = false;
  }

  constructor(
    public activeRoute: ActivatedRoute,
    public utilsService: UtilsService,
    public modalService: ModalService,
    private storage: LocalStorageService,
    private authService: AuthService,
    private translateService: TranslateService,
    private dataService: DataService,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 992) this.isMobile = true;
    }
    if (typeof window !== 'undefined') {
      window.onresize = () => {
        this.isMobile = window.innerWidth < 992 ? true : false;
      };
    }
    if (this.storage.retrieve('user')) {
      this.isLogin = this.storage.retrieve('user').id;
      this.userName = this.storage.retrieve('user').userName;
      this.userProfile = this.storage.retrieve('user').profilePic;
    }
    this.storage.observe('user').subscribe((user: IUser) => {
      this.isLogin = user.id;
      this.userName = user.userName;
      this.userProfile = user.profilePic;
    });

    this.dataService.getLocations().subscribe({
      next: (locationList: any) => {
        this.locationsList = locationList.locations;
      },
    });

    this.locale = this.storage.retrieve('lang');
    this.translateService.use(this.locale);
    this.storage.observe('lang').subscribe({
      next: (lang) => (this.locale = lang),
    });

    this.activeRoute.queryParamMap.subscribe((params) => {
      this.keyword = params.get('keyword') || '';
      this.city = params.get('city') || '';
      this.type = params.get('type') || 'product';
      this.categoty = params.get('categoty') || '';
    });
  }
  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 2
          ? []
          : this.locationsList
              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .slice(0, 10)
      )
    );

  showLoginModal(event: Event): void {
    event.preventDefault();
    this.modalService.showLoginModal();
  }

  showRegisterModal(event: Event): void {
    event.preventDefault();
    this.modalService.showRegisterModal();
  }

  onCategoryChange(event: any) {
    this.categoty = event.currentTarget.value;
  }

  onTypeChange() {
    const search: ISearchStateModel = { type: this.type };
    this.store.dispatch(new UpdateSearch(search));
  }

  onSearch() {
    this.router.navigate(['/ads/list'], {
      queryParams: {
        type: this.type,
        category: this.categoty,
        keyword: this.keyword,
        city: this.city,
        page: 1,
        limit: 10,
      },
    });
    this.menuActive = false;
  }

  logout() {
    this.authService.logout();
    this.userName = '';
    this.isLogin = '';
    this.userName = '';
    this.userProfile = '';
  }

  getLanguage(): string {
    if (typeof navigator !== 'undefined') {
      return navigator.language.startsWith('en') ? 'en' : 'fr';
    } else {
      return 'en';
    }
  }

  translateCategory(id: string, locale: string) {
    const category = this.categories.find((category) => category.id === id);
    return locale === 'fr' ? category.nameFr : category.nameEn;
  }
}
