import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './pages/home/home.module';

import { AppComponent } from './app.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AddAnAdComponent } from './pages/add-an-ad/add-an-ad.component';

import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { AccountConfirmationComponent } from './pages/account-confirmation/account-confirmation.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CguComponent } from './pages/cgu/cgu.component';
import { CoreModule } from './core/core.module';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AddAnAdComponent,
    PageNotFoundComponent,
    ContactUsComponent,
    AboutUsComponent,
    ComingSoonComponent,
    AccountConfirmationComponent,
    ChangePasswordComponent,
    CguComponent,
  ],

  imports: [
    NgxWebstorageModule.forRoot(),
    NgSelectModule,
    SocialLoginModule,
    NgxDropzoneModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    SharedModule,
    HomeModule,
    CoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: false,
      enableHtml: true,
      preventDuplicates: true,
      closeButton: false,
    }),
    FormsModule,
    NgbTimepickerModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CarouselModule,
  ],

  providers: [
    NgxImageCompressService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('337164541546042'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
