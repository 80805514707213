import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError, Observable } from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { IItems, IrecentItem } from '../models/items.model';
import { Store } from '@ngxs/store';
import {
  AddProduct,
  AddService,
  RemoveProduct,
  RemoveService,
} from '../state/actions/wishlist.action';
import { WishlistState } from '../state/states/wishlist.state';

@Injectable({
  providedIn: 'root',
})
export class WishlistService {
  user_id = this.storage.retrieve('user').id;
  private baseurl = environment.API_URL;

  constructor(
    private toastrService: ToastrService,
    private storage: LocalStorageService,
    private http: HttpClient,
    private translateService: TranslateService,
    private store: Store
  ) {}

  // Add wishlist to store
  public getWishlist(userId: string) {
    this.getWishListProducts(userId).subscribe((products: IItems[]) => {
      products.forEach((product: IItems) => {
        this.store.dispatch(new AddProduct(product));
      });
    });

    this.getWishListServices(userId).subscribe((services: IItems[]) => {
      services.forEach((service: IItems) => {
        this.store.dispatch(new AddService(service));
      });
    });
  }

  // Get wishlist product by id user
  public getWishListServices(userId: string): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseurl + 'whishlistService/' + userId)
      .pipe(catchError(this.handleHttpError));
  }

  // Get wishlist product by id user
  public getWishListProducts(userId: string): Observable<IItems[]> {
    return this.http
      .get<IItems[]>(this.baseurl + 'wishlistProduct/' + userId)
      .pipe(catchError(this.handleHttpError));
  }

  // Product add to Wishlist
  addToWishList(item: IItems | IrecentItem, type: string): void {
    if (type === 'product') {
      const existingProductIndex = this.store
        .selectSnapshot(WishlistState.getProducts)
        .findIndex((existingItem) => existingItem.id === item.id);
      if (existingProductIndex === -1) {
        item.type = 'product';
        this.store.dispatch(new AddProduct(<IItems>item));
        const formData = new FormData();
        formData.append('user_id', this.user_id);
        formData.append('product_id', item.id);
        this.createWishListProduct(formData).subscribe({
          next: () => {
            this.toastrService.success(
              this.translateService.instant(
                'wishlist.the_product_has_been_added'
              )
            );
          },
        });
      } else {
        this.store.dispatch(new RemoveProduct(item.id));
        this.deleteWishListProduct(this.user_id, item.id).subscribe({
          next: () => {
            this.toastrService.success(
              this.translateService.instant(
                'wishlist.the_product_has_been_removed'
              )
            );
          },
        });
      }
    }

    if (type === 'service') {
      const existingServiceIndex = this.store
        .selectSnapshot(WishlistState.getServices)
        .findIndex((existingItem) => existingItem.id === item.id);
      if (existingServiceIndex === -1) {
        item.type = 'service';
        this.store.dispatch(new AddService(<IItems>item));
        const formData = new FormData();
        formData.append('user_id', this.user_id);
        formData.append('service_id', item.id);
        this.createWishListService(formData).subscribe({
          next: () => {
            this.toastrService.success(
              this.translateService.instant(
                'wishlist.the_service_has_been_added'
              )
            );
          },
        });
      } else {
        this.store.dispatch(new RemoveService(item.id));
        this.deleteWishListService(this.user_id, item.id).subscribe({
          next: () => {
            this.toastrService.success(
              this.translateService.instant(
                'wishlist.the_service_has_been_removed'
              )
            );
          },
        });
      }
    }
  }

  // Product removed from Wishlist
  removeFromWishList(product: IItems): void {
    this.toastrService.success('Product removed from Wishlist.');
  }

  // Check whether product is in Wishlist or not
  isInWishlist(product: IItems | IrecentItem, type: string): boolean {
    if (type === 'product') {
      const products = this.store.selectSnapshot(WishlistState.getProducts);
      return products.some((item) => item.id === product.id);
    } else {
      const services = this.store.selectSnapshot(WishlistState.getServices);
      return services.some((item) => item.id === product.id);
    }
  }

  public deleteWishListService(
    userId: string,
    serviceId: string
  ): Observable<any> {
    return this.http
      .delete<any>(
        this.baseurl + 'whishlistService/' + serviceId + '/' + userId
      )
      .pipe(catchError(this.handleHttpError));
  }
  public deleteWishListProduct(
    userId: string,
    productId: string
  ): Observable<any> {
    return this.http
      .delete<any>(this.baseurl + 'wishlistProduct/' + productId + '/' + userId)
      .pipe(catchError(this.handleHttpError));
  }

  public createWishListService(formData: FormData): Observable<any> {
    return this.http
      .post<any>(this.baseurl + 'whishlistService', formData)
      .pipe(catchError(this.handleHttpError));
  }

  public createWishListProduct(formData: FormData): Observable<any> {
    return this.http
      .post<any>(this.baseurl + 'wishlistProduct', formData)
      .pipe(catchError(this.handleHttpError));
  }

  private handleHttpError(err: HttpErrorResponse) {
    let error: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
      error = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${err.status}, ` + `body was: ${err.error}`
      );
      error = `Backend returned code ${err.status}, body was: ${err.error}`;
    }
    // Return an observable with a user-facing error message.
    return throwError(err);
  }
}
