<header class="header header-14">
  <div class="header-top" *ngIf="!isMobile">
    <div [class]="containerClass">
      <div class="header-left">
        <button
          routerLink="/new"
          type="button"
          class="btn btn-primary btn-rounded btn-shadow"
          style="border-radius: 10px"
        >
          <i class="bi bi-plus-circle" style="margin-right: 5px"></i>
          {{ "header.ad_button" | translate }}
        </button>
      </div>

      <div class="header-right">
        <ul class="top-menu">
          <li>
            <a href="javascript:;"> Links</a>
            <ul class="menus">
              <strong class="me-5">
                <li>
                  <div class="header-dropdown">
                    <a href="javascript:;">XAF</a>
                    <div class="header-menu">
                      <ul>
                        <li>
                          <a class="colored_select-lang" href="javascript:;"
                            >XAF</a
                          >
                        </li>
                        <li><a href="javascript:;">EURO</a></li>
                        <li><a href="javascript:;">USD</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </strong>
              <li>
                <strong>
                  <div class="header-dropdown">
                    <a href="javascript:;">
                      {{ locale === "en" ? "English" : "Français" }}</a
                    >
                    <div class="header-menu">
                      <ul>
                        <li>
                          <a
                            (click)="changeLanguage('en')"
                            [ngClass]="{
                              'colored_select-lang ': locale === 'en'
                            }"
                            href="javascript:;"
                            >English</a
                          >
                        </li>
                        <li>
                          <a
                            (click)="changeLanguage('fr')"
                            [ngClass]="{
                              'colored_select-lang ': locale === 'fr'
                            }"
                            href="javascript:;"
                            >Français</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </strong>
              </li>
              <li>
                <div class="header-dropdown">
                  <a
                    class="user"
                    href="javascript:;"
                    *ngIf="userName && !userProfile"
                    ><ngx-avatars size="33" [name]="userName"></ngx-avatars
                  ></a>
                  <a class="user" href="javascript:;" *ngIf="userProfile"
                    ><ngx-avatars size="30" [src]="userProfile"></ngx-avatars
                  ></a>
                  <a
                    class="user"
                    href="javascript:;"
                    *ngIf="!userName && !userProfile"
                    ><ngx-avatars size="30" src="assets/images/avatar.png">
                    </ngx-avatars
                  ></a>
                  <div class="header-menu">
                    <ul>
                      <strong>
                        <li>
                          <a routerLink="/account" *ngIf="isLogin">{{
                            "header.account" | translate
                          }}</a
                          ><a
                            href="javascript:;"
                            (click)="showRegisterModal($event)"
                            *ngIf="!isLogin"
                            >{{ "header.sign_up" | translate }}</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:;"
                            (click)="showLoginModal($event)"
                            *ngIf="!isLogin"
                            >{{ "header.sign_in" | translate }}</a
                          >
                          <a
                            href="javascript:;"
                            (click)="logout()"
                            *ngIf="isLogin"
                            >{{ "header.sign_out" | translate }}</a
                          >
                        </li>
                      </strong>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="header-middle">
    <div [class]="containerClass">
      <div class="row">
        <div class="col-auto col-lg-3 col-xl-3 col-xxl-2">
          <a routerLink="/">
            <img
              class="header-desktop-logo"
              src="assets/images/home/logo.png"
              alt="Buy1Way"
            />
          </a>
        </div>

        <div class="col col-lg-9 col-xl-9 col-xxl-10 header-middle-right">
          <div class="row">
            <div class="col-lg-8 col-xxl-4-5col d-none d-lg-block">
              <buy1way-header-search></buy1way-header-search>
            </div>
            <div
              class="col-lg-4 col-xxl-5col d-flex justify-content-end align-items-center"
            >
              <div class="header-dropdown-link">
                <buy1way-messages-menu></buy1way-messages-menu>
                <buy1way-wishlist-menu></buy1way-wishlist-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="sticky-wrapper"
    [style.height]="
      utilsService.isSticky ? utilsService.stickyHeight + 'px' : ''
    "
  >
    <div
      class="header-bottom sticky-header"
      [class.fixed]="utilsService.isSticky"
    >
      <div [ngClass]="containerClass">
        <div class="row">
          <div class="col menu-list">
            <buy1way-main-menu></buy1way-main-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
