import { Component, NgZone, OnInit } from '@angular/core';
import {
  FacebookLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '../../../services/modal.service';
import { WishlistService } from '../../../../core/services/wishlist.service';
import { UserService } from '../../../../core/services/user.service';
import { IUser } from '../../../../core/models/auth.state.model';
import { Store } from '@ngxs/store';
import { IItems } from '../../../../core/models/items.model';
declare var google: any;

@Component({
  selector: 'buy1way-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  isLoading: boolean = false;
  isError: boolean = false;
  errorMessage: string;
  loginForm: UntypedFormGroup;
  resetEmailForm: UntypedFormGroup;
  fieldTextType: boolean;
  resetPassword = false;

  constructor(
    private ngZone: NgZone,
    private authService: SocialAuthService,
    private useService: UserService,
    private storage: LocalStorageService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    public modalService: ModalService,
    public wishlistService: WishlistService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
    this.authService.authState.subscribe((data) => {
      let user: IUser = {
        facebookId: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.name,
        email: data.email,
        profilePic: data.photoUrl,
      };

      this.useService.loginFacebook(user).subscribe({
        next: (user: IUser) => {
          this.storage.store('user', user);
          this.useService.getsWishListServices(user.id).subscribe({
            next: (services) => {
              services.forEach((service) => {
                this.wishlistService.addToWishList(<IItems>service, 'service');
              });
            },
          });
          this.useService.getsWishListProducts(user.id).subscribe({
            next: (products) => {
              products.forEach((product) => {
                this.wishlistService.addToWishList(<IItems>product, 'product');
              });
            },
          });
          this.toastr.success(
            this.translateService.instant(
              'login.you_are_successfully_logged_in'
            )
          );
          this.closeModal();
          this.wishlistService.getWishlist(user.id);
        },
        error: (err) => {
          if (err.status == '409') {
            this.toastr.error(
              this.translateService.instant(
                'login.this_email_address_is_already_used'
              )
            );
          } else {
            this.toastr.error(
              this.translateService.instant('login.an_error_has_occurred')
            );
          }
        },
      });
    });
  }

  ngAfterViewInit() {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: ((window as any)['handleCredentialResponse'] = (
        response: any
      ) =>
        this.ngZone.run(() => {
          this.loginGoogle(jwt_decode(response.credential));
        })),
    });

    google.accounts.id.renderButton(document.getElementById('googleButton'), {
      type: 'standard',
      text: 'Google',
      theme: 'outline',
      size: 'medium',
      width: '100',
    });
  }

  initForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      rememberMe: [''],
    });
  }
  resetForm(): void {
    this.resetEmailForm = this.fb.group({
      email: ['', [Validators.required]],
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  register() {
    this.closeModal();
    this.modalService.showRegisterModal();
  }

  closeModal() {
    let modal = document.querySelector('.login-modal') as HTMLElement;
    if (modal) modal.click();
  }

  showResetPassword() {
    this.resetPassword = true;
  }

  sendEmail() {
    if (this.resetEmailForm.valid) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('email', this.resetEmailForm.controls.email.value);
      formData.append('locale', this.getLanguage());
      this.useService.resetPasswordSendEmail(formData).subscribe({
        next: () => {
          this.closeModal();
          Swal.fire(
            this.translateService.instant('login.success'),
            this.translateService.instant(
              'login.we_have_sent_you_a_password_reset_email'
            ),
            'success'
          );
          this.isLoading = false;
        },
        error: () => {
          Swal.fire(
            this.translateService.instant('login.error'),
            this.translateService.instant('login.an_error_has_occurred'),
            'error'
          );
        },
      });
    } else {
      this.validateAllFormFields(this.resetEmailForm);
    }
  }

  login() {
    this.isError = false;
    if (this.loginForm.valid) {
      this.isLoading = true;
      let user: IUser = {
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value,
      };
      this.useService.login(user).subscribe({
        next: (user: IUser) => {
          this.isLoading = false;
          this.closeModal();
          this.storage.store('user', user);
          this.useService.getsWishListServices(user.id).subscribe({
            next: (services) => {
              services.forEach((service) => {
                this.wishlistService.addToWishList(<IItems>service, 'service');
              });
            },
          });
          this.useService.getsWishListProducts(user.id).subscribe({
            next: (products) => {
              products.forEach((product) => {
                this.wishlistService.addToWishList(<IItems>product, 'product');
              });
            },
          });

          this.toastr.success(
            this.translateService.instant(
              'login.you_are_successfully_logged_in'
            )
          );

          this.wishlistService.getWishlist(user.id);
        },
        error: (err) => {
          this.isLoading = false;
          this.isError = true;
          if (err.status == 401) {
            if (err.error.message === 'Unconfirmed user') {
              this.errorMessage = this.translateService.instant(
                'login.your_account_is_not_yet_confirmed'
              );
            }
            if (err.error.message === 'Invalid login credentials') {
              this.errorMessage = this.translateService.instant(
                'login.wrong_email_or_password'
              );
            }
          } else if (err.status == 409) {
            this.toastr.error(
              this.translateService.instant(
                'login.this_email_address_is_already_used'
              )
            );
          } else {
            this.errorMessage = this.errorMessage =
              this.translateService.instant('login.wrong_email_or_password');
          }
        },
      });
    } else {
      this.validateAllFormFields(this.loginForm);
    }
  }

  loginGoogle(googleDetails: any) {
    let user: IUser = {
      googleId: googleDetails.sub,
      firstName: googleDetails.given_name,
      lastName: googleDetails.family_name,
      userName: googleDetails.name,
      email: googleDetails.email,
      profilePic: googleDetails.picture,
    };
    this.useService.loginGoogle(user).subscribe({
      next: (user: IUser) => {
        this.storage.store('user', user);
        this.useService.getsWishListServices(user.id).subscribe({
          next: (services) => {
            services.forEach((service) => {
              this.wishlistService.addToWishList(<IItems>service, 'service');
            });
          },
        });
        this.useService.getsWishListProducts(user.id).subscribe({
          next: (products) => {
            products.forEach((product) => {
              this.wishlistService.addToWishList(<IItems>product, 'product');
            });
          },
        });
        this.toastr.success(
          this.translateService.instant('login.you_are_successfully_logged_in')
        );
        this.closeModal();
        this.wishlistService.getWishlist(user.id);
      },
      error: (err) => {
        if (err.status == '409') {
          this.toastr.error(
            this.translateService.instant(
              'login.this_email_address_is_already_used'
            )
          );
        } else {
          this.toastr.error(
            this.translateService.instant('login.an_error_has_occurred')
          );
        }
      },
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  get f() {
    return this.loginForm.controls;
  }

  getLanguage(): string {
    if (typeof navigator !== 'undefined') {
      return navigator.language.startsWith('en') ? 'en' : 'fr';
    } else {
      return 'en';
    }
  }
}
