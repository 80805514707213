import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';

import { ModalService } from '../../services/modal.service';
import { UtilsService } from '../../../core/services/utils.service';
import { AuthService } from '../../../core/services/auth.service';
import { IUser } from '../../../core/models/auth.state.model';

@Component({
  selector: 'desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
})
export class DesktopHeaderComponent {
  isMobile: boolean = false;
  userName: string;
  userProfile: string;
  isLogin: string;
  @Input() containerClass = 'container';
  locale: string = 'fr';

  wishCount = 0;

  constructor(
    public activeRoute: ActivatedRoute,
    public utilsService: UtilsService,
    public modalService: ModalService,
    private storage: LocalStorageService,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth < 992) this.isMobile = true;
    window.onresize = () => {
      this.isMobile = window.innerWidth < 992 ? true : false;
    };
    if (this.storage.retrieve('user')) {
      this.isLogin = this.storage.retrieve('user').id;
      this.userName = this.storage.retrieve('user').userName;
      this.userProfile = this.storage.retrieve('user').profilePic;
    }
    this.storage.observe('user').subscribe((user: IUser) => {
      this.isLogin = user.id;
      this.userName = user.userName;
      this.userProfile = user.profilePic;
    });
    this.locale = this.storage.retrieve('lang');
    this.translateService.use(this.locale);
    this.storage.observe('lang').subscribe({
      next: (lang) => (this.locale = lang),
    });
  }

  showLoginModal(event: Event): void {
    event.preventDefault();
    this.modalService.showLoginModal();
  }
  showRegisterModal(event: Event): void {
    event.preventDefault();
    this.modalService.showRegisterModal();
  }

  logout() {
    this.authService.logout();
    this.userName = '';
    this.isLogin = '';
    this.userName = '';
    this.userProfile = '';
  }

  getLanguage(): string {
    if (typeof navigator !== 'undefined') {
      return navigator.language.startsWith('en') ? 'en' : 'fr';
    } else {
      return 'en';
    }
  }

  changeLanguage(lang: string) {
    this.storage.store('lang', lang);
    this.translateService.use(lang);
  }
}
