import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

import { CustomValidators } from '../../shared/components/modals/register-modal/Customvalidator';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'buy1way-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  isLoading: boolean = false;
  changePasswordForm: UntypedFormGroup;
  fieldTextType: boolean;
  isValidCode = false;
  code: string;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.code = params['code'];
      this.userService.resetPasswordCheckCode(params['code']).subscribe({
        next: () => (this.isValidCode = true),
        error: (err) => {
          if (err.status == 401) {
            Swal.fire(
              this.translateService.instant('change_password.warning'),
              this.translateService.instant(
                'change_password.change_code_has_expired'
              ),
              'warning'
            );
          } else if (err.status == 404) {
            Swal.fire(
              this.translateService.instant('change_password.error'),
              this.translateService.instant(
                'change_password.change_code_invalid'
              ),
              'error'
            );
          }
          this.router.navigateByUrl('/');
        },
      });
    });
    this.initForm();
  }

  initForm(): void {
    this.changePasswordForm = this.fb.group(
      {
        password: [
          null,
          [
            Validators.minLength(8),
            Validators.required,
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            CustomValidators.patternValidator(/[#?!@$%^_\-&*~(){}\[\]|\\]/, {
              hasSpecialCharacters: true,
            }),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: CustomValidators.mustMatch('password', 'confirmPassword'),
      }
    );
  }

  change() {
    if (this.changePasswordForm.valid) {
      const formData = new FormData();
      formData.append('code', this.code);
      formData.append(
        'password',
        this.changePasswordForm.controls.password.value
      );
      this.userService.resetPasswordSend(formData).subscribe({
        next: () => {
          Swal.fire(
            this.translateService.instant('change_password.success'),
            this.translateService.instant(
              'change_password.password_has_been_changed_successfully'
            ),
            'success'
          );
          this.router.navigateByUrl('/');
          this.isLoading = false;
        },
        error: () => {
          Swal.fire(
            this.translateService.instant('change_password.error'),
            this.translateService.instant(
              'change_password.something_bad_happened'
            ),
            'error'
          );
          this.isLoading = false;
        },
      });
    } else {
      this.validateAllFormFields(this.changePasswordForm);
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  get f() {
    return this.changePasswordForm.controls;
  }
}
